import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb, Space } from 'antd';
import ReportEditForm from './components/ReportEditForm';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_REPORT } from '../../../router/router-path';
import { ReportContext } from '../store/report-store';
import MyDelConfirm from '../../../components/MyDelConfirm';
import { RoleType } from '../../../store/user.model';
import { useRoleType } from '../../../hooks/use-auth';
import { useAliveNotify } from '../../../core/keep-alive/use-keep-alive';

const ReportEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const aliveNotify = useAliveNotify();

  const {
    delStore: { submit }
  } = useContext(ReportContext);

  const roleType = useRoleType();

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>レポート一覧</Breadcrumb.Item>
        <Breadcrumb.Item>
          {params.id
            ? window.location.pathname.startsWith('/report/copy/')
              ? 'コピー登録'
              : '変更'
            : '新規登録'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea report-edit">
        <div className="mainCard reportCard editCard">
          <div
            className={
              params.id && roleType !== RoleType.USER ? 'cardHeader' : ''
            }
          >
            <div className="cardHeaderBtn">
              <Space>
                {/* <Button
                  onClick={() => window.open('/imgs/AVS指標について.pdf')}
                >
                  <FilePdfOutlined />
                  AVSとは？
                </Button> */}
                {params.id && roleType !== RoleType.USER && (
                  <MyDelConfirm
                    handleDel={() => submit({ id: params?.id })}
                    delSuccess={() => {
                      aliveNotify();
                      navigate(PATH_REPORT);
                    }}
                  />
                )}
              </Space>
            </div>
          </div>
          <div className="cardBody">
            <ReportEditForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ReportEdit);
