import React, { useEffect, useState } from 'react';
import { Switch, Table } from 'antd';
import { AccountListModel } from '../view/account/account/store/model/account.model';

interface Props {
  value?: number[];
  onChange?: (value: number[]) => void;
  allUsers: AccountListModel[];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const UserTable = ({ value, onChange, allUsers }: Props) => {
  const [targetIDs, changeTargetIDs] = useState<number[]>([]);

  useEffect(() => {
    if (value && value.join('') !== targetIDs.join('')) {
      changeTargetIDs(value);
    }
  }, [value, changeTargetIDs]);

  const toggleTargetID = (userID: number) => {
    let _targetIDs: number[];
    if (targetIDs.some((id) => id === userID)) {
      _targetIDs = targetIDs.filter((id: number) => id !== userID);
    } else {
      _targetIDs = [...targetIDs, userID];
    }
    changeTargetIDs(_targetIDs);
    if (onChange) {
      onChange(_targetIDs);
    }
  };

  const userListColumns: any = [
    {
      title: 'ユーザ名',
      dataIndex: 'username',
      key: 'username',
      align: 'center',
      width: 160
    },
    {
      title: '部署',
      dataIndex: 'adminDept',
      key: 'adminDept',
      align: 'center'
    },
    {
      title: 'ユーザ選択',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 120,
      render: (text: boolean, record: AccountListModel) => {
        const checked = targetIDs.some(
          (userID: number) => userID === record.id
        );
        return (
          <Switch
            checkedChildren="ON"
            unCheckedChildren="OFF"
            checked={checked}
            onChange={() => {
              toggleTargetID(record.id);
            }}
          />
        );
      }
    }
  ];

  return (
    <div className="tableBox">
      <Table
        rowKey="id"
        columns={userListColumns}
        dataSource={allUsers}
        size="middle"
      />
    </div>
  );
};

export default UserTable;
