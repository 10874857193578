import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useIndexPath, useRoleType } from '../hooks/use-auth';

const NotFound = () => {
  const location = useLocation();

  const roleType = useRoleType();

  const indexPath = useIndexPath();

  return <Navigate to={indexPath(roleType)} state={{ from: location }} />;
};

export default NotFound;
