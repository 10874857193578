import moment from 'moment';
import { DATE, FULL_TIME } from '../constants';
import { detectBrowser, browserName } from './common';
type STATUS = {
  status: number;
  name: 'default' | 'success' | 'processing' | 'error' | 'warning' | undefined;
  text: string;
};

type TIME_TYPE = 'days' | 'hours' | 'minutes' | 'seconds';

export enum TYPE_TIME {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds'
}

export type EXPORT_CONDITION = {
  startTime: string;
  endTime: string;
};

export type CONDITION_REPORT = {
  adStartTime: string;
  adEndTime: string;
  materialName: string;
  materialScale: number;
  hourlyAdTimes: EXPORT_CONDITION;
  adCountPerHour: number;
  adCountPerDay: number;
  adCountOfPeriod: number;
  timeSpecification?: number;
};

export const TIME_DURATION = 15;

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getReportStatus = (status: number): any => {
  const statusList: STATUS[] = [
    {
      status: 0,
      name: 'default',
      text: 'スタンバイ'
    },
    {
      status: 1,
      name: 'processing',
      text: '集計中'
    },
    {
      status: 2,
      name: 'success',
      text: '完了'
    },
    {
      status: 3,
      name: 'processing',
      text: '計測中'
    }
  ];

  return statusList.find((item) => item.status === status);
};

export const formatHour = (time: number) => {
  return time < 10 ? `0${time}:00` : `${time}:00`;
};

export const formatHourOnly = (time: number) => {
  return time < 10 ? `$0${time}` : time;
};

export const pdfSetting =
  detectBrowser() === browserName.SAFARI
    ? {
        //a4
        heightPerPage: 1563,
        //height from top to three page
        heightThreePage: 4658
      }
    : {
        heightPerPage: 1635,
        heightThreePage: 4878
      };

export const headerPDFHeight = 0;

// @ts-ignore
export const arrayForOptionMin = [...new Array(60).keys()];
// repeat time of condition report is 15 secs
export const arrayForOptionSec = [0, 15, 30, 45];

export const compareOtherHour = (time1: string, time2: string) => {
  const hour1 = +time1?.split(':')[0];
  const min1 = +time1?.split(':')[1];
  const sec1 = +time1?.split(':')[2] || 0;
  const hour2 = +time2?.split(':')[0];
  const min2 = +time2?.split(':')[1];
  const sec2 = +time2?.split(':')[2] || 0;

  const calHour1 = hour1 * 3600 + min1 * 60 + sec1;
  const calHour2 = hour2 * 3600 + min2 * 60 + sec2;
  return calHour2 > calHour1;
};

export const getQueryTime = (time: string, type: string) => {
  const tempTime = time.split(':');
  return type === TYPE_TIME.HOURS
    ? tempTime[0]
    : type === TYPE_TIME.MINUTES
    ? tempTime[1]
    : tempTime[2];
};

export const updateHour = (time: string) => {
  return formatHour(+time.split(':')[0] + 1);
};

export const initConditionReport = {
  adStartTime: '',
  adEndTime: '',
  materialName: null,
  materialScale: null,
  hourlyAdTimes: [
    {
      startTime: '',
      endTime: ''
    }
  ],
  adCountPerHour: 0,
  adCountPerDay: 0,
  adCountOfPeriod: 0
};

export const durationTime = [15, 30, 45, 60, 75, 90, 120, 3, 5, 10];
export const endTimeOfDay = '24:00';
export const maxEndTimeMedia = 26;
export const minScaleTime = 15;
export const maxPriceLength = 11;
export const maxHourOfDay = 24;
export const outsideHour = 25;

// because the max time in the date = 26:00, not 24:00
export const differentTime = (
  startTime: string,
  endTime: string,
  type: TIME_TYPE
) => {
  const typeTime = type === TYPE_TIME.DAYS ? DATE : FULL_TIME;
  return moment(endTime, typeTime).diff(moment(startTime, typeTime), type);
};

export const calculateOutTime = (startTime: string, endTime: string) => {
  const startHour = +startTime.split(':')[0];
  const endHour = +endTime.split(':')[0];
  const startMin = +startTime.split(':')[1];
  const endMin = +endTime.split(':')[1];
  if (startHour === maxHourOfDay && endHour === outsideHour) {
    return startMin === endMin ? 3600 : (endMin + (60 - startMin)) * 60;
  } else {
    return (endMin - startMin) * 60;
  }
};
