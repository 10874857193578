import { observer } from 'mobx-react-lite';
import { AccountContext, AccountStore } from '../store/account-store';
import React from 'react';
import AccountEdit from './AccountEdit';

export default observer(() => {
  return (
    <AccountContext.Provider value={new AccountStore()}>
      <AccountEdit />
    </AccountContext.Provider>
  );
});
