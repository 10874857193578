export class NumberUtils {
  public static toThousands(num = 0, chart = ',', length = 3) {
    let result = '';
    const nums = num.toString().split('.');
    const int = nums[0];
    const decmial = nums[1] ? '.' + nums[1] : '';
    let index = 0;
    for (let n = int.length - 1; n >= 0; n--) {
      index++;
      result = int[n] + result;
      if (index % length === 0 && n !== 0) {
        result = chart + result;
      }
    }
    return result + decmial;
  }
  public static fixPoint(num = 0, reserve = 1) {
    const reserveNum = reserve * 10;
    return Math.round(num * reserveNum) / reserveNum;
  }
}
