import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { LegendComponent, TitleComponent } from 'echarts/components';
import { RadarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { unit } from '../constants/text';
import { sizeChart } from '../utils/charts';
import { NumberUtils } from '../utils/number-utils';
import { roundedNumber } from '../utils/common';
import { retentionLength } from '../constants';

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

/* eslint-disable @typescript-eslint/no-explicit-any */
const EAreaChart = (props: any) => {
  const { data, isReportDetail } = props;
  const seriesData = data?.series?.length
    ? JSON.parse(JSON.stringify(data?.series))
    : Array(retentionLength).fill(0);

  // the first data should be = null to don't show on chart,
  // the begin value = 1, not 0
  seriesData?.shift();

  const maxValue =
    seriesData?.reduce((a: string, b: string) => {
      return Math.max(+a, +b);
    }) || 1;

  const totalRetentionData =
    seriesData?.reduce((a: string, b: string) => {
      return +a + +b;
    }) || 0;

  const getOption = () => {
    return {
      width: isReportDetail ? 250 : 270,
      grid: {
        //prevent broken UI when data is too big
        left: maxValue > 99999 ? '19%' : '15%',
        right: '12%'
      },
      tooltip: {
        trigger: 'axis',
        position: function (pt: any) {
          //just style for element
          return [pt[0] - 120, '-2%'];
        },
        formatter: (params: any) => `<div class="area-tooltip-wrapper">
            <p class="tooltip-value">
            <span class="value-name">${
              +params[0]?.dataIndex + 1 + unit.seconds
            }</span>
            <span class="value">${NumberUtils.toThousands(params[0].value)} ${
          unit.people
        }</span>
            <span>${roundedNumber(
              (params[0].value / totalRetentionData) * 100
            )}%</span>
            </p>
            </div>`
      },
      xAxis: {
        name: unit.seconds,
        type: 'category',
        nameTextStyle: {
          align: 'left',
          verticalAlign: 'top',
          padding: [8, 0, 0, -8]
        },
        // min: 1,
        axisTick: {
          interval: 0,
          alignWithLabel: true
        },
        axisLabel: {
          //set the label show on x Axis
          interval: 0,
          formatter: (value: string) => +value + 1
        }
      },
      yAxis: {
        name: unit.peopleGraph,
        type: 'value',
        max: maxValue
      },
      // dependent on how many section
      series: [
        {
          name: 'Retention Data',
          type: 'bar',
          itemStyle: {
            color: '#2eb2ea'
          },
          lineStyle: {
            width: data?.series?.length ? 1 : 0
          },
          areaStyle: {
            color: '#41abf2'
          },
          data: seriesData
        }
      ]
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox areaBox">
      <ReactEcharts
        option={getOption()}
        style={{
          width: props?.isHasBorder ? sizeChart.custom : sizeChart.default,
          height: isReportDetail ? sizeChart.print : sizeChart.default
        }}
        ref={echartsEl}
      />
    </div>
  );
};

export default observer(EAreaChart);
