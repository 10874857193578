import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import MyTable from '../../../../components/MyTable';
import { SettingContext } from '../store/setting-store';
import { OrderType } from '../../../../model/order-type';
import SettingModal from '../components/SettingModal';
import columns from './columns';

const SettingList = () => {
  const {
    listStore,
    modalStore,
    accountListStore: { loadData: loadAccountListData }
  } = useContext(SettingContext);

  const { openModal } = modalStore;

  useEffect(() => {
    loadAccountListData().then();
  }, [loadAccountListData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sortHandle = (sorter: Record<string, any>) => {
    if (sorter.id) {
      return {
        orderType: sorter.id === 'ascend' ? OrderType.ASC : OrderType.DESC
      };
    }
    return {};
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>システム設定</Breadcrumb.Item>
      </Breadcrumb>
      <div className="subMainArea">
        <MyTable
          listStore={listStore}
          columns={columns(modalStore)}
          rowKey={'id'}
          alive={true}
          search={false}
          sortHandle={sortHandle}
          toolBarRender={() => [
            <Button type="primary" onClick={() => openModal()}>
              <PlusOutlined />
              新規登録
            </Button>
          ]}
        />

        <SettingModal />
      </div>
    </>
  );
};

export default observer(SettingList);
