import { ProColumns } from '@ant-design/pro-table/lib/typing';
import { Dropdown, Menu, Badge, Space } from 'antd';
import { MyLink } from '../../../core/navigate';
import {
  PATH_REPORT_COPY,
  PATH_REPORT_DETAILS,
  PATH_REPORT_UPDATE
} from '../../../router/router-path';
import React, { useContext } from 'react';
import { ReportListModel } from '../store/model/report.model';
import { DownOutlined } from '@ant-design/icons/lib';
import { AuthContext } from '../../../store/auth-store';
import { ReportContext } from '../store/report-store';
import MyDelConfirm from '../../../components/MyDelConfirm';
import { useAliveNotify } from '../../../core/keep-alive/use-keep-alive';

export const Columns = (): ProColumns<ReportListModel>[] => {
  const aliveNotify = useAliveNotify();
  const {
    delStore: { submit },
    listStore: { reload }
  } = useContext(ReportContext);
  const { role } = useContext(AuthContext);

  return [
    {
      title: 'No',
      dataIndex: 'id',
      key: 'id',
      width: 60,
      align: 'center',
      fixed: 'left',
      sorter: true
    },
    {
      title: '広告主名',
      dataIndex: 'owner',
      key: 'owner',
      ellipsis: true,
      align: 'left'
    },
    {
      title: '広告内容',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      align: 'left'
    },
    {
      title: '放映開始日',
      dataIndex: 'adStartDate',
      key: 'adStartDate',
      width: 120,
      search: false,
      align: 'center'
    },
    {
      title: '放映終了日',
      dataIndex: 'adEndDate',
      key: 'adEndDate',
      width: 120,
      search: false,
      align: 'center'
    },
    {
      title: '状態',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      search: false,
      align: 'center',
      render: (_, record: ReportListModel) => {
        // 0: スタンバイ
        // 1: 集計中
        // 2: 完了
        // 3: 計測中
        if (record.status === 0) {
          return (
            <>
              <Badge status="default" text="スタンバイ" />
            </>
          );
        }
        if (record.status === 1) {
          return (
            <>
              <Badge status="processing" text="集計中" />
            </>
          );
        }
        if (record.status === 2) {
          return (
            <>
              <Badge status="success" text="完了" />
            </>
          );
        }
        if (record.status === 3) {
          return (
            <>
              <Badge status="processing" text="計測中" />
            </>
          );
        }
      }
    },
    {
      title: '代理店名',
      dataIndex: 'agencyName',
      key: 'agency',
      width: 140,
      search: false,
      align: 'center',
      render: (_, record: ReportListModel) => {
        return record?.agency?.name;
      }
    },
    {
      title: '媒体名',
      dataIndex: 'mediaName',
      key: 'mediaName',
      width: 140,
      search: false,
      align: 'center',
      render: (_, record: ReportListModel) => {
        return record?.medias.map((item) => item.name).join(',');
      }
    },
    {
      title: 'アカウント名',
      dataIndex: 'accountName',
      key: 'account',
      width: 140,
      search: false,
      align: 'center',
      render: (_, record: ReportListModel) => {
        return record?.account?.username;
      }
    },
    {
      title: 'アクション',
      dataIndex: 'others',
      key: 'others',
      width: 120,
      align: 'center',
      fixed: 'right',
      search: false,

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (_, row: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={'view' + row.id}>
                  <MyLink to={`${PATH_REPORT_DETAILS}/${row.id}`}>表示</MyLink>
                </Menu.Item>
                {role !== 2 && (
                  <Menu.Item key={'edit' + row.id}>
                    <MyLink to={`${PATH_REPORT_UPDATE}/${row.id}`}>更新</MyLink>
                  </Menu.Item>
                )}
                {role !== 2 && (
                  <Menu.Item key={'copy' + row.id}>
                    <MyLink to={`${PATH_REPORT_COPY}/${row.id}`}>コピー</MyLink>
                  </Menu.Item>
                )}
                {role !== 2 && (
                  <Menu.Item key={'del' + row.id}>
                    <MyDelConfirm
                      handleDel={() => submit({ id: row.id })}
                      delSuccess={() => {
                        aliveNotify();
                        reload();
                      }}
                      el={'dropdown'}
                    />
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <a>
              <Space>
                アクション
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      }
    }
  ];
};

// const columns: ProColumns<ReportListModel>[] = []
