import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import MyTable from '../../../../components/MyTable';
// import { MediaListModel } from '../../../account/media/store/model/media.model';
import columns from '../columns';
import { DashboardContext } from '../../store/dashboard-store';

const ManageWatch = () => {
  const { mediaListStore } = useContext(DashboardContext);

  return (
    <div className="watch">
      <h2>監視</h2>
      <div className="tableBox">
        <MyTable
          listStore={mediaListStore}
          columns={columns}
          rowKey={'id'}
          alive={true}
          search={false}
        />
      </div>
    </div>
  );
};

export default observer(ManageWatch);
