import { ProColumns } from '@ant-design/pro-table/lib/typing';
import { Space } from 'antd';
import { MyLink } from '../../../../core/navigate';
import { PATH_MEDIA_UPDATE } from '../../../../router/router-path';
import React from 'react';
import { MediaListModel } from '../store/model/media.model';

const columns: ProColumns<MediaListModel>[] = [
  {
    title: '媒体ID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
    align: 'center',
    sorter: true
  },
  {
    title: '媒体名',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    align: 'left'
  },
  {
    title: '媒体所在地',
    dataIndex: 'location',
    key: 'location',
    ellipsis: true,
    align: 'left'
  },
  {
    title: 'アカウント名',
    dataIndex: 'accountName',
    key: 'account',
    ellipsis: true,
    align: 'left',
    render: (_, record: MediaListModel) => {
      return record?.account?.username;
    }
  },
  {
    title: '登録日',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 110,
    align: 'center'
  },
  {
    title: '最終更新日',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    width: 110,
    align: 'center'
  },
  {
    title: '変更',
    dataIndex: 'others',
    key: 'others',
    align: 'center',
    width: 120,
    render: (_, record: MediaListModel) => {
      return (
        <Space size="middle">
          <MyLink to={`${PATH_MEDIA_UPDATE}/${record.id}`}>変更</MyLink>
        </Space>
      );
    }
  }
];

export default columns;
