import { replaceText } from '../utils/string';
import { ageUnit, unit } from './text';

/**=========date start==========**/
export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE = 'YYYY-MM-DD';
export const TIME = 'HH:mm';
export const FULL_TIME = 'HH:mm';
export const FULL_DATE_FORMAT = 'YYYY/MM/DD (ddd)';
export const YEAR_MONTH_DATE_FORMAT = 'YYYY/MM/DD';
/**=========date end==========**/

/**=========event bus start==========**/
export const EVENT_GO_TO_LOGIN_KEY = 'event_go_to_login_key';
/**=========event bus end==========**/

/**==== is an external link to this website ====**/
export const DIRECT_LINK_HELP_PAGE =
  'https://different-clematis-edf.notion.site/OOH-Analytics-0b55fb3f9c9f4119b8b4a1a4b9c414eb';
/**==========================**/

const ageGroupIndex = '{index0}{index1}';

export const ageGroup = {
  group1: replaceText(ageGroupIndex, '10', ageUnit.smallGroup),
  group2: replaceText(ageGroupIndex, '20', ageUnit.smallGroup),
  group3: replaceText(ageGroupIndex, '30', ageUnit.smallGroup),
  group4: replaceText(ageGroupIndex, '40', ageUnit.smallGroup),
  group5: replaceText(ageGroupIndex, '50', ageUnit.smallGroup),
  group6: replaceText(ageGroupIndex, '60', ageUnit.smallGroup),
  group7: replaceText(ageGroupIndex, '70', ageUnit.bigGroup)
};

export const adFeeLimit = 11;

// from 0s - 15s
export const retentionLength = 16;

export const colorLineGraph = [
  '#e66665', //red
  '#fdaf43', //orange
  '#00c4cd', //blue
  '#75bd7a', //green
  '#b452c5', //purple
  '#42c1f8', //blue light
  '#9ea1a0', //gray
  '#dddee0' //gray
];

export const durationGroup = (value: number) => {
  return value <= 10
    ? {
        value: +value * 60,
        text: replaceText(ageGroupIndex, value.toString(), unit.minutes)
      }
    : {
        value: +value,
        text: replaceText(ageGroupIndex, value.toString(), unit.seconds)
      };
};
// @ts-ignore
export const arrayForOptionHour: number[] = [...new Array(25).keys()];
