import { Menu } from 'antd';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';

import { useIndexPath, useRoleType } from '../../../../hooks/use-auth';
import { MenuModel } from '../../../../router/menu-data';
import {
  PATH_CSV_EXPORT_LIST,
  PATH_REPORT
} from '../../../../router/router-path';
import { PAGE_PATH } from '../../../../shared/enum';
import { DIRECT_LINK_HELP_PAGE } from '../../../../constants';
import { text } from '../../../../constants/text';

interface IProps {
  list: MenuModel[];
  collapsed: boolean;
}

const managePath = 'manage';

const Menus = ({ list, collapsed }: IProps) => {
  const location = useLocation();

  const roleType = useRoleType();
  const indexPath = useIndexPath();

  const menus = list.reduce<MenuModel[]>((m, n) => {
    const _menus = n.menus
      ? n.menus.map((item) => ({ ...item, parentPath: item.path }))
      : [n];
    return [...m, ..._menus];
  }, []);

  const checkPathName = (currentLocation: string, menuItem: MenuModel) => {
    //default url = '/path1/path2/...'
    const parentLocation = currentLocation.split('/')[1];
    const subLocation = currentLocation.split('/')[2];
    const itemPath = menuItem.path.split('/')[2];
    if (parentLocation === managePath) {
      return subLocation === itemPath;
    }
  };

  const target = menus.find((item) => {
    const currentPath = location.pathname;
    const pathSplit = currentPath.split('/');
    return (
      item.path ===
        (pathSplit.includes(PAGE_PATH.report)
          ? PATH_REPORT
          : pathSplit.includes(PAGE_PATH.csv) &&
            (pathSplit.includes('add') || pathSplit.includes('update'))
          ? PATH_CSV_EXPORT_LIST
          : currentPath) ||
      checkPathName(currentPath, item) ||
      item.path === currentPath
    );
  });

  const defaultSelectedKey = target ? target.path : indexPath(roleType);

  const defaultOpenKey =
    list.reduce<{ path: string; index: number } | undefined>((m, n) => {
      const index = defaultSelectedKey.indexOf(n.path);
      if (index !== -1 && (!m || (m && m?.index > index))) {
        return { path: n.path, index };
      }
      return m;
    }, undefined)?.path || '';

  const menuItem = (item: MenuModel) => {
    const { name, path, icon } = item;
    const iconCom = icon ? icon() : undefined;
    return (
      <Menu.Item icon={iconCom} key={path}>
        <Link to={path || ''}>{name}</Link>
      </Menu.Item>
    );
  };

  const MenuList = () => {
    return list
      .filter((item) => item.enableStatus)
      .map((item) => {
        const { path, name, menus, icon } = item;
        const iconCom = icon ? icon() : undefined;
        return menus ? (
          <Menu.SubMenu icon={iconCom} key={path} title={<span>{name}</span>}>
            {menus.map((item) => menuItem(item))}
          </Menu.SubMenu>
        ) : (
          menuItem(item)
        );
      });
  };

  const HelpLink = () => (
    <a
      className="custom-menu ant-menu-item"
      href={DIRECT_LINK_HELP_PAGE}
      target="_blank"
    >
      <span className="anticon anticon-setting ant-menu-item-icon">
        <LinkOutlined />
      </span>
      <span className="ant-menu-title-content">
        {text.sidebar.helpPageTitle}
      </span>
    </a>
  );

  return (
    <Menu
      className={collapsed ? 'mainNav navUnfold' : 'mainNav'}
      defaultSelectedKeys={[defaultSelectedKey]}
      defaultOpenKeys={[defaultOpenKey]}
      mode="inline"
      theme="dark"
      selectedKeys={[defaultSelectedKey]}
    >
      {MenuList()}
      <HelpLink />
    </Menu>
  );
};

export default observer(Menus);
