export interface ViewerResponse {
  averageViewingTime?: number;
  dwellTimeAve: number;
  dwellTimeMinAve?: number;
  dwellTimeSecAve?: number;
  frequency: number;
  otc: number;
  ots: number;
  rating: number;
  reach: number;
  revisitIntervalAve: number;
  vac: number;
  viewingDistanceAve: number;
  vacCost?: number;
  vacNumber?: number;
  otcCost?: number;
  otcNumber?: number;
  viewingProbabilityWithSound?: number;
  viewingProbabilityWithoutSound?: number;
}

export class ViewerService {
  data: ViewerResponse;
  constructor(param: ViewerResponse) {
    this.data = {
      ...param,
      vac: param?.vac || 0,
      reach: param?.reach || 0,
      otc: param?.otc || 0,
      ots: param?.ots || 0,
      frequency: param?.frequency || 0,
      averageViewingTime: param?.averageViewingTime || 0,
      rating: param?.rating || 0,
      revisitIntervalAve: param?.revisitIntervalAve || 0,
      viewingDistanceAve: param?.viewingDistanceAve || 0,
      dwellTimeMinAve: Math.floor(param?.dwellTimeAve / 60) || 0,
      dwellTimeSecAve: Math.floor(param?.dwellTimeAve % 60) || 0,
      vacCost: param?.vacCost || 0,
      vacNumber: param?.vacNumber || 0,
      otcCost: param?.otcCost || 0,
      otcNumber: param?.otcNumber || 0,
      viewingProbabilityWithSound: param?.viewingProbabilityWithSound || 0,
      viewingProbabilityWithoutSound: param?.viewingProbabilityWithoutSound || 0
    };
  }
}
