import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import {
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { LabelLayout } from 'echarts/features';
import { ageChart, sizeChart } from '../utils/charts';

/* eslint-disable @typescript-eslint/no-explicit-any */
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

interface Props {
  data?: Array<{ name: string; value: number | string }>;
  title?: string;
  isReportDetail?: boolean;
}
const EPieChart = (props: Props) => {
  const { isReportDetail } = props;

  const getOption = () => {
    const param = {
      color: [
        '#00c4cd',
        '#75bd7a',
        '#fdaf43',
        '#e66665',
        '#b452c5',
        '#42c1f8',
        '#dddee0',
        '#9ea1a0'
      ]
    };
    return ageChart(props, param);
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{
          width: isReportDetail ? sizeChart.custom : sizeChart.default,
          height: isReportDetail ? sizeChart.print : sizeChart.default
        }}
        ref={echartsEl}
      />
    </div>
  );
};

export default observer(EPieChart);
