import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb } from 'antd';
import MediaEditForm from './components/MediaEditForm';
import { PATH_MEDIA } from '../../../../router/router-path';
import { useParams } from 'react-router-dom';
import { useMyNavigate } from '../../../../core/navigate';
import { MediaContext } from '../store/media-store';
import MyDelConfirm from '../../../../components/MyDelConfirm';
import { useAliveNotify } from '../../../../core/keep-alive/use-keep-alive';

const MediaEdit = () => {
  const params = useParams();
  const navigate = useMyNavigate();
  const aliveNotify = useAliveNotify();

  const {
    delStore: { submit }
  } = useContext(MediaContext);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>設定・登録</Breadcrumb.Item>
        <Breadcrumb.Item>媒体{params.id ? '変更' : '登録'}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea">
        <div className="mainCard editCard">
          {params.id && (
            <div className="cardHeader">
              <MyDelConfirm
                handleDel={() => submit({ id: params?.id })}
                delSuccess={() => {
                  aliveNotify();
                  navigate(PATH_MEDIA);
                }}
              />
            </div>
          )}
          <div className="cardBody">
            <MediaEditForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(MediaEdit);
