import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

type Props = {
  title: string;
  textTitle?: string;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const TooltipIcon = (props: Props) => {
  const { title, textTitle } = props;
  const colorBackground = '#fff';
  return (
    <>
      {textTitle && <span className="mr-2">{textTitle}</span>}
      <Tooltip
        title={title}
        color={colorBackground}
        getPopupContainer={(trigger: any) => trigger.parentNode}
        overlayClassName="tooltip-icon"
      >
        <InfoCircleOutlined className="tooltip-icon" />
      </Tooltip>
    </>
  );
};

export default TooltipIcon;
