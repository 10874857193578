import { useContext, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import {
  Typography,
  Spin,
  Form,
  Row,
  Col,
  Radio,
  Select,
  DatePicker,
  Button,
  message
} from 'antd';
import moment from 'moment';

import { CsvContext } from '../store';
import { CsvExport } from '../../../model/csv';
import { text } from '../../../constants/text';
import { CsvType } from '../../../constants/enum';
import { FULL_DATE_FORMAT, YEAR_MONTH_DATE_FORMAT } from '../../../constants';
import BreadCrumb from '../../../components/Breadcrumb';
import { isTheFutureDate } from '../../../utils/common';

const { Title } = Typography;

/* eslint-disable @typescript-eslint/no-explicit-any */
const CsvManualExport = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const [form] = Form.useForm();

  const {
    mediaSelListStore: {
      list: mediaList,
      loadData: loadMedias,
      isBusy: isLoading
    },
    exportFile: { submit, isBusy }
  } = useContext(CsvContext);

  useEffect(() => {
    loadMedias().then();
  }, [loadMedias]);

  const csvType = [
    {
      name: text.csv.scvType.vac,
      value: CsvType.VAC
    },
    {
      name: text.csv.scvType.otc,
      value: CsvType.OTC
    }
  ];

  const isDisabledDate = (current: any) => {
    // Can not select days before today and today
    return isTheFutureDate(current);
  };

  const onSubmit = async (value: CsvExport) => {
    const body = {
      ...value,
      targetDate: moment(value.targetDate)
        .format(YEAR_MONTH_DATE_FORMAT)
        .split('/')
        .join('')
    };
    const { status } = await submit(body);
    if (!status) {
      message.success(text.csv.manual.exportMsg.success);
    }
    if (status === 400 || status === 403 || status === 404) {
      message.error(text.csv.manual.exportMsg.error);
    }
  };
  return (
    <>
      <BreadCrumb
        mainTitle={text.csv.breadcrumb.mainMenu}
        subTitle={text.breadCrumb.manual.subMenu}
      />
      <div className="subMainArea">
        <div className="mainCard dashboardCard csv-form">
          <div className="cardBody">
            <Spin tip={text.common.loading} spinning={isLoading}>
              <Form
                {...layout}
                form={form}
                onFinish={onSubmit}
                className="form ant-form ant-form-horizontal"
              >
                <Row gutter={24}>
                  <Col span={14}>
                    <Title level={5}>{text.csv.manual.title}</Title>
                    <Form.Item
                      className="mt-10"
                      name="exportType"
                      label={text.csv.exportType}
                      initialValue={CsvType.VAC}
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <Radio.Group>
                        {csvType.map((type, index) => (
                          <Radio value={type.value} key={index}>
                            {type.name}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name="mediaId"
                      label={text.media.singleLabel}
                      rules={[
                        {
                          required: true,
                          message: text.csv.validation.empty.singleMedia
                        }
                      ]}
                    >
                      <Select
                        placeholder={text.media.placeHolder}
                        allowClear={true}
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        optionFilterProp="children"
                        filterOption={(input, option: any) => {
                          return (option?.label.toLowerCase() ?? '').includes(
                            input.toLowerCase()
                          );
                        }}
                        filterSort={(optionA: any, optionB: any) => {
                          return (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare(
                              (optionB?.label ?? '').toLowerCase()
                            );
                        }}
                        options={mediaList.map((media) => {
                          return {
                            label: media.name,
                            value: media.id
                          };
                        })}
                      />
                    </Form.Item>
                    <Form.Item
                      label={text.csv.dataUsage.label}
                      className="dateTime-picker"
                      name="targetDate"
                      rules={[
                        {
                          required: true
                        }
                      ]}
                    >
                      <DatePicker
                        format={FULL_DATE_FORMAT}
                        allowClear={false}
                        disabledDate={isDisabledDate}
                        getPopupContainer={(trigger: any) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <Form.Item
                      className="btnCenter txt-center"
                      wrapperCol={{ span: 16, offset: 4 }}
                    >
                      <Button
                        key="submit"
                        type="primary"
                        onClick={() => form.submit()}
                        loading={isBusy}
                      >
                        {text.csv.manual.bntSubmit}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(CsvManualExport);
