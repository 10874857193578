import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import ProTable, { ActionType } from '@ant-design/pro-table';
import { useAliveUpdate } from '../core/keep-alive/use-keep-alive';
// import { ViewPageListStore } from 'mobx-template';

/* eslint-disable @typescript-eslint/no-explicit-any */
// interface Props<T> extends ProTableProps<T, Record<string, any>> {
//   alive?: boolean;
//   defaultPageSize?: number;
//   listStore: ViewPageListStore<T, any>;
//   sortHandle?: (sort: Record<string, any>) => Record<string, any>;
// }

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
// const MyTable = <T extends unknown>(props: Props<T>) => {
const MyTable = (props: any) => {
  const { listStore, rowKey, alive, defaultPageSize, sortHandle } = props;
  const { loadData, pagination } = listStore;

  const ref = useRef<ActionType>();

  useAliveUpdate(() => {
    if (alive) {
      ref?.current?.reload();
    }
  });

  listStore.reload = () => {
    ref?.current?.reload();
  };

  return (
    <ProTable<any>
      actionRef={ref}
      request={async (params, sort) => {
        const sortData = sortHandle ? sortHandle(sort) : {};
        // @ts-ignore
        return await loadData(
          { page: params.current, ...params, ...sortData },
          { ...params }
        );
      }}
      rowKey={rowKey}
      options={{ reload: true, density: false, setting: false }}
      pagination={{
        showQuickJumper: false,
        defaultCurrent: 1,
        defaultPageSize: defaultPageSize || 10,
        ...pagination
      }}
      {...props}
    />
  );
};

export default observer(MyTable);
