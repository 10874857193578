import React, { useContext, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
import { LineChart } from 'echarts/charts';
import { DashboardContext } from '../store/dashboard-store';
import { NumberUtils } from '../../../utils/number-utils';
import { getDataZoom } from '../../../utils/charts';
import { colorLineGraph } from '../../../constants';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
/* eslint-disable @typescript-eslint/no-explicit-any */

const EchartsStackedArea = () => {
  const {
    summaryStore: { data },
    dataZoom
  } = useContext(DashboardContext);

  const onEvents = {
    dataZoom: (event: any) => {
      if (event.batch) {
        getDataZoom(dataZoom, event.batch[0].start, event.batch[0].end);
      } else {
        getDataZoom(dataZoom, event.start, event.end);
      }
    }
  };

  const tmp: any = [];
  for (let i = 0; i < data?.realtimeCameraDataTrend?.legends.length; i++) {
    const name = data?.realtimeCameraDataTrend?.legends[i];
    const datas = data?.realtimeCameraDataTrend?.series[name.toLowerCase()];
    const temp = {
      name: name,
      type: 'line',
      // stack: 'Total',
      symbol: 'none',
      z: data?.realtimeCameraDataTrend?.legends.length - i,
      areaStyle: {
        opacity: 1
      },
      lineStyle: {
        width: 1
      },
      emphasis: {
        focus: 'series'
      },
      data: datas
    };
    tmp.push(temp);
  }

  const getOption = () => {
    return {
      color: colorLineGraph,
      title: [
        {
          text: '',
          left: 'center'
        }
      ],
      tooltip: {
        trigger: 'axis',
        formatter: (
          params: Array<any>
          // ticket: unknown,
          // callback: () => void
        ) => {
          const headerStr = params[0].axisValue + '<br />';
          // let sum = 0;
          // let totalStr = '';
          let tipString = '';
          let indexColor;
          for (let i = 0, length = params.length; i < length; i++) {
            indexColor = params[i].color;
            tipString += `<div style="width: 150px;margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style="margin: 0;"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${indexColor}"></span>${
              params[i].seriesName
            }</p><p style="margin: 0;">${NumberUtils.toThousands(
              params[i].value
            )}</p> </div>`;
            // sum += parseInt(params[i].value) || 0;
          }
          // totalStr += `<div style="width: 150px; margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style=" margin: 0;">Total</p><p style="margin: 0;">${NumberUtils.toThousands(
          //   sum
          // )}</p></div>`;
          tipString = headerStr + tipString;
          return tipString;
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: data?.realtimeCameraDataTrend?.xAxis || []
        }
      ],
      legend: {
        icon: 'circle',
        data: data?.realtimeCameraDataTrend?.legends || [],
        padding: 20
      },
      yAxis: [
        {
          type: 'value'
        }
      ],
      dataZoom: dataZoom,
      series: tmp
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: '1120px', height: ' 460px' }}
        ref={echartsEl}
        onEvents={onEvents}
      />
    </div>
  );
};

export default observer(EchartsStackedArea);
