import { fetchGet } from '../../../../utils/request';
import { makeAutoObservable } from 'mobx';
import React from 'react';
import { fromSubmitStore } from 'mobx-template';
import { AuthStore } from '../../../../store/auth-store';

export class LayoutStore {
  constructor(private authStore: AuthStore) {
    makeAutoObservable(this);
  }

  // logout store
  logoutReqStore = fromSubmitStore(() => fetchGet('/logout'), {
    successCallback: () => this.authStore.removeAuth()
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const LayoutContext = React.createContext<LayoutStore>(null!);
