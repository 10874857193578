import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb } from 'antd';
import AccountEditForm from './components/AccountEditForm';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_ACCOUNT } from '../../../../router/router-path';
import { AccountContext } from '../store/account-store';
import MyDelConfirm from '../../../../components/MyDelConfirm';
import { RoleType } from '../../../../store/user.model';
import { useRoleType } from '../../../../hooks/use-auth';
import { useAliveNotify } from '../../../../core/keep-alive/use-keep-alive';

const AccountEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const aliveNotify = useAliveNotify();

  const {
    delStore: { submit }
  } = useContext(AccountContext);

  const roleType = useRoleType();

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>設定・登録</Breadcrumb.Item>
        <Breadcrumb.Item>
          アカウント{params.id ? '変更' : '登録'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea">
        <div className="mainCard editCard">
          {params.id && roleType === RoleType.ADMIN && (
            <div className="cardHeader">
              <MyDelConfirm
                handleDel={() => submit({ id: params?.id })}
                delSuccess={() => {
                  aliveNotify();
                  navigate(PATH_ACCOUNT);
                }}
              />
            </div>
          )}
          <div className="cardBody">
            <AccountEditForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(AccountEdit);
