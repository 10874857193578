import { ProColumns } from '@ant-design/pro-table/lib/typing';
import { Space } from 'antd';
import React from 'react';
import { SettingListModel } from '../store/model/setting.model';
import { ViewModalStore } from 'mobx-template';

const columns = (
  modalStore: ViewModalStore
): ProColumns<SettingListModel>[] => {
  return [
    {
      title: 'エッジボックスID',
      dataIndex: 'id',
      key: 'id',
      width: 160,
      align: 'center',
      sorter: true
    },
    {
      title: 'X-API-Key',
      dataIndex: 'apiKey',
      key: 'apiKey',
      ellipsis: true,
      align: 'left'
    },
    {
      title: 'アカウント名',
      dataIndex: 'accountId',
      key: 'accountId',
      align: 'center',
      render: (_, record: SettingListModel) => {
        return record.account?.username;
      }
    },
    {
      title: '媒体名',
      dataIndex: 'mediaId',
      key: 'mediaId',
      align: 'center',
      render: (_, record: SettingListModel) => {
        return record.media?.name;
      }
    },
    {
      title: 'エッジボックス登録日',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center'
    },
    {
      title: '変更',
      dataIndex: 'others',
      key: 'others',
      align: 'center',
      width: 120,
      render: (_, record: SettingListModel) => {
        return (
          <Space size="small">
            <a key="editable" onClick={() => modalStore.openModal(record)}>
              変更
            </a>
          </Space>
        );
      }
    }
  ];
};

export default columns;
