import {
  fetchDel,
  fetchGet,
  fetchPost,
  fetchPut
} from '../../../../utils/request';
import { makeAutoObservable } from 'mobx';
import React from 'react';
import {
  fromObjStore,
  fromPageListStore,
  fromSubmitStore
} from 'mobx-template';
import { AgencyListModel, AgencyModel } from './model/agency.model';

export class AgencyStore {
  readonly apiPrefix = '/agency';

  constructor() {
    makeAutoObservable(this);
  }

  listStore = fromPageListStore<AgencyListModel>((params) =>
    fetchPost(`${this.apiPrefix}/list`, params)
  );

  editStore = fromSubmitStore<AgencyModel>((body: AgencyModel) => {
    const fetch = body.id ? fetchPut : fetchPost;
    return fetch(`${this.apiPrefix}`, body);
  });

  detailStore = fromObjStore<AgencyModel, { id: string }>(
    ({ id }) => fetchGet(`${this.apiPrefix}/${id}`),
    {
      successCallback: (data: AgencyModel) => {
        this.detailStore.setData({
          accountName: data.account?.username,
          id: data.id
        });
      }
    }
  );

  delStore = fromSubmitStore<{ id: string }>(({ id }) =>
    fetchDel(`${this.apiPrefix}/${id}`)
  );
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AgencyContext = React.createContext<AgencyStore>(null!);
