import { useContext } from 'react';
import { Dropdown, Menu, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { CsvContext } from '../store';
import { useAliveNotify } from '../../../core/keep-alive/use-keep-alive';
import { MyLink } from '../../../core/navigate';
import { text } from '../../../constants/text';
import { CsvType, CsvTypeText } from '../../../constants/enum';
import { MediaListModel } from '../../account/media/store/model/media.model';
import MyDelConfirm from '../../../components/MyDelConfirm';

/* eslint-disable @typescript-eslint/no-explicit-any */
const columnsData = () => {
  const aliveNotify = useAliveNotify();
  const {
    deleteConfig: { submit },
    csvConfigList: { reload }
  } = useContext(CsvContext);

  return [
    {
      title: text.csv.list.id,
      dataIndex: 'id',
      key: 'id',
      width: 50,
      align: 'center',
      sorter: true
    },
    {
      title: text.csv.list.exportType.title,
      dataIndex: 'exportType',
      key: 'exportType',
      width: 80,
      align: 'center',
      render: (value: CsvType) => CsvTypeText[value]
    },
    {
      title: text.csv.list.companyName,
      dataIndex: 'companyName',
      key: 'companyName',
      ellipsis: true,
      width: 180,
      align: 'center'
    },
    {
      title: text.csv.list.media,
      dataIndex: 'medias',
      key: 'medias',
      width: 240,
      search: false,
      align: 'left',
      render: (value: MediaListModel[]) => {
        return value.length > 0
          ? value.map((item: MediaListModel) => item?.name).join(',\n')
          : '';
      }
    },
    {
      title: text.csv.list.createAt,
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center',
      width: 90
    },
    {
      title: text.csv.list.action.title,
      dataIndex: 'others',
      key: 'others',
      align: 'center',
      width: 90,
      render: (_: any, row: any) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={`edit-${row.id}`}>
                  <MyLink to={`/csv/update/${row.id}`}>
                    {text.csv.list.action.edit}
                  </MyLink>
                </Menu.Item>
                <Menu.Item key={`delete-${row.id}`}>
                  <MyDelConfirm
                    title={text.csv.modal.deleteConfirm}
                    handleDel={() => submit({ id: row.id })}
                    delSuccess={() => {
                      aliveNotify();
                      reload();
                    }}
                    el={'dropdown'}
                  />
                </Menu.Item>
              </Menu>
            }
          >
            <a>
              <Space>
                {text.csv.list.action.title}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      }
    }
  ];
};

export default columnsData;
