import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Card } from 'antd';
import EchartsStackedLine from '../../components/EchartsStackedLine';
import { ReportContext } from '../../store/report-store';
import { useParams } from 'react-router-dom';
import EPieChart from '../../../../components/PieChart';
import ERadarChart from '../../../../components/RadarChart';
import EAreaChart from '../../../../components/AreaChart';
import Viewer from '../../../../components/Viewer';
import { ViewerService } from '../../../../shared/services/viewer';
import {
  browserName,
  detectBrowser,
  getTextReportStatus
} from '../../../../utils/common';
import {
  getAgeProportion,
  getGenderProportion,
  getMovingAndStayingProportion
} from '../../../../utils/charts';
import { text } from '../../../../constants/text';
import TooltipIcon from '../../../../components/TooltipIcon';

interface Props {
  printTotalPage: number;
  viewCost: number;
  totalView: number;
}

const AdPeriod = (props: Props) => {
  const { printTotalPage, viewCost, totalView } = props;
  const params = useParams();

  const {
    detailStore: { data: reportDef },
    viewWholeStore: { data: reportWhole, loadData: loadReportWholeData }
  } = useContext(ReportContext);

  useEffect(() => {
    if (params?.id) {
      loadReportWholeData({ id: params?.id }).then();
    }
  }, [loadReportWholeData, params?.id]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.report.viewingPeopleTitle}
                title={text.tooltip.report.generalGraph}
              />
            }
          >
            <EchartsStackedLine data={reportWhole?.cameraDataChartByDays} />
          </Card>
        </Col>
        <Viewer
          data={new ViewerService(reportWhole?.viewerAnalyticsBoard).data}
          isReportPage={true}
          isShowCost={true}
          adFee={viewCost}
          totalView={totalView}
          reportStatus={getTextReportStatus(
            reportDef?.adStartDate,
            reportDef?.status
          )}
        />
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.gender}
                title={text.tooltip.report.genderChart}
              />
            }
          >
            <EPieChart
              data={getGenderProportion(reportWhole?.genderProportionPieGraph)}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.ageMale}
                title={text.tooltip.report.ageMaleChart}
              />
            }
          >
            <EPieChart
              data={getAgeProportion(reportWhole?.ageProportionMalePieGraph)}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.ageFemale}
                title={text.tooltip.report.ageFemaleChart}
              />
            }
          >
            <EPieChart
              data={getAgeProportion(reportWhole?.ageProportionFemalePieGraph)}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={
              <TooltipIcon
                textTitle={text.chart.title.reportRadarChart}
                title={text.tooltip.report.radarChart}
              />
            }
          >
            <ERadarChart
              data={reportWhole?.walkingDirectionGraph}
              isHasBorder={true}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.movingAndStaying}
                title={text.tooltip.report.stayAndMovingChart}
              />
            }
          >
            <EPieChart
              data={getMovingAndStayingProportion(
                reportWhole?.movingStationaryPieGraph
              )}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={
              <TooltipIcon
                textTitle={text.chart.title.reportViewingTime}
                title={text.tooltip.report.retentionChart}
              />
            }
          >
            <EAreaChart
              data={reportWhole?.viewerRetentionGraph}
              isHasBorder={true}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <div
          className={`print-tail 
        ${
          detectBrowser() === browserName.SAFARI
            ? 'top-page-safari-2'
            : 'top-page-2'
        }`}
        >
          <p className="page-number">2/{printTotalPage}</p>
        </div>
      </Row>
      <div
        className={`print-tail 
      ${
        detectBrowser() === browserName.SAFARI
          ? 'top-page-safari-3'
          : 'top-page-3'
      }`}
      >
        <p className="page-number">3/{printTotalPage}</p>
      </div>
    </>
  );
};

export default observer(AdPeriod);
