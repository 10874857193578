import React from 'react';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/es/locale/ja_JP';
import { RootRouters } from './router/RootRouters';
import { useConfigSetUp } from './hooks/use-config-setup';
import { useAliveAutoFixContext } from './core/keep-alive/use-keep-alive';
import './App.scss';
import './theme.less';
import 'moment/locale/ja';
import './stylesheet/styles.scss';

const App = () => {
  useConfigSetUp();

  useAliveAutoFixContext();

  return (
    <ConfigProvider locale={jaJP}>
      <RootRouters />
    </ConfigProvider>
  );
};

export default App;
