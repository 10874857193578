import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { useModalCloseEffect } from 'mobx-template';

import { SettingContext } from '../store/setting-store';
import { useFormInit } from '../../../../core/form';
import { text } from '../../../../constants/text';
import { MediaSettingErrorRes } from '../../../../constants/enum';

const { Option } = Select;
const SettingModal = () => {
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 }
  };

  const {
    editStore,
    modalStore,
    listStore,
    accountListStore: { list: accountList },
    mediaListStore: { loadData: loadMediaListData, list: mediaList }
  } = useContext(SettingContext);

  const { isBusy, submit } = editStore;
  const { visible, data: modalData, closeModal, isUpdate } = modalStore;

  const actionText = isUpdate ? '変更' : '新規登録';
  const [accountId, setAccountId] = useState<string>('');
  const [mediaId, setMediaId] = useState<number>(0);

  useEffect(() => {
    if (modalData?.mediaId) {
      setMediaId(modalData.mediaId);
      form.setFieldsValue({ mediaCode: modalData.media.mediaCode });
      form.setFieldsValue({ sensorId: modalData.media.sensorId });
      form.setFieldsValue({ sensorToken: modalData.media.sensorToken });
    }
    if (modalData?.accountId) {
      setAccountId(modalData.accountId);
    }
  }, [modalData?.mediaId, modalData?.accountId]);

  useEffect(() => {
    loadMediaListData({ accountId: accountId }).then(({ success, data }) => {
      if (success && data) {
        setMediaId(data[0]?.id);
      }
    });
  }, [loadMediaListData, accountId]);

  useModalCloseEffect(() => {
    form.resetFields();
  }, modalStore);

  useFormInit(form, { ...modalData, mediaId: isUpdate ? mediaId : null });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (values: any) => {
    const { success, status, errorMessage } = await submit(values);
    if (success) {
      listStore.reload();
      closeModal();
    }
    if (status === 400) {
      if (errorMessage === MediaSettingErrorRes.MEDIA) {
        message.error(text.mediaSetting.errorMsg.mediaCode);
      } else {
        message.error(text.mediaSetting.errorMsg.sensor);
      }
    }
  };

  return (
    <Modal
      className="media-setting-modal"
      visible={visible}
      title={actionText}
      onCancel={closeModal}
      getContainer={false}
      centered
      forceRender
      footer={[
        <Button key="back" onClick={() => closeModal()}>
          キャンセル
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={isBusy}
        >
          登録・更新
        </Button>
      ]}
    >
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        className="form"
        initialValues={{
          mediaCode: modalData?.media?.mediaCode,
          sensorId: modalData?.media?.sensorId,
          sensorToken: modalData?.media?.sensorToken
        }}
      >
        <Form.Item name="id" label="エッジボックスID">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="apiKey"
          label="X-API-Key"
          rules={[{ required: true, message: 'X-API-Keyを入力してください' }]}
        >
          <Input placeholder="X-API-Key名を入力してください" />
        </Form.Item>
        <Form.Item
          name="accountId"
          label="アカウント名"
          rules={[
            { required: true, message: 'アカウント名を選択してください' }
          ]}
        >
          <Select
            placeholder="アカウント名を選択してください"
            onChange={(e: string) => setAccountId(e)}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {accountList.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.username}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="mediaId"
          label="媒体"
          rules={[{ required: true, message: '媒体を選択してください' }]}
        >
          <Select
            placeholder="媒体を選択してください"
            onChange={(e: number) => setMediaId(e)}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            {mediaList.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="mediaCode"
          label={text.mediaSetting.mediaCode}
          rules={[
            { required: true, message: text.mediaSetting.validation.mediaCode }
          ]}
        >
          <Input
            value={modalData?.media.mediaCode}
            placeholder={text.mediaSetting.placeHolder.mediaCode}
          />
        </Form.Item>
        <Form.Item
          name="sensorId"
          label={text.mediaSetting.sensorId}
          rules={[
            { required: true, message: text.mediaSetting.validation.sensorId }
          ]}
        >
          <Input placeholder={text.mediaSetting.placeHolder.sensorId} />
        </Form.Item>
        <Form.Item
          name="sensorToken"
          label={text.mediaSetting.sensorToken}
          rules={[
            {
              required: true,
              message: text.mediaSetting.validation.sensorToken
            }
          ]}
        >
          <Input placeholder={text.mediaSetting.placeHolder.sensorToken} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(SettingModal);
