import axios from 'axios';

export const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_API_ENDPOINT
});
/* eslint-disable @typescript-eslint/no-explicit-any */
export const fetchGet = (url: string): Promise<any> => {
  return instance.get(url).then((res) => res.data);
};

export const fetchPost = (url: string, data?: any): Promise<any> => {
  return instance.post(url, data).then((res) => res.data);
};

export const fetchPut = (url: string, data?: any): Promise<any> => {
  return instance.put(url, data).then((res) => res.data);
};

export const fetchDel = (url: string): Promise<any> => {
  return instance.delete(url).then((res) => res.data);
};
