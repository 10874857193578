import { useContext, useState } from 'react';
import { Button, Form, Input, Checkbox, Row, Col, Modal } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons/lib';
import { observer } from 'mobx-react-lite';
import { LoginModel } from '../store/model/login.model';
// import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../store/login-store';
import { useIndexPath } from '../../../../hooks/use-auth';
import './LoginForm.scss';

/* eslint-disable @typescript-eslint/no-explicit-any */
const LoginForm = () => {
  // const navigate = useNavigate();

  const indexPath = useIndexPath();

  const {
    loginReqStore: { body, submit, errorMessage, setErrorMessage, isBusy }
  } = useContext(LoginContext);

  /**
   * ログインを送信
   * @param values データ
   */
  const onFinish = async (values: LoginModel) => {
    const { success, data } = await submit(values, { showMessage: false });
    if (success) {
      window.location.href = indexPath(data?.role.id);
      // navigate(indexPath(data?.role.id), { replace: true });
    }
  };

  const onFinishFailed = ({ errorFields }: any) => {
    if (errorFields.length) {
      setErrorMessage(errorFields[0].errors[0]);
    }
  };

  const [userID, setUserID] = useState(body.id);
  const [userPassword, setUserPassword] = useState(body.password);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Form
        className="msgBox"
        initialValues={body}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <h1>{'OOH Analytics'}</h1>
        <Form.Item
          name="id"
          noStyle
          rules={[{ required: true, message: 'ユーザIDを入力してください' }]}
        >
          <Input
            prefix={<UserOutlined />}
            onBlur={(e) => setUserID(e.target.value)}
            onChange={(e) => setUserID(e.target.value)}
            placeholder="ユーザIDを入力してください"
          />
        </Form.Item>

        <Form.Item
          name="password"
          noStyle
          rules={[{ required: true, message: 'パスワードを入力してください' }]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            type="password"
            onBlur={(e) => setUserPassword(e.target.value)}
            onChange={(e) => setUserPassword(e.target.value)}
            placeholder="パスワードを入力してください"
          />
        </Form.Item>
        <div className="errorMessage">{errorMessage}</div>
        <Row gutter={8} className="remember">
          <Col span={12}>
            <Form.Item name="isRemember" valuePropName="checked">
              <Checkbox>ログイン情報を維持する</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12} className="forgetPsw">
            <Button className="forgetPswBtn" type="link" onClick={showModal}>
              パスワードを忘れた方はこちら
            </Button>
          </Col>
        </Row>
        <Form.Item className="loginBtnBox" label="" colon={false}>
          <Button
            className="loginBtn"
            type="primary"
            htmlType="submit"
            loading={isBusy}
            disabled={userID === '' || userPassword === ''}
          >
            ログイン
          </Button>
        </Form.Item>
      </Form>

      <Modal
        className="contactModal"
        visible={isModalVisible}
        footer={[
          <Button type="primary" onClick={handleOk}>
            OK
          </Button>
        ]}
      >
        <p>運営にお問い合わせください。</p>
        <p>メールアドレス: ohh@gmail.com</p>
      </Modal>
    </>
  );
};

export default observer(LoginForm);
