import React from 'react';
import { observer } from 'mobx-react-lite';
import { ReportContext, ReportStore } from '../store/report-store';
import { MyKeepAlive } from '../../../core/keep-alive/MyKeepAlive';
import ReportDetails from './ReportDetails';

export default observer(() => {
  return (
    <ReportContext.Provider value={new ReportStore()}>
      <MyKeepAlive onlyOne={true}>
        <ReportDetails />
      </MyKeepAlive>
    </ReportContext.Provider>
  );
});
