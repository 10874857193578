import { observer } from 'mobx-react-lite';

import { CsvContext, CsvStore } from '../store';
import { MyKeepAlive } from '../../../core/keep-alive/MyKeepAlive';
import CsvManualExport from './ManualExport';

export default observer(() => {
  return (
    <CsvContext.Provider value={new CsvStore()}>
      <MyKeepAlive onlyOne={true}>
        <CsvManualExport />
      </MyKeepAlive>
    </CsvContext.Provider>
  );
});
