import React, { useContext, useEffect } from 'react';
import { Button, Col, Form, Input, Row, Spin, InputNumber } from 'antd';
import { observer } from 'mobx-react-lite';
import { PATH_AGENCY } from '../../../../../router/router-path';
import { useParams, useNavigate } from 'react-router-dom';
import { useAliveNotify } from '../../../../../core/keep-alive/use-keep-alive';
import { useFormInit } from '../../../../../core/form';
import { AgencyContext } from '../../store/agency-store';
import { AuthContext } from '../../../../../store/auth-store';
import { RoleType } from '../../../../../store/user.model';
import { useRoleType } from '../../../../../hooks/use-auth';
import { useAccountName } from '../../../../../hooks/use-account-name';

const AgencyEditForm = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const aliveNotify = useAliveNotify();

  const roleType = useRoleType();

  const accountName = useAccountName();

  const {
    detailStore: { data, loadData, setData, isBusy: loading },
    editStore: { submit, isBusy },
    delStore: { isBusy: delLoading }
  } = useContext(AgencyContext);

  const {
    userStore: { data: userData }
  } = useContext(AuthContext);

  useEffect(() => {
    if (params?.id) {
      loadData({ id: params?.id }).then();
    } else {
      if (roleType !== RoleType.ADMIN) {
        setData({ accountId: userData?.id, accountName: userData?.username });
      }
    }
  }, [loadData, params?.id, setData, userData]);

  useFormInit(form, data);

  const handleBlur = (accountId: string) => {
    if (accountId) {
      accountName.loadData(
        accountId,
        (accountName) => setData({ accountId, accountName }),
        () => setData({ accountId: accountId, accountName: '' })
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (values: any) => {
    const { success } = await submit(values);
    if (success) {
      aliveNotify();
      navigate(PATH_AGENCY);
    }
  };

  return (
    <Spin
      tip="読み込み..."
      spinning={loading || delLoading || accountName.isBusy}
    >
      <Form
        form={form}
        {...layout}
        name="agency"
        onFinish={onFinish}
        className="form"
      >
        <Row gutter={24}>
          <Col span={12}>
            {roleType === RoleType.ADMIN ? (
              <Form.Item
                name="accountId"
                label="アカウントID"
                rules={[
                  {
                    required: true,
                    message: 'アカウントIDを入力してください'
                  }
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="アカウントIDを入力してください"
                  onBlur={(e) => handleBlur(e.target.value)}
                />
              </Form.Item>
            ) : (
              <Form.Item name="accountId" label="アカウントID">
                <Input disabled />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item name="accountName" label="アカウント名">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="id" label="代理店ID">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label="代理店名"
              rules={[
                { required: true, message: '代理店名を入力してください' }
              ]}
            >
              <Input maxLength={50} placeholder="代理店名を入力してください" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="address" label="住所">
              <Input maxLength={255} placeholder="住所を入力してください" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="telNo" label="電話番号">
              <Input maxLength={20} placeholder="電話番号を入力してください" />
            </Form.Item>
            <p style={{ position: 'absolute', top: '32px', right: '12px' }}>
              ※半角数字(ハイフン無し)
            </p>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label="メールアドレス"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'メールアドレスを入力してください'
                }
              ]}
            >
              <Input
                maxLength={50}
                placeholder="メールアドレスを入力してください"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              className="btnCenter"
              wrapperCol={{ span: 16, offset: 4 }}
            >
              <Button onClick={() => navigate(PATH_AGENCY)}>キャンセル</Button>
              <Button type="primary" loading={isBusy} htmlType="submit">
                {params.id ? '変更' : '登録'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(AgencyEditForm);
