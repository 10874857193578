import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb, Card, Col, Row, Spin } from 'antd';
import EchartsStackedArea from '../components/EchartsStackedArea';
import MyTime from '../../../components/MyTime';
import MediaForm from './components/MediaForm';
import MediaInfo from './components/MediaInfo';
import moment from 'moment';
import { DashboardContext } from '../store/dashboard-store';
import EPieChart from '../../../components/PieChart';
import { DATE } from '../../../constants';
import Viewer from '../../../components/Viewer';
import { ViewerService } from '../../../shared/services/viewer';
import { text } from '../../../constants/text';
import ERadarChart from '../../../components/RadarChart';
import EAreaChart from '../../../components/AreaChart';
import {
  getAgeProportion,
  getGenderProportion,
  getMovingAndStayingProportion
} from '../../../utils/charts';
import EChartsStackLine from '../components/EChartStackLine';
import TooltipIcon from '../../../components/TooltipIcon';

const MediaMain = () => {
  const [mediaID, setMediaID] = React.useState<number>(0);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [mediaDate, setMediaDate] = React.useState<any>(
    moment(moment().format(DATE))
  );

  const [time, setTime] = useState(moment().valueOf());
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().valueOf());
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const myImagePath = `/imgs/${mediaID}/last_scene.jpg?${time}`;

  const {
    summaryStore: { data: summaryData, isBusy: loading },
    mediaSelListStore: {
      loadData: loadMediaListData,
      list: mediaList,
      isBusy: mediaLoading
    }
  } = useContext(DashboardContext);

  useEffect(() => {
    loadMediaListData().then();
  }, [loadMediaListData]);

  useEffect(() => {
    if (mediaList.length) {
      setMediaID(mediaList[0].id);
    }
  }, [mediaList]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>ダッシュボード</Breadcrumb.Item>
        <Breadcrumb.Item>媒体</Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea">
        <div className="mainCard dashboardCard">
          <div className="cardHeader">
            <div className="selArea">
              <MediaForm
                setMediaID={setMediaID}
                mediaID={mediaID}
                mediaDate={mediaDate}
                setMediaDate={setMediaDate}
              />
            </div>
          </div>
          <div className="cardBody dashboardBody">
            <Spin
              tip="読み込み..."
              wrapperClassName="cardLoading"
              spinning={loading || mediaLoading}
            >
              <Row gutter={[16, 16]} className="dashboardViewer">
                <Col span={24}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.area}
                        title={text.tooltip.dashboard.areaGraph}
                      />
                    }
                  >
                    <EchartsStackedArea />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card>
                    <div className="dashboardImg">
                      <img src={myImagePath} alt="dashboard-img" />
                      <p className="time">
                        <MyTime />
                        <span className="live">LIVE</span>
                      </p>
                    </div>
                  </Card>
                </Col>
                <Col span={16}>
                  <Card
                    title="（媒体）基本情報"
                    className="strong-header"
                    style={{ height: '100%' }}
                  >
                    <MediaInfo mediaID={mediaID} />
                  </Card>
                </Col>
                <Viewer
                  data={
                    new ViewerService(summaryData?.viewerAnalyticsBoard).data
                  }
                />
                <Col span={24}>
                  <Card
                    title={'視聴態度'}
                    className="strong-header chart-group"
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={8}>
                        <Card
                          title={
                            <TooltipIcon
                              textTitle={text.chart.title.gender}
                              title={text.tooltip.dashboard.genderChart}
                            />
                          }
                        >
                          <EPieChart
                            data={getGenderProportion(
                              summaryData?.genderProportionPieGraph
                            )}
                          />
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          title={
                            <TooltipIcon
                              textTitle={text.chart.title.ageMale}
                              title={text.tooltip.dashboard.ageMaleChart}
                            />
                          }
                        >
                          <EPieChart
                            data={getAgeProportion(
                              summaryData?.ageProportionMalePieGraph
                            )}
                          />
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          title={
                            <TooltipIcon
                              textTitle={text.chart.title.ageFemale}
                              title={text.tooltip.dashboard.ageFemaleChart}
                            />
                          }
                        >
                          <EPieChart
                            data={getAgeProportion(
                              summaryData?.ageProportionFemalePieGraph
                            )}
                          />
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          title={
                            <TooltipIcon
                              textTitle={text.chart.title.radarChart}
                              title={text.tooltip.dashboard.radarChart}
                            />
                          }
                        >
                          <ERadarChart
                            data={summaryData?.walkingDirectionGraph}
                            isHasBorder={true}
                          />
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          title={
                            <TooltipIcon
                              textTitle={text.chart.title.movingAndStaying}
                              title={text.tooltip.dashboard.stayAndMovingChart}
                            />
                          }
                        >
                          <EPieChart
                            data={getMovingAndStayingProportion(
                              summaryData?.movingStationaryPieGraph
                            )}
                          />
                        </Card>
                      </Col>
                      <Col span={8}>
                        <Card
                          title={
                            <TooltipIcon
                              textTitle={text.chart.title.viewingTime}
                              title={text.tooltip.dashboard.retentionChart}
                            />
                          }
                        >
                          <EAreaChart
                            data={summaryData?.viewerRetentionGraph}
                          />
                        </Card>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col span={24}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.dailyChart}
                        title={text.tooltip.dashboard.dailyGraph}
                      />
                    }
                  >
                    <EChartsStackLine
                      showTotal={
                        summaryData?.realtimeTrendByMedia24Hours?.legends
                          ?.length > 1
                      }
                    />
                  </Card>
                </Col>
              </Row>
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(MediaMain);
