import React, { useContext, useEffect } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Space,
  InputNumber,
  Radio,
  Select
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfigService } from 'mobx-template';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { PATH_MEDIA } from '../../../../../router/router-path';
import { useAliveNotify } from '../../../../../core/keep-alive/use-keep-alive';
import { useFormInit } from '../../../../../core/form';
import { MediaContext } from '../../store/media-store';
import { useRoleType } from '../../../../../hooks/use-auth';
import { RoleType } from '../../../../../store/user.model';
import { AuthContext } from '../../../../../store/auth-store';
import { useAccountName } from '../../../../../hooks/use-account-name';
import TimeHHmm from '../../../../../components/TimeHHmm';
import {
  compareOtherHour,
  formatHour
} from '../../../../../utils/report-utils';
import { arrayForOptionHour } from '../../../../../constants';
import { text } from '../../../../../constants/text';

const { Option } = Select;
/* eslint-disable @typescript-eslint/no-explicit-any */
const MediaEditForm = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const params = useParams();

  const aliveNotify = useAliveNotify();

  const roleType = useRoleType();

  const accountName = useAccountName();

  const {
    detailStore: { data, loadData, setData, isBusy: loading },
    editStore: { submit, isBusy, body },
    delStore: { isBusy: delLoading }
  } = useContext(MediaContext);

  const {
    userStore: { data: userData }
  } = useContext(AuthContext);

  useEffect(() => {
    if (params?.id) {
      loadData({ id: params?.id }).then((res: any) => {
        form.setFieldsValue({
          timeRangeHasAudio: res.data.listTimeRangeHasAudio
        });
      });
    } else {
      if (roleType !== RoleType.ADMIN) {
        setData({
          accountId: userData?.id,
          accountName: userData?.username
        });
      }
      setData({
        hasAudio: true
      });
    }
  }, [loadData, params?.id, setData, userData]);

  useFormInit(form, {
    ...data,
    times: [
      data?.opStartTime || body?.opStartTime,
      data?.opEndTime || body?.opEndTime
    ]
  });

  const handleBlur = (accountId: string) => {
    if (accountId) {
      accountName.loadData(
        accountId,
        (accountName) => setData({ accountId, accountName }),
        () => setData({ accountId: accountId, accountName: '' })
      );
    }
  };
  const handleChange = (val: boolean) => {
    setData({
      hasAudio: val,
      timeRangeHasAudio: [
        {
          startTime: '',
          endTime: ''
        }
      ]
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (values: any) => {
    const startTime = form.getFieldValue('opStartTime');
    const endTime = form.getFieldValue('opEndTime');
    const startHour = Number(startTime.split(':')[0]);
    const startMinute = Number(startTime.split(':')[1]);
    const endHour = Number(endTime.split(':')[0]);
    const endMinute = Number(endTime.split(':')[1]);

    if (
      startHour > endHour ||
      (startHour === endHour && startMinute > endMinute)
    ) {
      ConfigService.config.showErrorMessage({
        message: '媒体運営時間が誤っています。'
      });
    } else {
      const { success } = await submit(values);
      if (success) {
        aliveNotify();
        navigate(PATH_MEDIA);
      }
    }
  };

  const hourSelector = (field: any, conditionIdx: number, name: string) => {
    return (
      <Form.Item
        name={[field.name, name]}
        fieldKey={[field.fieldKey, name]}
        className="space-item time-box-item"
        rules={[
          {
            required: true,
            validator(rule, value) {
              const timeFields = form?.getFieldsValue();
              const excTime = timeFields?.timeRangeHasAudio?.[conditionIdx];
              if (!timeFields?.opStartTime || !timeFields?.opEndTime) {
                return Promise.reject(
                  new Error(text.media.validation.mediaTimeEmpty)
                );
              }
              if (!value) {
                return Promise.reject(
                  new Error(text.media.validation.required)
                );
              }

              const isDuplicatedTime = timeFields.timeRangeHasAudio.find(
                (time: any) => {
                  return (
                    excTime?.startTime &&
                    excTime?.endTime &&
                    ((compareOtherHour(time?.startTime, value) &&
                      compareOtherHour(value, time?.endTime)) ||
                      excTime?.startTime === excTime?.endTime ||
                      !compareOtherHour(excTime?.startTime, excTime?.endTime))
                  );
                }
              );
              if (isDuplicatedTime) {
                return Promise.reject(
                  new Error(text.media.validation.invalidTime)
                );
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Select
          style={{ width: 90 }}
          getPopupContainer={(trigger) => trigger.parentNode}
        >
          {arrayForOptionHour.map((value, index) => {
            const _value = formatHour(value);
            return (
              <Option value={_value} key={index}>
                {_value}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };

  return (
    <Spin
      tip="読み込み..."
      spinning={loading || delLoading || accountName.isBusy}
    >
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        className="form media-form"
      >
        <Row gutter={24}>
          <Col span={12}>
            {roleType === RoleType.ADMIN ? (
              <Form.Item
                name="accountId"
                label="アカウントID"
                rules={[
                  {
                    required: true,
                    message: 'アカウントIDを入力してください'
                  }
                ]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  placeholder="アカウントIDを入力してください"
                  onBlur={(e) => handleBlur(e.target.value)}
                />
              </Form.Item>
            ) : (
              <Form.Item name="accountId" label="アカウントID">
                <Input disabled />
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item name="accountName" label="アカウント名">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="id" label="媒体ID">
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label="媒体名"
              rules={[{ required: true, message: '媒体名を入力してください' }]}
            >
              <Input maxLength={50} placeholder="媒体名を入力してください" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="location"
              label="媒体所在地"
              rules={[
                { required: true, message: '媒体所在地を入力してください' }
              ]}
            >
              <Input
                maxLength={255}
                placeholder="媒体所在地を入力してください"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="媒体運営時間">
              <Space
                size="small"
                align="baseline"
                className="flex-center-y time-duration-section"
              >
                <Form.Item
                  label="開始"
                  name="opStartTime"
                  className="media-duration"
                  rules={[
                    {
                      required: true,
                      message: '媒体運営開始時間を入力してください'
                    }
                  ]}
                >
                  <TimeHHmm />
                </Form.Item>
                <span className="mt-2">〜</span>
                <Form.Item
                  label="終了"
                  name="opEndTime"
                  className="media-duration"
                  rules={[
                    {
                      required: true,
                      message: '媒体運営終了時間を入力してください'
                    }
                  ]}
                >
                  <TimeHHmm />
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <Form.Item
              label={text.media.sound.haveSound}
              name="viewingProbabilityWithSound"
              rules={[
                {
                  required: true,
                  validator(rule, value) {
                    if (!value && value !== 0) {
                      return Promise.reject(text.media.validation.required);
                    } else {
                      if (value >= 0 && value <= 100) {
                        return Promise.resolve();
                      }
                      return Promise.reject(text.media.validation.invalidValue);
                    }
                  }
                }
              ]}
            >
              <InputNumber
                disabled={roleType !== RoleType.ADMIN}
                maxLength={5}
                placeholder="0"
                className="mb-1 media-percent"
              />
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          <Col span={12}>
            <Form.Item
              label={text.media.sound.noSound}
              name="viewingProbabilityWithoutSound"
              rules={[
                {
                  required: true,
                  validator(rule, value) {
                    if (!value && value !== 0) {
                      return Promise.reject(text.media.validation.required);
                    } else {
                      if (value >= 0 && value <= 100) {
                        return Promise.resolve();
                      }
                      return Promise.reject(text.media.validation.invalidValue);
                    }
                  }
                }
              ]}
            >
              <InputNumber
                disabled={roleType !== RoleType.ADMIN}
                maxLength={5}
                placeholder="0"
                className="mb-1 media-percent"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="hasAudio"
              label={text.media.sound.title}
              style={{ marginBottom: '10px' }}
            >
              <Radio.Group
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              >
                <Radio value={true}>音声あり</Radio>
                <Radio value={false}>音声なし</Radio>
              </Radio.Group>
            </Form.Item>
            {(data?.hasAudio || form.getFieldValue('hasAudio')) && (
              <>
                <Form.Item
                  label={text.media.sound.comment}
                  name="audioComment"
                  style={{ justifyContent: 'right' }}
                >
                  <Input
                    maxLength={100}
                    placeholder={text.media.sound.commentPlaceholder}
                    className="mb-1"
                  />
                </Form.Item>
                <Form.List
                  initialValue={[
                    {
                      startTime: '',
                      endTime: ''
                    }
                  ]}
                  name={['timeRangeHasAudio']}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => {
                        return (
                          <Form.Item
                            label={text.media.sound.duration}
                            name={[field.name]}
                            fieldKey={[field.fieldKey]}
                            key={index}
                            className="time-box-group"
                            rules={[{ required: true, message: '' }]}
                          >
                            <Space size="large" align="baseline">
                              {hourSelector(field, index, 'startTime')}
                              <span className="sign">〜</span>
                              {hourSelector(field, index, 'endTime')}
                            </Space>
                            {fields.length > 1 && (
                              <MinusCircleOutlined
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            )}
                          </Form.Item>
                        );
                      })}
                      <Form.Item className="btn-add">
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          {text.media.btnAddTime}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </>
            )}
          </Col>

          <Col span={24}>
            <Form.Item
              className="btnCenter"
              wrapperCol={{ span: 16, offset: 4 }}
            >
              <Button onClick={() => navigate(PATH_MEDIA)}>キャンセル</Button>
              <Button
                type="primary"
                loading={isBusy}
                onClick={() => form.submit()}
              >
                {params.id ? '変更' : '登録'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(MediaEditForm);
