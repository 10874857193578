import React, { useContext } from 'react';
import { Badge, Button, List, Modal, Popover } from 'antd';
import { observer } from 'mobx-react-lite';
import {
  BellOutlined,
  ExclamationCircleOutlined,
  LoginOutlined
} from '@ant-design/icons/lib';
import {
  PATH_ACCOUNT_UPDATE,
  PATH_LOGIN
} from '../../../../router/router-path';
import { LayoutContext } from '../store/layout-store';
import { AuthContext } from '../../../../store/auth-store';
import { RoleType } from '../../../../store/user.model';
import './Setting.scss';
import { useMyNavigate } from '../../../../core/navigate';
import { text } from '../../../../constants/text';

const Setting = () => {
  const navigate = useMyNavigate();

  const {
    logoutReqStore: { submit }
  } = useContext(LayoutContext);
  const {
    userStore: { data }
  } = useContext(AuthContext);

  const handleLogout = () => {
    Modal.confirm({
      title: '確認',
      centered: true,
      icon: <ExclamationCircleOutlined />,
      content: 'ログアウトしてよろしいですか？',
      okText: '実行',
      cancelText: 'キャンセル',
      onOk: () => {
        submit(undefined, { showMessage: false }).then();
        navigate(PATH_LOGIN, { replace: true });
      }
    });
  };

  const infoData = [
    {
      title: text.setting.title,
      description: text.setting.description,
      link: ''
    }
  ];

  const contentInfo = (
    <div className="infoPopup">
      <List
        itemLayout="horizontal"
        dataSource={infoData}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              title={
                item.link ? (
                  <a href="https://ant.design">{item.title}</a>
                ) : (
                  <span>{item.title}</span>
                )
              }
              description={item.description}
            />
          </List.Item>
        )}
      />
    </div>
  );

  const handleUsername = () => {
    if (data.role.id === RoleType.AD_MEDIA) {
      navigate(`${PATH_ACCOUNT_UPDATE}/${data.id}`);
    } else {
      // navigate(`${PATH_AGENCY_UPDATE}/${data.id}`);
    }
  };

  return (
    <div className="topMenu">
      <ul className="topRight">
        <li>
          <p className="dropdownBox">
            <span className="userName">
              ユーザ：
              {!data ? (
                ''
              ) : data?.role?.id === RoleType.ADMIN ? (
                <span>{data?.username}さん</span>
              ) : (
                <a onClick={() => handleUsername()}>{data?.username}さん</a>
              )}
            </span>
          </p>
        </li>
        <li>
          <Popover
            content={contentInfo}
            trigger="click"
            placement="topRight"
            arrowPointAtCenter
          >
            <Badge count={infoData.length}>
              <BellOutlined style={{ cursor: 'pointer' }} />
            </Badge>
          </Popover>
        </li>
        <li>
          <Button onClick={handleLogout}>
            <LoginOutlined />
            ログアウト
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default observer(Setting);
