import { useLayoutEffect } from 'react';
import { message } from 'antd';
import { ConfigService } from 'mobx-template';
import EventBus from '../core/event-bus';
import { EVENT_GO_TO_LOGIN_KEY } from '../constants';
import { text } from '../constants/text';
import { MediaSettingErrorRes } from '../constants/enum';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const useConfigSetUp = () => {
  const excludeMsg: string[] = [
    MediaSettingErrorRes.MEDIA,
    MediaSettingErrorRes.SENSOR
  ];
  useLayoutEffect(() => {
    ConfigService.setup({
      useSystemErrorMessage: true,
      systemErrorMessage: 'システムの例外は、管理者に連絡してください！',
      use401Message: true,
      message401:
        'ログインの有効期限が切れています。もう一度ログインしてください！',
      handleHttpResult: (resBody: any): any => {
        const { success, errorCode, errorMessage, payload, count } =
          resBody || {};
        return {
          success,
          errorCode,
          errorMessage,
          data: payload,
          total: count || 0
        };
      },
      handleHttpErrorResult: (resBody: any): any => {
        const { success, errorCode, errorMessage } = resBody || {};
        return { success, errorCode, errorMessage };
      },
      showErrorMessage: ({ message: errorMessage, errorCode, status }) => {
        if (status === 401) {
          message.warn(errorMessage);
        } else if (errorCode && +errorCode === 20008) {
          message.error(text.csv.auto.exportMsg.s3Info);
        } else if (errorCode && +errorCode === 20009) {
          message.error(text.csv.auto.exportMsg.s3bucket);
        } else {
          if (!excludeMsg.includes(errorMessage)) {
            message.error(errorMessage);
          }
        }
      },
      showSuccessMessage: (errorMessage: string) => {
        message.success(errorMessage);
      },
      handle401: () => {
        EventBus.emit(EVENT_GO_TO_LOGIN_KEY);
      }
    });
  });
};
