import {
  fetchDel,
  fetchGet,
  fetchPost,
  fetchPut
} from '../../../../utils/request';
import { makeAutoObservable } from 'mobx';
import React from 'react';
import {
  fromObjStore,
  fromPageListStore,
  fromSubmitStore
} from 'mobx-template';
import { MediaListModel, MediaModel } from './model/media.model';

export class MediaStore {
  static apiPrefix = '/media';

  constructor() {
    makeAutoObservable(this);
  }

  listStore = fromPageListStore<MediaListModel>((params) =>
    fetchPost(`${MediaStore.apiPrefix}/list`, params)
  );

  editStore = fromSubmitStore<MediaModel>((body: MediaModel) => {
    const fetch = body.id ? fetchPut : fetchPost;
    return fetch(`${MediaStore.apiPrefix}`, body);
  });

  detailStore = fromObjStore<MediaModel, { id: string }>(
    ({ id }) => fetchGet(`${MediaStore.apiPrefix}/${id}`),
    {
      successCallback: (data: MediaModel) => {
        this.detailStore.setData({ accountName: data.account?.username });
      }
    }
  );

  delStore = fromSubmitStore<{ id: string }>(({ id }) =>
    fetchDel(`${MediaStore.apiPrefix}/${id}`)
  );
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const MediaContext = React.createContext<MediaStore>(null!);
