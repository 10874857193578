import { makeAutoObservable } from 'mobx';
import React from 'react';
import { fromListStore, fromObjStore, fromPageListStore } from 'mobx-template';
import { CustomerInfo, DashboardModel } from './model/dashboard.model';
import { fetchGet, fetchPost } from '../../../utils/request';
import { MediaListModel } from '../../account/media/store/model/media.model';
import { MediaSelModel } from '../../user/setting/store/model/setting.model';

export class DashboardStore {
  static apiPrefix = '/dashboard';

  constructor() {
    makeAutoObservable(this);
  }

  customerInfoStore = fromObjStore<CustomerInfo>((params) =>
    fetchPost(`${DashboardStore.apiPrefix}/customerInfo`, params)
  );

  summaryStore = fromObjStore<
    DashboardModel,
    { date: string; mediaIds: number[] }
  >((params) => fetchPost(`${DashboardStore.apiPrefix}/summary`, params));

  mediaListStore = fromPageListStore<MediaListModel>((params) =>
    fetchPost('/media/list', params)
  );

  mediaSelListStore = fromListStore<MediaSelModel>(() =>
    fetchGet('/common/media/codes')
  );

  dataZoom = [
    {
      type: 'inside',
      minValueSpan: 1,
      start: 0,
      end: 100
    },
    {
      start: 0,
      end: 100,
      labelFormatter: () => {
        return '';
      }
    }
  ];
  dataZoom24 = [
    {
      type: 'inside',
      minValueSpan: 1,
      start: 0,
      end: 100
    },
    {
      start: 0,
      end: 100,
      labelFormatter: () => {
        return '';
      }
    }
  ];
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const DashboardContext = React.createContext<DashboardStore>(null!);
