import React from 'react';
import { observer } from 'mobx-react-lite';
import { MyKeepAlive } from '../../../core/keep-alive/MyKeepAlive';
import ReportList from './ReportList';
import { ReportContext, ReportStore } from '../store/report-store';

const Report = () => {
  return (
    <ReportContext.Provider value={new ReportStore()}>
      <MyKeepAlive onlyOne={true}>
        <ReportList />
      </MyKeepAlive>
    </ReportContext.Provider>
  );
};

export default observer(Report);
