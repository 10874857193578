export enum REPORT_USAGE {
  publishing = 1,
  simulation = 2
}

export enum REPORT_STATUS_ENUM {
  standBy,
  processing,
  success,
  measuring
}

export enum GENDER_TOTAL {
  male = 'totalMale',
  female = 'totalFemale'
}

export enum AGE_GROUP {
  age10 = '10',
  age20 = '20',
  age30 = '30',
  age40 = '40',
  age50 = '50',
  age60 = '60',
  age70 = '70'
}

export enum MOVING_STAYING {
  moving = 'moving',
  staying = 'staying'
}

export enum CONDITION_STATUS {
  allDay,
  custom,
  allMedia
}

export enum PAGE_PATH {
  report = 'report',
  dashboard = 'dashboard',
  csv = 'csv'
}
