import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import moment from 'moment';

const MyTime = () => {
  const [time, setTime] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format('YYYY/MM/DD HH:mm:ss'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <>{time}</>;
};

export default observer(MyTime);
