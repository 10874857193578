import { Breadcrumb } from 'antd';

type Props = {
  mainTitle: string;
  subTitle: string;
};

const BreadCrumb = (props: Props) => {
  const { mainTitle, subTitle } = props;
  return (
    <Breadcrumb>
      <Breadcrumb.Item>{mainTitle}</Breadcrumb.Item>
      <Breadcrumb.Item>{subTitle}</Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default BreadCrumb;
