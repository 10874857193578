import { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { CsvContext } from '../store';
import { useMyNavigate } from '../../../core/navigate';
import { OrderType } from '../../../model/order-type';
import MyTable from '../../../components/MyTable';
import BreadCrumb from '../../../components/Breadcrumb';
import { text } from '../../../constants/text';
import columnsData from './columnsData';
import { PATH_CSV_ADD } from '../../../router/router-path';

/* eslint-disable @typescript-eslint/no-explicit-any */
const CsvExportList = () => {
  const navigate = useMyNavigate();

  const { csvConfigList } = useContext(CsvContext);

  const sortHandle = (sorter: Record<string, any>) => {
    if (sorter.id) {
      return {
        orderType: sorter.id === 'ascend' ? OrderType.ASC : OrderType.DESC
      };
    }
    return {};
  };

  return (
    <>
      <BreadCrumb
        mainTitle={text.csv.breadcrumb.mainMenu}
        subTitle={text.breadCrumb.list.subMenu}
      />
      <div className="subMainArea">
        <MyTable
          listStore={csvConfigList}
          columns={columnsData()}
          rowKey={'id'}
          alive={true}
          search={false}
          sortHandle={sortHandle}
          toolBarRender={() => [
            <Button
              type="primary"
              key="primary"
              onClick={() => navigate(PATH_CSV_ADD)}
            >
              <PlusOutlined />
              {text.csv.list.action.add}
            </Button>
          ]}
        />
      </div>
    </>
  );
};

export default observer(CsvExportList);
