import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Breadcrumb,
  Button,
  Col,
  Collapse,
  Descriptions,
  Row,
  Select,
  Table,
  Space,
  InputNumber
} from 'antd';
import AdPeriod from './components/AdPeriod';
import ReportDate from './components/ReportDate';
import { PATH_REPORT } from '../../../router/router-path';
import { useRoleBack } from '../../../hooks/use-auth';
import { useNavigate, useParams } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons/lib';
import { ReportContext } from '../store/report-store';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import {
  CONDITION_REPORT,
  getReportStatus,
  headerPDFHeight,
  maxPriceLength,
  pdfSetting
} from '../../../utils/report-utils';
import Title from 'antd/lib/typography/Title';
import { commonText, formatDate, text, unit } from '../../../constants/text';
import {
  browserName,
  detectBrowser,
  isTheFutureDate
} from '../../../utils/common';
import { NumberUtils } from '../../../utils/number-utils';
import { adFeeLimit } from '../../../constants';
import { CONDITION_STATUS } from '../../../shared/enum';

const { Option } = Select;

const { Panel } = Collapse;
const { Column, ColumnGroup } = Table;

/* eslint-disable @typescript-eslint/no-explicit-any */
const ReportDetails = () => {
  // const columns: any = [
  //   {
  //     title: '開始時間(分秒)',
  //     dataIndex: 'startTime',
  //     key: 'startTime',
  //     align: 'center'
  //   },
  //   {
  //     title: '終了時間(分秒)',
  //     dataIndex: 'endTime',
  //     key: 'endTime',
  //     align: 'center'
  //   }
  // ];

  const navigate = useNavigate();
  const roleBack = useRoleBack();
  const params = useParams();
  const handleBack = () => {
    roleBack(() => {
      navigate(PATH_REPORT);
    });
  };

  let printArea: any;

  // const handlePDF = () => {
  //   window.open('/imgs/AVS指標について.pdf');
  // };

  const reportStore = useContext(ReportContext);
  const {
    detailStore: { data: reportDef, loadData: loadReportDefData },
    dateStore: { list: dateList, loadData: loadDateList },
    viewDateStore: { loadData: loadViewDateData }
  } = reportStore;

  useEffect(() => {
    if (params?.id) {
      loadReportDefData({ id: params?.id }).then();
      loadDateList({ id: params.id }).then(({ data }) => {
        if (data) {
          loadViewDateData({ id: params.id, date: data[0] }).then();
          reportStore.updateDateReport(data[0]);
        }
      });
    }
  }, [loadReportDefData, params?.id]);

  useEffect(() => {
    if (reportDef) {
      setAdFee(reportDef?.adFee || 0);
    }
  }, [reportDef]);

  const getDateTitle = () => {
    if (dateList?.length) {
      //return `日（${moment(dateList[0]).format("M月D日")}）`;
      return '日別表示';
    }
    return '';
  };

  type TYPE = { type: number; name: string; text: string };

  const getUsageType = (usageType: number): string => {
    const usageTypeList: any = [
      {
        type: 1,
        name: 'publishing',
        text: '出稿レポート'
      },
      {
        type: 2,
        name: 'simulation',
        text: 'シミュレーション'
      }
    ];
    return (
      usageTypeList.find((item: TYPE) => item.type === usageType)?.text || ''
    );
  };

  const getExportConditionsAllDay = () => {
    if (reportDef?.exportConditions.length) {
      const [first] = reportDef?.exportConditions;
      return first.timeSpecification === 1
        ? `${first.adStartTime}~${first.adEndTime}`
        : first.timeSpecification === 0
        ? '終日'
        : '媒体運営時間全て';
    }
    return '';
  };
  const getExportConditionsAllDay2 = () => {
    if (reportDef?.exportConditions.length >= 2) {
      const [, second] = reportDef?.exportConditions;
      return second.timeSpecification === 1
        ? `${second.adStartTime}~${second.adEndTime}`
        : second.timeSpecification === 0
        ? '終日'
        : '媒体運営時間全て';
    }
    return '';
  };
  const getExportConditionsAllDay3 = () => {
    if (reportDef?.exportConditions.length >= 3) {
      const [, , third] = reportDef?.exportConditions;
      return third.timeSpecification === 1
        ? `${third.adStartTime}~${third.adEndTime}`
        : third.timeSpecification === 0
        ? '終日'
        : '媒体運営時間全て';
    }
    return '';
  };

  const getExportConditionsFirst = () => {
    if (reportDef?.exportConditions.length) {
      return reportDef?.exportConditions[0];
    }
    return {};
  };
  const getExportConditionsSec = () => {
    if (reportDef?.exportConditions.length >= 2) {
      return reportDef?.exportConditions[1];
    }
    return {};
  };
  const getExportConditionsThr = () => {
    if (reportDef?.exportConditions.length === 3) {
      return reportDef?.exportConditions[2];
    }
    return {};
  };

  const selectChangeByDate = (value: number) => {
    if (params?.id) {
      loadViewDateData({ id: params.id, date: dateList[value] }).then();
      reportStore.updateDateReport(dateList[value]);
    }
  };

  const group = (sourceFunc: any, count1: number, count2: number) => {
    const source = sourceFunc();
    let index = 0;
    const newArray: any[] = [];
    if (source) {
      const array = Array.from(source);
      while (index < array.length) {
        const size = index === 0 ? count1 : count2;
        newArray.push(array.slice(index, (index += size)));
      }
    }
    return newArray;
  };

  const dailRunTimeOne = () => {
    if (reportDef?.exportConditions.length) {
      // let _list: any[] = [];
      // reportDef?.exportConditions.map((value: any, index: number) => {
      //   if (value?.hourlyAdTimes.length) {
      //     _list.push(
      //       value?.hourlyAdTimes.map((_value: any, _index: number) => {
      //         return {
      //           key: `${index}000${_index}`,
      //           number: `${index}000${_index}`,
      //           startTime: _value.startTime,
      //           endTime: _value.endTime,
      //         };
      //       })
      //     );
      //   }
      // });
      // return [].concat.apply([], _list);
      if (reportDef?.exportConditions[0]?.timeSpecification !== 2) {
        if (reportDef?.exportConditions[0]?.hourlyAdTimes.length) {
          return reportDef?.exportConditions[0]?.hourlyAdTimes.map(
            (value: any, index: number) => {
              return {
                key: `000${index}`,
                number: `000${index}`,
                startTime: value.startTime,
                endTime: value.endTime
              };
            }
          );
        }
      }
    }
  };

  const dailRunTimeTwo = () => {
    if (reportDef?.exportConditions.length > 1) {
      if (reportDef?.exportConditions[1]?.timeSpecification !== 2) {
        if (reportDef?.exportConditions[1]?.hourlyAdTimes.length) {
          return reportDef?.exportConditions[1]?.hourlyAdTimes.map(
            (value: any, index: number) => {
              return {
                key: `000${index}`,
                number: `000${index}`,
                startTime: value.startTime,
                endTime: value.endTime
              };
            }
          );
        }
      }
    }
  };

  const dailRunTimeThree = () => {
    if (reportDef?.exportConditions.length > 2) {
      if (reportDef?.exportConditions[2]?.timeSpecification !== 2) {
        if (reportDef?.exportConditions[2]?.hourlyAdTimes.length) {
          return reportDef?.exportConditions[2]?.hourlyAdTimes.map(
            (value: any, index: number) => {
              return {
                key: `000${index}`,
                number: `000${index}`,
                startTime: value.startTime,
                endTime: value.endTime
              };
            }
          );
        }
      }
    }
  };

  const getMaxConditionPageCount = () => {
    const firstConditionPageCount = group(dailRunTimeOne, 22, 28).length;
    const secondConditionPageCount = group(dailRunTimeTwo, 22, 28).length;
    const thirdConditionPageCount = group(dailRunTimeThree, 22, 28).length;
    const maxConditionPageCount = Math.max(
      firstConditionPageCount,
      secondConditionPageCount,
      thirdConditionPageCount,
      1
    );
    return maxConditionPageCount;
  };

  const timeSpecification = (key: number) => {
    if (
      reportDef?.exportConditions.length &&
      reportDef?.exportConditions.length >= key + 1
    ) {
      return {
        adEndTime: reportDef?.exportConditions[key]?.adEndTime,
        adStartTime: reportDef?.exportConditions[key]?.adStartTime,
        timeSpecification: reportDef?.exportConditions[key]?.timeSpecification
      };
    }
  };

  const [adFee, setAdFee] = useState(reportDef?.adFee);

  const getTotalViewing = (dataArray: any) => {
    const allMediaCondition = dataArray?.find(
      (data: CONDITION_REPORT) =>
        data.timeSpecification === CONDITION_STATUS.allMedia
    );
    return allMediaCondition
      ? allMediaCondition.adCountOfPeriod
      : dataArray?.reduce(
          (firstPeriod: any, lastPeriod: any) =>
            firstPeriod + lastPeriod.adCountOfPeriod,
          0
        );
  };

  return (
    <>
      <Breadcrumb className="noPrint">
        <Breadcrumb.Item>レポート</Breadcrumb.Item>
        <Breadcrumb.Item>レポート詳細</Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea report-detail">
        <div className="mainCard reportCard dashboardCard">
          <div className="cardHeader noPrint">
            <Button onClick={() => handleBack()}>
              <RollbackOutlined />
              戻る
            </Button>
            {/* <Button onClick={() => handlePDF()}>
              <FilePdfOutlined />
              AVSとは？
            </Button> */}

            <Descriptions.Item
              label={text.report.adFeeTitle}
              span={3}
              className="report-status"
            >
              <span className="report-status">
                {reportDef?.status >= 0
                  ? getReportStatus(reportDef?.status)?.text
                  : ''}
              </span>
            </Descriptions.Item>

            <ReactToPrint
              content={() => printArea}
              trigger={() => (
                <Button
                  type="primary"
                  key="primary"
                  disabled={reportDef?.status !== 2}
                  onClick={() => window.print()}
                >
                  <Space>
                    PDF
                    <img src="/icon_down.png" height={16} width={16} />
                  </Space>
                </Button>
              )}
            />
          </div>
          <div
            ref={(el) => (printArea = el)}
            className={`cardBody ${
              detectBrowser() === browserName.SAFARI ? 'safari' : ''
            }`}
          >
            {new Array(getMaxConditionPageCount() + 2).fill(1).map((v, i) => (
              <div
                className="print_header"
                style={{
                  top: headerPDFHeight + i * pdfSetting.heightPerPage
                }}
                key={i}
              >
                <img src="/logo_header.jpg" height="30px" />
              </div>
            ))}
            {/*<div className="print-tail">*/}
            {/*  <p className={"page-number"}></p>*/}
            {/*</div>*/}
            <Title className="print-title" level={2}>
              {getUsageType(reportDef?.usageType)}
            </Title>
            <Collapse
              defaultActiveKey={['1', '2', '3', '4', '5', '6']}
              expandIconPosition="right"
              className="first-collapse"
            >
              {/* <Title className="report-title d-none" level={2}>
                {getUsageType(reportDef?.usageType)}
              </Title> */}
              <Panel
                header="（広告）基本情報"
                key="1"
                className={'print_header_second'}
              >
                <Row gutter={[16, 16]} justify="space-between" align="bottom">
                  <Col span={24}>
                    <Descriptions
                      bordered
                      size={'middle'}
                      labelStyle={{ width: '220px' }}
                    >
                      <Descriptions.Item
                        label={text.report.detail.label.owner}
                        span={3}
                      >
                        {reportDef?.owner}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.detail.label.name}
                        span={3}
                      >
                        {reportDef?.name}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.detail.label.dateTime}
                        span={3}
                      >
                        {` ${moment(reportDef?.adStartDate).format(
                          formatDate.yearMonthDay
                        )}
                        〜
                        ${moment(reportDef?.adEndDate).format(
                          formatDate.yearMonthDay
                        )}
                        （
                        ${
                          moment(reportDef?.adEndDate).diff(
                            moment(reportDef?.adStartDate),
                            'days'
                          ) + 1
                        }
                        ${commonText.ja.day}）`}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.adFeeTitle}
                        span={3}
                      >
                        <Space className="addFeeField">
                          <InputNumber
                            className="addFee"
                            min={0}
                            formatter={(value) =>
                              `${NumberUtils.toThousands(value)}`
                            }
                            onChange={(value) => setAdFee(value || 0)}
                            value={adFee}
                            onKeyPress={(e: any) => {
                              if (
                                (e.target.value
                                  .toString()
                                  .split('')
                                  .filter((val: string) => val !== ',')
                                  .length >= adFeeLimit ||
                                  //input numeric and delete, backspace only
                                  e.which < 48 ||
                                  e.which > 57) &&
                                e.which !== 8
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                              const value = e.target.value;
                              const newValue = value
                                .split(',')
                                .join('')
                                .split('-')
                                .join('')
                                .split('')
                                .slice(0, maxPriceLength)
                                .join('');
                              setAdFee(newValue);
                            }}
                          />
                          <span className="prefix">{unit.yen}</span>
                        </Space>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Panel>
              <Panel header="（媒体）基本情報" key="2">
                <Row gutter={[16, 16]} justify="space-between" align="bottom">
                  <Col span={24}>
                    <Descriptions
                      bordered
                      size={'middle'}
                      labelStyle={{ width: '220px' }}
                    >
                      <Descriptions.Item label="名称" span={3}>
                        {reportDef?.medias
                          .map((media: { name: string }) => media.name)
                          .join(',')}
                      </Descriptions.Item>
                      <Descriptions.Item label="所在地" span={3}>
                        {reportDef?.medias
                          .map((media: { location: string }) => media.location)
                          .join(',')}
                      </Descriptions.Item>
                      <Descriptions.Item label="運営時間" span={3}>
                        {reportDef?.medias
                          .map(
                            (media: {
                              opStartTime: string;
                              opEndTime: string;
                            }) => media.opStartTime + '～' + media.opEndTime
                          )
                          .join(',')}
                      </Descriptions.Item>
                      <Descriptions.Item label="音声" span={3}>
                        {reportDef?.medias
                          .map(
                            (media: {
                              hasAudio: boolean;
                              audioComment: string;
                            }) =>
                              (media.hasAudio ? '音声あり' : '音声なし') +
                              (media.audioComment !== null
                                ? '　※' + media.audioComment
                                : '')
                          )
                          .join(',')}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Panel>
              <Panel
                className={`fillBlank ${
                  detectBrowser() === browserName.SAFARI ? 'safari' : ''
                }`}
                header="レポート出力条件"
                key="3"
              >
                <Row gutter={[16, 16]} justify="space-between" align="bottom">
                  <Col span={24}>
                    <Descriptions
                      title={'出力条件1'}
                      bordered
                      size={'small'}
                      labelStyle={{ width: '220px' }}
                    >
                      <Descriptions.Item label="素材名" span={3}>
                        {getExportConditionsFirst()?.timeSpecification !==
                          2 && (
                          <>{getExportConditionsFirst()?.materialName || ''}</>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="広告放映時間" span={3}>
                        {getExportConditionsAllDay() || ''}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.duration.title}
                        span={3}
                      >
                        {/* {getExportConditionsFirst()?.timeSpecification !==
                          2 && ( */}
                        <>
                          {getExportConditionsFirst()?.materialScale
                            ? getExportConditionsFirst()?.materialScale +
                              unit.seconds
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.byHour}
                        span={3}
                      >
                        {/* {getExportConditionsFirst()?.timeSpecification !==
                          2 && ( */}
                        <>
                          {getExportConditionsFirst()?.adCountPerHour
                            ? getExportConditionsFirst()?.adCountPerHour +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.byDay}
                        span={3}
                      >
                        {/* {getExportConditionsFirst()?.timeSpecification !==
                          2 && ( */}
                        <>
                          {getExportConditionsFirst()?.adCountPerDay
                            ? getExportConditionsFirst()?.adCountPerDay +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.allTime}
                        span={3}
                      >
                        {/* {getExportConditionsFirst()?.timeSpecification !==
                          2 && ( */}
                        <>
                          {getExportConditionsFirst()?.adCountOfPeriod
                            ? getExportConditionsFirst()?.adCountOfPeriod +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
                <Row
                  gutter={[16, 16]}
                  justify="space-between"
                  align="bottom"
                  className="mt-2"
                >
                  <Col span={24}>
                    <Descriptions
                      title={'出力条件2'}
                      bordered
                      size={'small'}
                      labelStyle={{ width: '220px' }}
                    >
                      <Descriptions.Item label="素材名" span={3}>
                        {getExportConditionsSec()?.timeSpecification !== 2 && (
                          <>{getExportConditionsSec()?.materialName || ''}</>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="広告放映時間" span={3}>
                        {getExportConditionsAllDay2() || ''}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.duration.title}
                        span={3}
                      >
                        {/* {getExportConditionsSec()?.timeSpecification !== 2 && ( */}
                        <>
                          {getExportConditionsSec()?.materialScale
                            ? getExportConditionsSec()?.materialScale +
                              unit.seconds
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.byHour}
                        span={3}
                      >
                        {/* {getExportConditionsSec()?.timeSpecification !== 2 && ( */}
                        <>
                          {getExportConditionsSec()?.adCountPerHour
                            ? getExportConditionsSec()?.adCountPerHour +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.byDay}
                        span={3}
                      >
                        {/* {getExportConditionsSec()?.timeSpecification !== 2 && ( */}
                        <>
                          {getExportConditionsSec()?.adCountPerDay
                            ? getExportConditionsSec()?.adCountPerDay +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.allTime}
                        span={3}
                      >
                        {/* {getExportConditionsSec()?.timeSpecification !== 2 && ( */}
                        <>
                          {getExportConditionsSec()?.adCountOfPeriod
                            ? getExportConditionsSec()?.adCountOfPeriod +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>

                <Row
                  id="panel-three"
                  gutter={[16, 16]}
                  justify="space-between"
                  align="bottom"
                  className="mt-2"
                >
                  <Col span={24}>
                    <Descriptions
                      title={'出力条件3'}
                      bordered
                      size={'small'}
                      labelStyle={{ width: '220px' }}
                    >
                      <Descriptions.Item label="素材名" span={3}>
                        {getExportConditionsThr()?.timeSpecification !== 2 && (
                          <>{getExportConditionsThr()?.materialName || ''}</>
                        )}
                      </Descriptions.Item>
                      <Descriptions.Item label="広告放映時間" span={3}>
                        {getExportConditionsAllDay3() || ''}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.duration.title}
                        span={3}
                      >
                        {/* {getExportConditionsThr()?.timeSpecification !== 2 && ( */}
                        <>
                          {getExportConditionsThr()?.materialScale
                            ? getExportConditionsThr()?.materialScale +
                              unit.seconds
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.byHour}
                        span={3}
                      >
                        {/* {getExportConditionsThr()?.timeSpecification !== 2 && ( */}
                        <>
                          {getExportConditionsThr()?.adCountPerHour
                            ? getExportConditionsThr()?.adCountPerHour +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.byDay}
                        span={3}
                      >
                        {/* {getExportConditionsThr()?.timeSpecification !== 2 && ( */}
                        <>
                          {getExportConditionsThr()?.adCountPerDay
                            ? getExportConditionsThr()?.adCountPerDay +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={text.report.broadcasts.allTime}
                        span={3}
                      >
                        {/* {getExportConditionsThr()?.timeSpecification !== 2 && ( */}
                        <>
                          {getExportConditionsThr()?.adCountOfPeriod
                            ? getExportConditionsThr()?.adCountOfPeriod +
                              unit.times
                            : ''}
                        </>
                        {/* )} */}
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Panel>
              <div
                className={`print-tail ${
                  detectBrowser() === browserName.SAFARI
                    ? 'top-page-safari-1'
                    : 'top-page-1'
                }`}
              >
                <p className="page-number">
                  1/{getMaxConditionPageCount() + 2}
                </p>
              </div>
              <Panel
                header={`${text.report.period.title} (${moment(
                  reportDef?.adStartDate
                ).format(formatDate.yearMonthDay)} 〜 ${moment(
                  reportDef?.adEndDate
                ).format(formatDate.yearMonthDay)}）`}
                key="4"
                className={`print_header_second adPeriod ${
                  detectBrowser() === browserName.SAFARI ? 'safari' : ''
                }`}
              >
                {reportDef && (
                  <AdPeriod
                    viewCost={adFee >= 0 ? adFee : reportDef?.adFee}
                    totalView={getTotalViewing(reportDef?.exportConditions)}
                    printTotalPage={getMaxConditionPageCount() + 2}
                  />
                )}
              </Panel>
              <Panel className="noPrint" header={getDateTitle()} key="5">
                <div>
                  <Select
                    style={{ width: '200px' }}
                    defaultValue={0}
                    onChange={selectChangeByDate}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {dateList.map((value, index) => (
                      <Option
                        value={index}
                        disabled={isTheFutureDate(value)}
                        key={index}
                      >{`${moment(value).format(formatDate.monthDay)}`}</Option>
                    ))}
                  </Select>
                </div>
                <ReportDate />
              </Panel>
              {detectBrowser() !== browserName.SAFARI && (
                <div className="page-break"></div>
              )}
              <div className="print-top"></div>
              <Panel header="出力条件（放映タイミング）詳細" key="6">
                <Row gutter={[16, 16]}>
                  <Col span={8}>
                    {group(dailRunTimeOne, 22, 28).length === 0 && (
                      <>
                        <Table
                          bordered={true}
                          title={() => {
                            return '【出力条件1】';
                          }}
                          // rowKey="id"
                          // columns={columns}
                          dataSource={dailRunTimeOne()}
                          className="exportConditionTable"
                          size="middle"
                          pagination={false}
                        >
                          <ColumnGroup
                            title={
                              timeSpecification(0)?.timeSpecification === 1
                                ? '放映時間: ' +
                                  timeSpecification(0)?.adStartTime +
                                  '-' +
                                  timeSpecification(0)?.adEndTime
                                : timeSpecification(0)?.timeSpecification === 0
                                ? '放映時間: 終日'
                                : timeSpecification(0)?.timeSpecification === 2
                                ? '放映時間: 媒体運営時間全て'
                                : '放映時間: '
                            }
                          >
                            <Column
                              title="開始時間(分秒)"
                              dataIndex="startTime"
                              key="startTime"
                            />
                            <Column
                              title="終了時間(分秒)"
                              dataIndex="endTime"
                              key="endTime"
                            />
                          </ColumnGroup>
                        </Table>
                      </>
                    )}
                    <>
                      {group(dailRunTimeOne, 22, 28).map((data, i) => {
                        return (
                          <div
                            key={i}
                            className={
                              i < group(dailRunTimeOne, 22, 28).length - 1
                                ? 'page-break'
                                : ''
                            }
                          >
                            {i > 0 && <div className="print-top-sm"></div>}
                            <Table
                              bordered={true}
                              title={() => {
                                return '【出力条件1】';
                              }}
                              // rowKey="id"
                              // columns={columns}
                              dataSource={data}
                              className="exportConditionTable"
                              size="middle"
                              pagination={false}
                            >
                              <ColumnGroup
                                title={
                                  timeSpecification(0)?.timeSpecification === 1
                                    ? '放映時間: ' +
                                      timeSpecification(0)?.adStartTime +
                                      '-' +
                                      timeSpecification(0)?.adEndTime
                                    : timeSpecification(0)
                                        ?.timeSpecification === 0
                                    ? '放映時間: 終日'
                                    : timeSpecification(0)
                                        ?.timeSpecification === 2
                                    ? '放映時間: 媒体運営時間全て'
                                    : '放映時間: '
                                }
                              >
                                <Column
                                  title="開始時間(分秒)"
                                  dataIndex="startTime"
                                  key="startTime"
                                />
                                <Column
                                  title="終了時間(分秒)"
                                  dataIndex="endTime"
                                  key="endTime"
                                />
                              </ColumnGroup>
                            </Table>
                          </div>
                        );
                      })}
                    </>
                  </Col>
                  <Col span={8}>
                    {group(dailRunTimeTwo, 22, 28).length === 0 && (
                      <>
                        <Table
                          bordered={true}
                          title={() => {
                            return '【出力条件2】';
                          }}
                          // rowKey="id"
                          // columns={columns}
                          dataSource={dailRunTimeTwo()}
                          className="exportConditionTable"
                          size="middle"
                          pagination={false}
                        >
                          <ColumnGroup
                            title={
                              timeSpecification(1)?.timeSpecification === 1
                                ? '放映時間: ' +
                                  timeSpecification(1)?.adStartTime +
                                  '-' +
                                  timeSpecification(1)?.adEndTime
                                : timeSpecification(1)?.timeSpecification === 0
                                ? '放映時間: 終日'
                                : timeSpecification(1)?.timeSpecification === 2
                                ? '放映時間: 媒体運営時間全て'
                                : '放映時間: '
                            }
                          >
                            <Column
                              title="開始時間(分秒)"
                              dataIndex="startTime"
                              key="startTime"
                            />
                            <Column
                              title="終了時間(分秒)"
                              dataIndex="endTime"
                              key="endTime"
                            />
                          </ColumnGroup>
                        </Table>
                      </>
                    )}
                    <>
                      {group(dailRunTimeTwo, 22, 28).map((data, i) => {
                        return (
                          <div
                            key={i}
                            className={
                              i < group(dailRunTimeTwo, 22, 28).length - 1
                                ? 'page-break'
                                : ''
                            }
                          >
                            {i > 0 && <div className="print-top-sm"></div>}
                            <Table
                              bordered={true}
                              title={() => {
                                return '【出力条件2】';
                              }}
                              // rowKey="id"
                              // columns={columns}
                              dataSource={data}
                              className="exportConditionTable"
                              size="middle"
                              pagination={false}
                            >
                              <ColumnGroup
                                title={
                                  timeSpecification(1)?.timeSpecification === 1
                                    ? '放映時間: ' +
                                      timeSpecification(1)?.adStartTime +
                                      '-' +
                                      timeSpecification(1)?.adEndTime
                                    : timeSpecification(1)
                                        ?.timeSpecification === 0
                                    ? '放映時間: 終日'
                                    : timeSpecification(1)
                                        ?.timeSpecification === 2
                                    ? '放映時間: 媒体運営時間全て'
                                    : '放映時間: '
                                }
                              >
                                <Column
                                  title="開始時間(分秒)"
                                  dataIndex="startTime"
                                  key="startTime"
                                />
                                <Column
                                  title="終了時間(分秒)"
                                  dataIndex="endTime"
                                  key="endTime"
                                />
                              </ColumnGroup>
                            </Table>
                          </div>
                        );
                      })}
                    </>
                  </Col>
                  <Col span={8}>
                    {group(dailRunTimeThree, 22, 28).length === 0 && (
                      <>
                        <Table
                          bordered={true}
                          title={() => {
                            return '【出力条件3】';
                          }}
                          // rowKey="id"
                          // columns={columns}
                          dataSource={dailRunTimeThree()}
                          className="exportConditionTable"
                          size="middle"
                          pagination={false}
                        >
                          <ColumnGroup
                            title={
                              timeSpecification(2)?.timeSpecification === 1
                                ? '放映時間: ' +
                                  timeSpecification(2)?.adStartTime +
                                  '-' +
                                  timeSpecification(2)?.adEndTime
                                : timeSpecification(2)?.timeSpecification === 0
                                ? '放映時間: 終日'
                                : timeSpecification(2)?.timeSpecification === 2
                                ? '放映時間: 媒体運営時間全て'
                                : '放映時間: '
                            }
                          >
                            <Column
                              title="開始時間(分秒)"
                              dataIndex="startTime"
                              key="startTime"
                            />
                            <Column
                              title="終了時間(分秒)"
                              dataIndex="endTime"
                              key="endTime"
                            />
                          </ColumnGroup>
                        </Table>
                      </>
                    )}
                    <>
                      {group(dailRunTimeThree, 22, 28).map((data, i) => {
                        return (
                          <div
                            key={i}
                            className={
                              i < group(dailRunTimeThree, 22, 28).length - 1
                                ? 'page-break'
                                : ''
                            }
                          >
                            {i > 0 && <div className="print-top-sm"></div>}
                            <Table
                              bordered={true}
                              title={() => {
                                return '【出力条件3】';
                              }}
                              // rowKey="id"
                              // columns={columns}
                              dataSource={data}
                              className="exportConditionTable"
                              size="middle"
                              pagination={false}
                            >
                              <ColumnGroup
                                title={
                                  timeSpecification(2)?.timeSpecification === 1
                                    ? '放映時間: ' +
                                      timeSpecification(2)?.adStartTime +
                                      '-' +
                                      timeSpecification(2)?.adEndTime
                                    : timeSpecification(2)
                                        ?.timeSpecification === 0
                                    ? '放映時間: 終日'
                                    : timeSpecification(2)
                                        ?.timeSpecification === 2
                                    ? '放映時間: 媒体運営時間全て'
                                    : '放映時間: '
                                }
                              >
                                <Column
                                  title="開始時間(分秒)"
                                  dataIndex="startTime"
                                  key="startTime"
                                />
                                <Column
                                  title="終了時間(分秒)"
                                  dataIndex="endTime"
                                  key="endTime"
                                />
                              </ColumnGroup>
                            </Table>
                          </div>
                        );
                      })}
                    </>
                  </Col>
                </Row>
                {new Array(getMaxConditionPageCount()).fill(1).map((v, i) => (
                  <div
                    className="print-tail"
                    style={{
                      top:
                        pdfSetting.heightThreePage +
                        i * pdfSetting.heightPerPage
                    }}
                    key={i}
                  >
                    <p className="page-number">
                      {i + 3}/{getMaxConditionPageCount() + 2}
                    </p>
                  </div>
                ))}
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ReportDetails);
