import {
  fetchDel,
  fetchGet,
  fetchPost,
  fetchPut
} from '../../../../utils/request';
import { makeAutoObservable } from 'mobx';
import { AccountListModel, AccountModel } from './model/account.model';
import React from 'react';
import {
  fromObjStore,
  fromPageListStore,
  fromSubmitStore
} from 'mobx-template';
import { OrderType } from '../../../../model/order-type';

export class AccountStore {
  static apiPrefix = '/account';

  constructor() {
    makeAutoObservable(this);
  }

  listStore = fromPageListStore<AccountListModel, { orderType: OrderType }>(
    (params) => fetchPost(`${AccountStore.apiPrefix}/list`, params)
  );

  editStore = fromSubmitStore<AccountModel>((body: AccountModel) => {
    const fetch = body.id ? fetchPut : fetchPost;
    return fetch(`${AccountStore.apiPrefix}`, body);
  });

  detailStore = fromObjStore<AccountModel, { id: string }>(
    ({ id }) => fetchGet(`${AccountStore.apiPrefix}/${id}`),
    {
      successCallback: (data: AccountModel) => {
        this.detailStore.setData({ pswAgain: data?.password });
      }
    }
  );

  delStore = fromSubmitStore<{ id: string }>(({ id }) =>
    fetchDel(`${AccountStore.apiPrefix}/${id}`)
  );
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AccountContext = React.createContext<AccountStore>(null!);
