import React from 'react';
import { observer } from 'mobx-react-lite';
import { SettingContext, SettingStore } from '../store/setting-store';
import { MyKeepAlive } from '../../../../core/keep-alive/MyKeepAlive';
import SettingList from './SettingList';

const Setting = () => {
  return (
    <SettingContext.Provider value={new SettingStore()}>
      <MyKeepAlive onlyOne={true}>
        <SettingList />
      </MyKeepAlive>
    </SettingContext.Provider>
  );
};

export default observer(Setting);
