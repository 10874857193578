import { ageGroup } from '../constants';
import { commonText, text, unit } from '../constants/text';
import { AGE_GROUP, GENDER_TOTAL, MOVING_STAYING } from '../shared/enum';
import { PeopleInfo } from '../shared/services/chart';
import { roundedNumber } from './common';
import { NumberUtils } from './number-utils';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const ageChart = (dataProps: any, params: any) => {
  const { title, data } = dataProps;
  return {
    ...params,
    title: [
      {
        text: title,
        left: 'center'
      }
    ],
    tooltip: {
      trigger: 'item',
      formatter: (param: any) => {
        return `
      <span>
        ${param.marker} 
        ${param.name} &nbsp;
        <span class="value-tooltip-custom">${NumberUtils.toThousands(
          param.value
        )}${unit.people}</span> &nbsp;
        ${roundedNumber(param.percent)}%
      </span>`;
      }
    },
    legend: {
      icon: 'circle',
      bottom: 0,
      width: '100%',
      itemWidth: 14,
      padding: [0, 2],
      formatter: (name: string) => {
        const legend = data?.filter((item: any) => item.name === name)[0];
        return [
          `{name|${legend?.name}}`,
          `{value|${NumberUtils.toThousands(legend?.value)}${unit.people}}`
        ].join('  ');
      },
      textStyle: {
        rich: {
          name: {
            fontSize: 12,
            fontWeight: 900
          },
          value: {
            fontSize: 12
          }
        }
      }
    },
    series: [
      {
        name: title,
        type: 'pie',
        radius: '50%',
        data: data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        label: {
          padding: [5, 10],
          formatter: function (data: any) {
            return [
              `{name|${data.name}: }\n`,
              `{percent|${roundedNumber(data.percent)}%}`
            ].join('');
          },
          overflow: 'none',
          textStyle: {
            rich: {
              percent: {
                fontSize: 11
              }
            }
          },
          labelLine: {
            show: false //隐藏标示线
          }
        },
        // labelLayout: {
        //   draggable: true
        // },
        center: ['50%', '38%']
      }
    ]
  };
};

const getPeopleData = (peopleData: PeopleInfo[], typeName: string) => {
  return (
    peopleData?.find((people: PeopleInfo) => people.name === typeName)?.value ||
    0
  );
};

export const getGenderProportion = (genderData: PeopleInfo[]) => {
  return [
    {
      name: commonText.gender.male,
      value: getPeopleData(genderData, GENDER_TOTAL.male)
    },
    {
      name: commonText.gender.female,
      value: getPeopleData(genderData, GENDER_TOTAL.female)
    }
  ];
};

export const getAgeProportion = (ageGroupData: PeopleInfo[]) => {
  return [
    {
      name: ageGroup.group1,
      value: getPeopleData(ageGroupData, AGE_GROUP.age10)
    },
    {
      name: ageGroup.group2,
      value: getPeopleData(ageGroupData, AGE_GROUP.age20)
    },
    {
      name: ageGroup.group3,
      value: getPeopleData(ageGroupData, AGE_GROUP.age30)
    },
    {
      name: ageGroup.group4,
      value: getPeopleData(ageGroupData, AGE_GROUP.age40)
    },
    {
      name: ageGroup.group5,
      value: getPeopleData(ageGroupData, AGE_GROUP.age50)
    },
    {
      name: ageGroup.group6,
      value: getPeopleData(ageGroupData, AGE_GROUP.age60)
    },
    {
      name: ageGroup.group7,
      value: getPeopleData(ageGroupData, AGE_GROUP.age70)
    }
  ];
};

export const getMovingAndStayingProportion = (
  movingStayingData: PeopleInfo[]
) => {
  return [
    {
      name: text.chart.movingStaying.moving,
      value: getPeopleData(movingStayingData, MOVING_STAYING.moving)
    },
    {
      name: text.chart.movingStaying.staying,
      value: getPeopleData(movingStayingData, MOVING_STAYING.staying)
    }
  ];
};

// line chart, split axis to 4 section
export const sectionSplit = 4;
export const labelXAxisArea = [0, 5, 10, 15];

export const getDataZoom = (
  dataZoom: any,
  DataZoomStart: number,
  DataZoomEnd: number
) => {
  dataZoom[0] = {
    type: 'inside',
    minValueSpan: 1,
    start: DataZoomStart,
    end: DataZoomEnd
  };
  dataZoom[1] = {
    minValueSpan: 1,
    start: DataZoomStart,
    end: DataZoomEnd,
    labelFormatter: () => {
      return '';
    }
  };
  return dataZoom;
};

export const sizeChart = {
  default: '350px',
  custom: '330px',
  print: '310px',
  width: '250px'
};
