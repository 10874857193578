import moment from 'moment';
import { DATE } from '../constants';
import { text } from '../constants/text';
import { REPORT_STATUS_ENUM } from '../shared/enum';
import { getFilename } from './string';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const browserName = {
  SAFARI: 'safari',
  CHROME: 'chrome'
};

export const detectBrowser = () => {
  const userAgent = navigator.userAgent;
  if (userAgent.match(/chrome|chromium|crios/i)) {
    return 'chrome';
  }
  if (userAgent.match(/safari/i)) {
    return 'safari';
  }
};

export const isTheFutureDate = (date: string) => {
  const currentDate = moment().format(DATE);
  return moment(date).isAfter(currentDate);
};

export const isToday = (date: string) => {
  const currentDate = moment().format(DATE);
  return moment(date).isSame(currentDate);
};

export const getMaxValue = (array: number[]) => {
  return Math.max(...array);
};

export const getTextReportStatus = (datePicked: string, status: number) => {
  return isToday(datePicked) && status === REPORT_STATUS_ENUM.measuring
    ? text.report.status.measuring
    : isToday(datePicked) && status === REPORT_STATUS_ENUM.processing
    ? text.report.status.processing
    : '';
};

//rounded number default is 1 decimal
export const roundedNumber = (num: number, decimal = 1) => {
  const numberDecimal = Math.pow(10, decimal);
  return (
    Math.round((num + Number.EPSILON) * numberDecimal) / numberDecimal
  ).toFixed(decimal);
};

export const validateString =
  (pattern: RegExp, emptyMsg: string, invalidMsg: string) =>
  (_: any, value: string) => {
    if (!value.trim()) {
      return Promise.reject(new Error(emptyMsg));
    } else if (!pattern.test(value)) {
      return Promise.reject(new Error(invalidMsg));
    } else {
      return Promise.resolve();
    }
  };

export const downloadCSV = (res: any) => {
  const csvDownload = document.createElement('a');
  const blob = new Blob([res.data], {
    type: 'application/octet-stream'
  });

  const csvFileName: string = getFilename(res.headers['content-disposition']);
  csvDownload.href = window.URL.createObjectURL(blob);
  csvDownload.download = csvFileName;
  document.body.appendChild(csvDownload);
  csvDownload.click();
  document.body.removeChild(csvDownload);
};
