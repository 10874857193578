import {
  fetchDel,
  fetchGet,
  fetchPost,
  fetchPut
} from '../../../utils/request';
import { makeAutoObservable } from 'mobx';
import {
  ReportListModel,
  ReportModel,
  ReportWholeModel,
  WeekModel
} from './model/report.model';
import React from 'react';
import {
  fromListStore,
  fromObjStore,
  fromPageListStore,
  fromSubmitStore
} from 'mobx-template';
import { OrderType } from '../../../model/order-type';
import { MediaSelModel } from '../../user/setting/store/model/setting.model';
import { AccountListModel } from '../../account/account/store/model/account.model';

export class ReportStore {
  static apiPrefix = '/report';

  constructor() {
    makeAutoObservable(this);
  }

  timeSpecificationCheckStatus: number[] = [0];
  datePicked = '';

  // 更新
  updateTimeSpecificationCheckStatus(index: number, value: number) {
    this.timeSpecificationCheckStatus = this.timeSpecificationCheckStatus.map(
      (item, i) => (i === index ? value : item)
    );
  }

  // 添加
  addTimeSpecificationCheckStatus(value: number) {
    this.timeSpecificationCheckStatus = [
      ...this.timeSpecificationCheckStatus,
      value
    ];
  }

  // 删除
  removeTimeSpecificationCheckStatus(index: number) {
    this.timeSpecificationCheckStatus =
      this.timeSpecificationCheckStatus.filter((_, i) => i !== index);
  }

  //reset to default condition = 0
  resetTimeSpecificationCheckStatus() {
    this.timeSpecificationCheckStatus = [0];
  }

  updateDateReport(value: string) {
    this.datePicked = value;
  }

  mediaSelListStore = fromListStore<MediaSelModel>(() =>
    fetchGet('/common/media/codes')
  );

  agencySelListStore = fromListStore<MediaSelModel>(() =>
    fetchGet('/common/agency/codes')
  );

  listStore = fromPageListStore<ReportListModel, { orderType: OrderType }>(
    (params) => fetchPost(`${ReportStore.apiPrefix}/list`, params)
  );

  editStore = fromSubmitStore<ReportModel>((body: ReportModel) => {
    const fetch = body.id ? fetchPut : fetchPost;
    return fetch(`${ReportStore.apiPrefix}`, body);
  });

  detailStore = fromObjStore<ReportModel, { id: string }>(
    ({ id }) => fetchGet(`${ReportStore.apiPrefix}/${id}`),
    {
      successCallback: (data: ReportModel) => {
        this.timeSpecificationCheckStatus = data.exportConditions.map(
          (item) => item.timeSpecification
        );
      }
    }
  );

  delStore = fromSubmitStore<{ id: string }>(({ id }) =>
    fetchDel(`${ReportStore.apiPrefix}/${id}`)
  );

  viewWholeStore = fromObjStore<ReportWholeModel, { id: string }>(({ id }) =>
    fetchGet(`${ReportStore.apiPrefix}/view/${id}/whole`)
  );

  weekStore = fromListStore<WeekModel, { id: string }>(({ id }) =>
    fetchGet(`${ReportStore.apiPrefix}/${id}/week/codes`)
  );

  dateStore = fromListStore<string, { id: string }>(({ id }) =>
    fetchGet(`${ReportStore.apiPrefix}/${id}/date/codes`)
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewWeekStore = fromObjStore<any, { id: string; week: string }>(
    ({ id, week }) =>
      fetchGet(`${ReportStore.apiPrefix}/view/${id}/week/${week}`)
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewDateStore = fromObjStore<any, { id: string; date: string }>(
    ({ id, date }) =>
      fetchGet(`${ReportStore.apiPrefix}/view/${id}/date/${date}`)
  );

  agencyUsersStore = fromListStore<AccountListModel, { id: string }>(({ id }) =>
    fetchGet(`/agency/${id}/user/all`)
  );
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ReportContext = React.createContext<ReportStore>(null!);
