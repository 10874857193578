import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { arrayForOptionMin, arrayForOptionSec } from '../utils/report-utils';

interface Props {
  value?: string;
  onChange?: (value: string) => void;
  isDisable?: boolean;
}

const { Option } = Select;

const TimeMMss = ({ value, onChange, isDisable }: Props) => {
  const [mm, setMm] = useState<string>('');
  const [ss, setSs] = useState<string>('');

  const getTimes = (value: string) => {
    if (value) {
      const times = value.split(':');
      if (times.length === 2) {
        return times;
      }
    }
    return ['', ''];
  };

  // 检查更新 小时
  useEffect(() => {
    if (value && value.length > 1) {
      const [_mm] = getTimes(value);
      if (_mm !== mm) {
        setMm(_mm);
      }
    }
    if (!value) {
      setMm('');
    }
  }, [mm, value, setMm]);

  // 检查更新 分钟
  useEffect(() => {
    if (value && value.length > 1) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, _ss] = getTimes(value);
      if (_ss !== ss) {
        setSs(_ss);
      }
    }
    if (!value) {
      setSs('');
    }
  }, [ss, value, setSs]);

  const onChangeMM = (value: string) => {
    setMm(value);
    if (onChange) {
      onChange(`${value}:${ss || ''}`);
    }
  };
  const onChangeSS = (value: string) => {
    setSs(value);
    if (onChange) {
      onChange(`${mm || ''}:${value}`);
    }
  };

  return (
    <>
      <Select
        style={{ width: 60 }}
        value={mm}
        onChange={onChangeMM}
        getPopupContainer={(trigger) => trigger.parentNode}
        disabled={isDisable}
      >
        {arrayForOptionMin.map((value, index) => {
          const _value = value < 10 ? `0${value}` : value;
          return (
            <Option key={index} value={_value}>
              {_value}
            </Option>
          );
        })}
      </Select>
      <span> : </span>
      <Select
        style={{ width: 60 }}
        value={ss}
        onChange={onChangeSS}
        getPopupContainer={(trigger) => trigger.parentNode}
        disabled={isDisable}
      >
        {arrayForOptionSec.map((value, index) => {
          const _value = value < 10 ? `0${value}` : value;
          return (
            <Option key={index} value={_value}>
              {_value}
            </Option>
          );
        })}
      </Select>
    </>
  );
};

export default TimeMMss;
