import {
  Link,
  LinkProps,
  NavigateOptions,
  useLocation,
  useNavigate
} from 'react-router-dom';
import * as React from 'react';

export const useMyNavigate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (key: string, options?: NavigateOptions) => {
    navigate(key, { ...(options || {}), state: { from: location } });
  };
};

export const MyLink = (props: LinkProps) => {
  const location = useLocation();
  return <Link {...props} state={{ from: location }} />;
};
