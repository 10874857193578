import { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { CsvContext } from '../store';

import BreadCrumb from '../../../components/Breadcrumb';
import { text } from '../../../constants/text';
import MyTable from '../../../components/MyTable';
import columnsData from './columnsData';
import { OrderType } from '../../../model/order-type';

/* eslint-disable @typescript-eslint/no-explicit-any */
const CsvManage = () => {
  const { exportList } = useContext(CsvContext);

  const sortHandle = (sorter: Record<string, any>) => {
    if (sorter.id) {
      return {
        orderType: sorter.id === 'ascend' ? OrderType.ASC : OrderType.DESC
      };
    }
    return {};
  };

  return (
    <>
      <BreadCrumb
        mainTitle={text.csv.breadcrumb.mainMenu}
        subTitle={text.breadCrumb.manualManage.subMenu}
      />
      <div className="subMainArea">
        <MyTable
          listStore={exportList}
          columns={columnsData()}
          rowKey={'id'}
          alive={true}
          search={false}
          sortHandle={sortHandle}
          toolBarRender={() => [<></>]}
        />
      </div>
    </>
  );
};

export default observer(CsvManage);
