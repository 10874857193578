import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { LegendComponent, TitleComponent } from 'echarts/components';
import { RadarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { NumberUtils } from '../utils/number-utils';
import { text, unit } from '../constants/text';
import { DirectionGraph } from '../view/dashboard/store/model/dashboard.model';
import { getMaxValue } from '../utils/common';
import { sizeChart } from '../utils/charts';

echarts.use([TitleComponent, LegendComponent, RadarChart, CanvasRenderer]);

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  data?: DirectionGraph;
  isHasBorder?: boolean;
  isReportDetail?: boolean;
}

type DirectionGraphData = {
  name: string;
  value: string;
};

const ERadarChart = (props: Props) => {
  const { data, isReportDetail } = props;

  const [indicatorList, setIndicatorList] = useState<number[]>([]);
  const [directionData, setDirectionData] = useState<DirectionGraphData[]>([]);

  useEffect(() => {
    if (data) {
      const tempData: number[] = [
        +data.rearwardDirection,
        +data.leftDirection,
        +data.frontDirection,
        +data.rightDirection
      ];
      setIndicatorList(tempData);

      const tooltipList = [
        {
          name: text.chart.direction.forward,
          value: data.frontDirection
        },
        {
          name: text.chart.direction.left,
          value: data.leftDirection
        },
        {
          name: text.chart.direction.right,
          value: data.rightDirection
        },
        {
          name: text.chart.direction.behind,
          value: data.rearwardDirection
        }
      ];
      setDirectionData(tooltipList);
    }
  }, [data]);

  const indicator = [
    {
      name: text.chart.direction.forward,
      max: getMaxValue(indicatorList)
    },
    { name: text.chart.direction.left, max: getMaxValue(indicatorList) },
    {
      name: text.chart.direction.right,
      max: getMaxValue(indicatorList)
    },
    {
      name: text.chart.direction.behind,
      max: getMaxValue(indicatorList)
    }
  ];

  const getOption = () => {
    return {
      color: ['#42aff7'],
      tooltip: {
        show: true,
        className: 'echarts-tooltip',
        position: function (point: any) {
          // 50 and 160: just style for tooltip
          return [point[0] - 50, point[1] - 160];
        },
        formatter: function () {
          let tipString = '';
          for (let i = 0; i < directionData.length; i++) {
            tipString += `<div class="radar-tooltip-wrapper">
                            <p class="radar-tooltip-name">
                              </span>${directionData[i].name}
                            </p>
                            <p class="tooltip-value">
                              ${NumberUtils.toThousands(
                                +directionData[i].value
                              )} ${unit.people}
                            </p>
                          </div>`;
          }
          return tipString;
        }
      },
      radar: [
        {
          indicator: indicator,
          // center: props.isHasBorder ? ['48.5%', '44.5%'] : ['50%', '45%'],
          center: ['50%', '44.5%'],
          radius: 80,
          axisName: {
            show: false
          },
          axisLine: {
            show: false
          },
          splitArea: {
            show: false
          },
          splitLine: {
            show: false
          }
        }
      ],

      series: [
        {
          type: 'radar',
          radarIndex: 0,
          symbol: 'diamond',
          data: [
            {
              value: indicatorList,
              symbol: 'rect',
              symbolSize: 6,
              lineStyle: {
                width: 2,
                type: [8, 2]
              }
            }
          ]
        }
      ]
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox radarBox">
      <ReactEcharts
        option={getOption()}
        style={{
          width: sizeChart.width,
          height: isReportDetail ? sizeChart.print : sizeChart.default
        }}
        ref={echartsEl}
      />
    </div>
  );
};

export default observer(ERadarChart);
