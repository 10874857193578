import { ConfigService, useFetchObjStore } from 'mobx-template';
import { fetchGet } from '../utils/request';
import { AccountStore } from '../view/account/account/store/account-store';
import { AccountModel } from '../view/account/account/store/model/account.model';

export const useAccountName = () => {
  const { isBusy, loadData } = useFetchObjStore<AccountModel>(({ id }) =>
    fetchGet(`${AccountStore.apiPrefix}/${id}`)
  );
  return {
    isBusy,
    loadData: (
      id: string,
      successCallback: (username: string) => void,
      errorCallback?: () => void
    ) => {
      loadData({ id }, { showMessage: false }).then(({ success, data }) => {
        if (success && data) {
          successCallback(data.username);
        } else {
          if (errorCallback) {
            errorCallback();
          }
          ConfigService.config.showErrorMessage({
            message: 'アカウントIDが誤っています。'
          });
        }
      });
    }
  };
};
