import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb, Card, Col, Row, Spin } from 'antd';
import EchartsStackedArea from '../components/EchartsStackedArea';
import ManageForm from './components/ManageForm';
import { DashboardContext } from '../store/dashboard-store';
import ManageCustomerInfo from './components/ManageCustomerInfo';
import ManageWatch from './components/ManageWatch';
import Viewer from '../../../components/Viewer';
import { ViewerService } from '../../../shared/services/viewer';
import ERadarChart from '../../../components/RadarChart';
import EAreaChart from '../../../components/AreaChart';
import EPieChart from '../../../components/PieChart';
import { text } from '../../../constants/text';
import {
  getGenderProportion,
  getAgeProportion,
  getMovingAndStayingProportion
} from '../../../utils/charts';
import EChartStackLine from '../components/EChartStackLine';
import TooltipIcon from '../../../components/TooltipIcon';

const ManageMain = () => {
  const {
    summaryStore: { data: summaryData, isBusy: loading },
    mediaSelListStore: { isBusy: mediaLoading },
    // customerInfoStore: { loadData: loadCustomerInfo, isBusy: customerLoading }
    customerInfoStore: { loadData: loadCustomerInfo }
  } = useContext(DashboardContext);

  useEffect(() => {
    loadCustomerInfo().then();
  }, [loadCustomerInfo]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>ダッシュボード</Breadcrumb.Item>
        <Breadcrumb.Item>全体</Breadcrumb.Item>
      </Breadcrumb>

      <div className="subMainArea">
        <div className="mainCard dashboardCard">
          <div className="cardHeader">
            <div className="selArea">
              <ManageForm />
            </div>
            {/*<p className="time">*/}
            {/*  時刻 <MyTime />*/}
            {/*</p>*/}
          </div>
          <div className="cardBody dashboardBody">
            <Spin
              tip="読み込み..."
              wrapperClassName="cardLoading"
              spinning={loading || mediaLoading}
            >
              <Row gutter={[16, 16]} className="dashboardViewer">
                <Col span={24}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.area}
                        title={text.tooltip.dashboard.areaGraph}
                      />
                    }
                  >
                    <EchartsStackedArea />
                  </Card>
                </Col>
                <Viewer
                  data={
                    new ViewerService(summaryData?.viewerAnalyticsBoard).data
                  }
                />
                {/* <Col span={24}>
                  <DashboardGender />
                </Col> */}
                <Col span={8}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.gender}
                        title={text.tooltip.dashboard.genderChart}
                      />
                    }
                  >
                    <EPieChart
                      data={getGenderProportion(
                        summaryData?.genderProportionPieGraph
                      )}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.ageMale}
                        title={text.tooltip.dashboard.ageMaleChart}
                      />
                    }
                  >
                    <EPieChart
                      data={getAgeProportion(
                        summaryData?.ageProportionMalePieGraph
                      )}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.ageFemale}
                        title={text.tooltip.dashboard.ageFemaleChart}
                      />
                    }
                  >
                    <EPieChart
                      data={getAgeProportion(
                        summaryData?.ageProportionFemalePieGraph
                      )}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.radarChart}
                        title={text.tooltip.dashboard.radarChart}
                      />
                    }
                  >
                    <ERadarChart data={summaryData?.walkingDirectionGraph} />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.movingAndStaying}
                        title={text.tooltip.dashboard.stayAndMovingChart}
                      />
                    }
                  >
                    <EPieChart
                      data={getMovingAndStayingProportion(
                        summaryData?.movingStationaryPieGraph
                      )}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    title={
                      <TooltipIcon
                        textTitle={text.chart.title.viewingTime}
                        title={text.tooltip.dashboard.retentionChart}
                      />
                    }
                  >
                    <EAreaChart data={summaryData?.viewerRetentionGraph} />
                  </Card>
                </Col>
              </Row>
              <Col span={24} className="mt-4">
                <Card
                  title={
                    <TooltipIcon
                      textTitle={text.chart.title.dailyChart}
                      title={text.tooltip.dashboard.dailyGraph}
                    />
                  }
                >
                  <EChartStackLine
                    showTotal={
                      summaryData?.realtimeTrendByMedia24Hours?.legends
                        ?.length > 1
                    }
                  />
                </Card>
              </Col>
              <Row>
                <Col span={12}>
                  <ManageCustomerInfo />
                </Col>
              </Row>
              <ManageWatch />
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(ManageMain);
