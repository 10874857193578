import { PATTERN } from './pattern';
/* eslint-disable @typescript-eslint/no-explicit-any */
export const replaceText = (baseString: string, ...res: any) => {
  let result = baseString;
  res.forEach((val: string, index: number) => {
    result = result.split(`{index${index}}`).join(val);
  });
  return result;
};

export const getFilename = (contentDisposition: string) => {
  const match = contentDisposition.match(PATTERN.FILE_NAME);
  if (match && match[1]) {
    // Decode the URL-encoded filename
    return decodeURIComponent(match[1]);
  }
  return contentDisposition;
};
