/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-namespace */
export namespace PATTERN {
  export const HALF_SIZE_NUMERIC = /^[0-9]+$/;
  export const FULL_SIZE_NUMERIC = /^[０-９]+$/;
  export const EMPTY_STRING = /^\s*$/;
  export const FILE_NAME =
    /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?(?:$|;)/;
  export const AWS_ACCESS_KEY = /^(?!\s)[A-Z0-9]{20}(?![A-Z0-9])/;
  export const AWS_SECRET_KEY =
    /(?<![A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=])/;
  export const AWS_BUCKET_NAME =
    /(?!(^xn--|.+-s3alias$))^[a-z0-9][a-z0-9-]{1,61}[a-z0-9]$/;
  export const AWS_URL = /^s3:\/\/([^\/]+)\/(.*?([\^\/]+)\/?)$/;
  export const COMPANY_NAME = /^[ぁ-んァ-ン一-龯a-zA-Z0-9\s]+$/;
  export const HOUR_ONLY = /:\d{2}$/;
}
