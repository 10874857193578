import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, DatePicker, Form, Select } from 'antd';
import moment from 'moment';
import { DashboardContext } from '../../store/dashboard-store';
import { useFormInit } from '../../../../core/form';

const { Option } = Select;

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  mediaID?: number;
  setMediaID: (mediaID: number) => void;
  mediaDate?: any;
  setMediaDate: (mediaDate: any) => void;
}

let timeout: any;

const MediaForm = (props: Props) => {
  const { mediaID, setMediaID, mediaDate, setMediaDate } = props;
  const [init, setInit] = useState(false);
  const [form] = Form.useForm();

  const {
    summaryStore: { loadData },
    mediaSelListStore: { list: mediaList }
  } = useContext(DashboardContext);

  const today: string = moment().format('YYYYMMDD');

  useEffect(() => {
    if (!init && mediaID) {
      setInit(true);
      loadSummaryData([mediaID], today);
    }
  }, [mediaID]);

  useEffect(() => {
    return () => clearTimerLoad();
  }, []);

  const clearTimerLoad = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
  };

  const startTimerLoad = (mediaIds: any, date: string) => {
    clearTimerLoad();
    timeout = setTimeout(() => {
      loadSummaryData(mediaIds, date);
    }, 15 * 1000);
  };

  const loadSummaryData = (mediaIds: any, date: string) => {
    loadData({ mediaIds: mediaIds, date: date }).then(({ success }) => {
      if (success) {
        if (date === today) {
          startTimerLoad(mediaIds, date);
        } else {
          clearTimerLoad();
        }
      }
    });
  };

  const onFinish = async (values: any) => {
    setMediaID(values.mediaIds);
    setMediaDate(values.date);
    const date = moment(values.date).format('YYYYMMDD');
    if (date !== today) {
      clearTimerLoad();
    }
    loadSummaryData([values.mediaIds], date);
  };

  useFormInit(form, {
    date: mediaDate,
    mediaIds: mediaID === 0 ? null : mediaID
  });

  return (
    <>
      <Form form={form} onFinish={onFinish} className="form" layout="inline">
        <Form.Item
          name="date"
          rules={[{ required: true, message: '媒体を選択してください' }]}
        >
          <DatePicker
            allowClear={false}
            // getPopupContainer={(trigger: any) => trigger.parentNode}
          />
        </Form.Item>
        <Form.Item
          name="mediaIds"
          rules={[{ required: true, message: '媒体を選択してください' }]}
        >
          <Select
            placeholder="媒体を選択してください"
            loading={mediaID === 0}
            // getPopupContainer={(trigger) => trigger.parentNode}
          >
            {mediaList.map((item) => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={() => form.submit()}>
            検索
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default observer(MediaForm);
