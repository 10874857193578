import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  Form,
  Typography,
  Radio,
  Row,
  Col,
  Button,
  Input,
  Switch,
  Spin,
  Select
} from 'antd';

import { text } from '../../../constants/text';
import { AwsRegion, CsvType, EnableAutoExport } from '../../../constants/enum';
import { CsvContext } from '../store';
import { useAliveNotify } from '../../../core/keep-alive/use-keep-alive';
import { PATH_CSV_EXPORT_LIST } from '../../../router/router-path';
import { MediaListModel } from '../../account/media/store/model/media.model';
import { validateString } from '../../../utils/common';
import { PATTERN } from '../../../utils/pattern';

const { Title } = Typography;
const DEFAULT_AUTO_EXPORT = true;

/* eslint-disable @typescript-eslint/no-explicit-any */
const CsvConfigForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const aliveNotify = useAliveNotify();

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };
  const [form]: any = Form.useForm();

  const {
    createConfig: { submit, isBusy },
    detailConfig: { data, loadData, isBusy: isLoadingDetail },
    mediaSelListStore: {
      list: mediaList,
      loadData: loadMedias,
      isBusy: isLoading
    }
  } = useContext(CsvContext);

  useEffect(() => {
    if (params.id) {
      Promise.all([loadData({ id: params?.id })]).then();
    }
  }, []);

  useEffect(() => {
    loadMedias().then();
  }, [loadMedias]);

  const [isAutoExport, setIsAutoExport] = useState(DEFAULT_AUTO_EXPORT);
  useEffect(() => {
    if (data && mediaList) {
      form.setFieldsValue(data);
      const mediaIdList = data.medias.map((media: MediaListModel) => media?.id);
      setIsAutoExport(data.enableAutoExport === EnableAutoExport.YES);
      setMedia(mediaIdList);
    }
  }, [mediaList, data]);
  const [media, setMedia] = useState<number[]>([]);

  const csvType = [
    {
      name: text.csv.scvType.vac,
      value: CsvType.VAC
    },
    {
      name: text.csv.scvType.otc,
      value: CsvType.OTC
    }
  ];

  const awsRegion = [
    {
      name: text.csv.awsRegion.tokyo,
      value: AwsRegion.TOKYO
    },
    {
      name: text.csv.awsRegion.osaka,
      value: AwsRegion.OSAKA
    }
  ];
  const onChangeMedia = (value: any) => {
    setMedia(value);
    form.setFields([
      {
        name: 'mediaIds',
        errors: !value.length ? [text.csv.validation.empty.media] : undefined
      }
    ]);
  };

  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = (values: any) => {
    setIsFetching(true);
    const dataSubmitted = {
      ...values,
      id: params?.id || null,
      mediaIds: media,
      enableAutoExport: values.enableAutoExport
        ? EnableAutoExport.YES
        : EnableAutoExport.NO
    };
    setTimeout(async () => {
      const { success } = await submit(dataSubmitted);
      if (success) {
        aliveNotify();
        navigate(PATH_CSV_EXPORT_LIST);
      }
      setIsFetching(false);
    }, 2500);
  };

  return (
    <Spin tip={text.common.loading} spinning={isLoading || isLoadingDetail}>
      <Form
        {...layout}
        form={form}
        onFinish={onSubmit}
        className="form ant-form ant-form-horizontal"
      >
        <Row gutter={24}>
          <Col span={14}>
            <Title level={5}>{text.csv.auto.title}</Title>
            <Form.Item
              className="mt-10"
              name="enableAutoExport"
              label={text.csv.exportStatus.label}
              initialValue={EnableAutoExport.YES}
            >
              <Switch
                checkedChildren={text.csv.exportStatus.status.on}
                unCheckedChildren={text.csv.exportStatus.status.off}
                checked={isAutoExport}
                onChange={() => setIsAutoExport((prev: boolean) => !prev)}
              />
            </Form.Item>
            <Form.Item
              className="mt-10"
              name="exportType"
              label={text.csv.exportType}
              initialValue={CsvType.VAC}
            >
              <Radio.Group>
                {csvType.map((type, index) => (
                  <Radio value={type.value} key={index}>
                    {type.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="companyName"
              label={text.csv.companyName.label}
              initialValue=""
              rules={[
                {
                  required: true,
                  validator: validateString(
                    PATTERN.COMPANY_NAME,
                    text.csv.validation.empty.companyName,
                    text.csv.validation.invalid.companyName
                  )
                }
              ]}
            >
              <Input
                placeholder={text.csv.companyName.placeHolder}
                maxLength={10}
                onBlur={(e) =>
                  form.setFieldsValue({ companyName: e.target.value.trim() })
                }
              />
            </Form.Item>
            <Form.Item
              name="mediaIds"
              label={text.media.label}
              initialValue={media}
              rules={[
                {
                  required: true,
                  validator(rule, value, callback) {
                    !(media.length > 0)
                      ? callback(text.csv.validation.empty.media)
                      : callback();
                  }
                }
              ]}
            >
              <Select
                allowClear
                placeholder={text.media.placeHolder}
                value={media}
                defaultValue={media}
                mode="multiple"
                onChange={onChangeMedia}
                getPopupContainer={(trigger) => trigger.parentNode}
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return (option?.label.toLowerCase() ?? '').includes(
                    input.toLowerCase()
                  );
                }}
                filterSort={(optionA: any, optionB: any) => {
                  return (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase());
                }}
                options={mediaList.map((media) => {
                  return {
                    label: media.name,
                    value: media.id
                  };
                })}
              />
              {''}
            </Form.Item>
            <Form.Item
              name="s3Region"
              label={text.csv.awsRegion.label}
              initialValue={AwsRegion.TOKYO}
            >
              <Radio.Group>
                {awsRegion.map((type, index) => (
                  <Radio value={type.value} key={index}>
                    {type.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="s3AccessKey"
              label={text.csv.awsAccessKey.label}
              initialValue=""
              rules={[
                {
                  required: true,
                  validator: validateString(
                    PATTERN.AWS_ACCESS_KEY,
                    text.csv.validation.empty.awsAccessKey,
                    text.csv.validation.invalid.awsAccessKey
                  )
                }
              ]}
            >
              <Input
                placeholder={text.csv.awsAccessKey.placeHolder}
                maxLength={30}
                onBlur={(e) =>
                  form.setFieldsValue({ s3AccessKey: e.target.value.trim() })
                }
              />
            </Form.Item>
            <Form.Item
              name="s3SecretKey"
              label={text.csv.awsSecretKey.label}
              initialValue=""
              rules={[
                {
                  required: true,
                  validator: validateString(
                    PATTERN.AWS_SECRET_KEY,
                    text.csv.validation.empty.awsSecretKey,
                    text.csv.validation.invalid.awsSecretKey
                  )
                }
              ]}
            >
              <Input
                placeholder={text.csv.awsSecretKey.placeHolder}
                maxLength={50}
                onBlur={(e) =>
                  form.setFieldsValue({ s3SecretKey: e.target.value.trim() })
                }
              />
            </Form.Item>
            <Form.Item
              name="s3Bucket"
              label={text.csv.awsBucketName.label}
              initialValue=""
              rules={[
                {
                  required: true,
                  validator: validateString(
                    new RegExp(
                      `^(${PATTERN.AWS_URL.source}|${PATTERN.AWS_BUCKET_NAME.source})$`
                    ),
                    text.csv.validation.empty.awsBucketName,
                    text.csv.validation.invalid.awsBucketName
                  )
                }
              ]}
            >
              <Input
                placeholder={text.csv.awsBucketName.placeHolder}
                maxLength={256}
                onBlur={(e) =>
                  form.setFieldsValue({ s3Bucket: e.target.value.trim() })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              className="btnCenter txt-center"
              wrapperCol={{ span: 16, offset: 4 }}
            >
              <Button
                className="mr-4"
                onClick={() => navigate(PATH_CSV_EXPORT_LIST)}
              >
                {text.common.cancel}
              </Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => form.submit()}
                loading={isBusy || isFetching}
              >
                {text.csv.auto.btnSubmit}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(CsvConfigForm);
