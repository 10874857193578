import CryptoJS from 'crypto-js';

export class AccountUtils {
  public static roleID = 'role-id';
  public static secretKey = 'login-110923';
  public static portIdKey = 'id';
  public static passwordKey = 'password';
  public static isRemember = 'is-remember';

  /**
   * Process and remember account number
   * @param isRemember
   * @param id
   * @param password
   * @param exDays
   */
  public static handleRememberAccount(
    isRemember: boolean,
    id: string,
    password: string,
    exDays = 30
  ) {
    localStorage.setItem(
      AccountUtils.isRemember,
      (isRemember ? 1 : 0).toString()
    );
    if (isRemember) {
      AccountUtils.setRememberAccount(id, password, exDays);
    } else {
      AccountUtils.clearRememberAccount(id, password);
    }
  }

  /**
   * Get remember account by isRemember
   */
  public static getRememberAccount(): {
    id: string;
    password: string;
    isRemember: boolean;
  } {
    const isRemember = !!Number(
      localStorage.getItem(AccountUtils.isRemember) || 1
    );
    if (isRemember) {
      return { ...AccountUtils.getAccount(), isRemember };
    }
    return { id: '', password: '', isRemember };
  }

  /**
   * Get remember account
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getAccount(): { id: any; password: any } {
    let id;
    let password;
    if (document.cookie.length > 0) {
      const arr = document.cookie.split('; ');
      for (const item of arr) {
        const arr2 = item.split('==');
        if (arr2[0] === this.portIdKey) {
          const bytes = CryptoJS.AES.decrypt(arr2[1], this.secretKey);
          id = bytes.toString(CryptoJS.enc.Utf8);
        } else if (arr2[0] === this.passwordKey) {
          const bytes = CryptoJS.AES.decrypt(arr2[1], this.secretKey);
          password = bytes.toString(CryptoJS.enc.Utf8);
        }
      }
    }
    return { id, password };
  }

  /**
   * Set remember account
   * @param portId
   * @param psw
   * @param exDays
   */
  public static setRememberAccount(
    portId: string,
    psw: string,
    exDays: number
  ) {
    // Encrypt，加密账号密码
    const cipherPortId = CryptoJS.AES.encrypt(
      portId + '',
      this.secretKey
    ).toString();
    const cipherPsw = CryptoJS.AES.encrypt(psw + '', this.secretKey).toString();

    // 获取時間
    const exdate = new Date();
    // 保存的天数
    exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exDays);

    window.document.cookie = `${
      this.portIdKey
      // @ts-ignore
    }==${cipherPortId};path=/;expires=${exdate.toGMTString()}`;

    window.document.cookie = `${
      this.passwordKey
      // @ts-ignore
    }==${cipherPsw};path=/;expires=${exdate.toGMTString()}`;
  }

  /**
   * Clear account
   * @param portId
   * @param psw
   */
  public static clearRememberAccount(portId: string, psw: string) {
    this.setRememberAccount(portId, psw, -1);
  }

  /**
   * Get user permissions
   */
  public static getRoleID(): number | null {
    const roleID = localStorage.getItem(this.roleID);
    return roleID ? Number(roleID) : null;
  }

  /**
   * Set permissions
   * @param roleID
   */
  public static setRoleID(roleID: number) {
    localStorage.setItem(this.roleID, String(roleID));
  }
}
