import { fetchPost } from '../../../../utils/request';
import { makeAutoObservable } from 'mobx';
import { LoginModel } from './model/login.model';
import React from 'react';
import { fromSubmitStore } from 'mobx-template';
import { AccountUtils } from '../../../../utils/account-utils';
import { AuthStore } from '../../../../store/auth-store';
import { UserModel } from '../../../../store/user.model';

export class LoginStore {
  constructor(private authStore: AuthStore) {
    makeAutoObservable(this);
  }

  // login store
  loginReqStore = fromSubmitStore<LoginModel, UserModel>(
    (body: LoginModel) => fetchPost('/login', body),
    {
      defaultBody: () => AccountUtils.getRememberAccount(),
      successCallback: (data: UserModel) => {
        const { id, password, isRemember } = this.loginReqStore.body;
        this.authStore.user = data;
        // Process and remember account number
        AccountUtils.handleRememberAccount(isRemember, id, password);
      }
    }
  );
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const LoginContext = React.createContext<LoginStore>(null!);
