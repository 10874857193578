import { text } from './text';

export enum ExportType {
  VAC = 1,
  OTC
}

export enum EnableAutoExport {
  NO = 0,
  YES
}

export enum AwsRegion {
  TOKYO = 'ap-northeast-1',
  OSAKA = 'ap-northeast-3'
}

export enum CsvType {
  VAC = 1,
  OTC
}

export const CsvTypeText = {
  [CsvType.VAC]: text.csv.scvType.vac,
  [CsvType.OTC]: text.csv.scvType.otc
};

export enum ExportFileStatus {
  INPROGRESS = 1,
  SUCCESS,
  ERROR
}

export const ExportFileStatusText = {
  [ExportFileStatus.INPROGRESS]: text.csv.list.status.inProgress,
  [ExportFileStatus.SUCCESS]: text.csv.list.status.success,
  [ExportFileStatus.ERROR]: text.csv.list.status.error
};

export enum MediaSettingErrorRes {
  MEDIA = 'The mediaCode is invalid',
  SENSOR = 'The sensor ID or token is invalid'
}
