export const ageUnit = {
  smallGroup: '代',
  bigGroup: '代以上'
};

export const viewerTitle = {
  vac: {
    title: '視聴人数',
    note: '（VAC基準）'
  },
  reach: {
    title: 'リーチ',
    note: '（UU・VAC）'
  },
  frequency: {
    title: 'フリークエンシー',
    note: '（freq.）'
  },
  otc: {
    title: '接触人数',
    note: '（OTC基準）'
  },
  ots: {
    title: '視聴可能人数',
    note: '（OTS基準）'
  },
  audienceRating: {
    title: '視聴確率',
    note: ''
  },
  viewingTime: {
    title: '視聴時間'
  },
  returnInterval: {
    title: '再訪問インターバル'
  },
  viewedDistance: {
    title: '視聴距離'
  },
  dwellTime: {
    title: '滞在時間'
  },
  reportVac: {
    title: '広告視聴人数'
  },
  reportOtc: {
    title: '広告接触人数'
  },
  reportOts: {
    title: '広告視聴可能人数'
  },
  reportAudienceRating: {
    title: '視聴確率'
  },
  reportViewingTime: {
    title: '広告視聴時間'
  },
  reportViewedDistance: {
    title: '広告視聴距離'
  },
  adCost: {
    title: '広告視聴単価',
    note: '（VAC基準）'
  },
  watchingAd: {
    title: '平均広告視聴人数',
    note: '（VAC基準）'
  },
  contactPrice: {
    title: '広告接触単価',
    note: '（OTC基準）'
  },
  contactAd: {
    title: '平均広告接触人数',
    note: '（OTC基準）'
  },
  sound: {
    haveSound: '有音放映',
    noSound: '無音放映'
  }
};

export const unit = {
  yen: '円',
  people: '人',
  impressions: 'imp',
  times: '回',
  percent: '%',
  seconds: '秒',
  days: '日',
  meter: 'm',
  minutes: '分',
  broadcast: '回放映',
  peopleGraph: '人'
};

export const formatDate = {
  monthDay: 'M月D日',
  yearMonthDay: 'YYYY年M月D日'
};

export const average = '平均';

export const text = {
  common: {
    loading: '読み込み...',
    cancel: 'キャンセル'
  },
  sidebar: {
    helpPageTitle: '定義・用語',
    csv: {
      mainTitle: 'CSV機能',
      list: {
        menuTitle: 'CSV設定一覧'
      },
      basicOption: {
        title: 'の基本オプション',
        view: 'オプションのビュー',
        edit: 'オプションを更新',
        btnExport: 'Csvエクスポート'
      },
      autoExport: {
        menuTitle: 'CSV自動送信設定'
      },
      manualExport: {
        menuTitle: 'CSVダウンロード',
        edit: 'S3 Edit'
      },
      status: {
        title: 'CSVステータス管理'
      }
    }
  },
  media: {
    label: '媒体選択（複数選択)',
    singleLabel: '媒体選択',
    placeHolder: '媒体名',
    allSelector: '全部',
    sound: {
      title: '音声',
      haveSound: '視聴確率（有音放映）',
      noSound: '視聴確率（無音放映）',
      comment: 'コメント',
      commentPlaceholder: '無音時間帯があれば記入ください',
      duration: '期間'
    },
    btnAddTime: '期間追加',
    validation: {
      required: '必須フィールド',
      invalidTime: '無効時間',
      invalidValue: '有効な数字にて入力してください。',
      mediaTimeEmpty: '最初に媒体運営時間を入力してください。'
    }
  },
  mediaSetting: {
    mediaCode: '媒体コード',
    sensorId: 'センサーID',
    sensorToken: 'センサートークン',
    placeHolder: {
      mediaCode: '媒体コードを入力してください。',
      sensorId: 'センサーIDを入力してください。',
      sensorToken: 'センサートークンを入力してください。'
    },
    validation: {
      mediaCode: '媒体コードを入力してください。',
      sensorId: 'センサーIDを入力してください。',
      sensorToken: 'センサートークンを入力してください。'
    },
    errorMsg: {
      mediaCode: '媒体コードは無効です。',
      sensor: 'センサーIDおよびセンサートークンは無効です。'
    }
  },
  breadCrumb: {
    mainMenu: 'CSV',
    new: 'New export config',
    auto: {
      subMenu: 'CSV自動'
    },
    manual: {
      subMenu: 'CSVダウンロード'
    },
    manualManage: {
      subMenu: 'CSVステータス'
    },
    list: {
      subMenu: 'CSV設定一覧'
    }
  },
  csv: {
    breadcrumb: {
      mainMenu: 'CSV機能',
      auto: {
        subMenu: 'CSV自動'
      },
      manual: {
        subMenu: 'CSVダウンロード'
      },
      manualManage: {
        subMenu: 'Setting'
      },
      list: {
        subMenu: 'CSV設定一覧'
      }
    },
    list: {
      mainMenu: 'CSV',
      subMenu: 'CSV設定一覧',
      id: 'ID',
      exportType: {
        title: '出力タイプ',
        auto: '自動送信',
        manual: 'ダウンロード'
      },
      companyName: '会社名',
      fileName: 'ファイル名',
      status: {
        title: '出力ステータス',
        inProgress: '出力中',
        success: '成功',
        error: 'エラー'
      },
      media: '媒体名',
      createAt: '作成日',
      action: {
        title: 'アクション',
        add: 'CSV設定追加',
        view: '表示',
        edit: '編集',
        delete: '削除',
        backup: 'CSVダウンロード',
        deleteConfirmMsg: 'こちらのCSV設定を削除しますか。',
        no: 'いいえ',
        yes: 'はい'
      }
    },
    auto: {
      title: 'CSV自動送信設定',
      btnSubmit: '登録',
      exportMsg: {
        s3Info: 'S3サーバの情報が間違っています',
        s3bucket: 'S3のバケット名が間違っています'
      }
    },
    manual: {
      title: 'CSVダウンロード',
      bntSubmit: 'ダウンロード',
      exportMsg: {
        success: 'CSV出力を成功しました',
        error: 'CSV出力を失敗しました'
      }
    },
    scvType: {
      vac: 'VAC',
      otc: 'OTC'
    },
    exportStatus: {
      label: '自動送信ステータス',
      status: {
        on: 'ON',
        off: 'OFF'
      }
    },
    companyName: {
      label: 'データ送信先（略称)',
      placeHolder: '会社名'
    },
    awsRegion: {
      label: 'AWSリージョン',
      tokyo: 'Asia Pacific (Tokyo)',
      osaka: 'Asia Pacific (Osaka)'
    },
    awsAccessKey: {
      label: 'AWSアクセスキー',
      placeHolder: 'AWSアクセスキー'
    },
    awsSecretKey: {
      label: 'AWSシークレットキー',
      placeHolder: 'AWSシークレットキー'
    },
    awsBucketName: {
      label: 'AWSバケット名またはURL',
      placeHolder: 'AWSバケット名またはURL'
    },
    awsStatus: {
      label: 'AWS status',
      placeHolder: 'AWS status'
    },
    exportType: 'インプレッションタイプ',
    dataUsage: {
      label: 'データ期間指定',
      placeHolder: 'Data usage'
    },
    reference: {
      label: '参照期間',
      placeHolder: 'Reference'
    },
    modal: {
      deleteConfirm: 'こちらのCSV設定を削除しますか。'
    },
    validation: {
      empty: {
        companyName: 'データ送信先（略称）を入力してください',
        awsAccessKey: 'AWSアクセスキーを入力してください',
        media: '媒体選択（複数選択）を入力してください',
        singleMedia: '媒体選択を入力してください',
        awsSecretKey: 'AWSシークレットキーを入力してください',
        awsBucketName: 'AWSバケット名またはURLを入力してください'
      },
      invalid: {
        companyName: 'データ送信先は不適切です',
        awsAccessKey: 'AWSアクセスキーは不適切です',
        awsSecretKey: 'AWSシークレットキーは不適切です',
        awsBucketName: 'AWSバケット名・URLは不適切です'
      },
      fileNotExist: 'バックアップのデータがありません。'
    }
  },
  report: {
    addReport: {
      placeHolder: {
        agency: '対象広告主・代理店選択'
      }
    },
    period: {
      title: '広告出稿期間（全期間）'
    },
    addition: '追加',
    broadcasts: {
      byHour: '毎時放映回数',
      byDay: '放映回数（日）',
      allTime: '放映回数（期間）'
    },
    adFeeTitle: '広告放映料金',
    adAirTime: {
      title: '広告放映時間',
      allDay: '終日',
      timeDesign: '時間指定',
      mediaTime: '媒体運営時間全て'
    },
    duration: {
      title: '素材尺'
    },
    viewingPeopleTitle: '広告インプレッション推移',
    detail: {
      label: {
        owner: '広告主名',
        name: '広告内容',
        dateTime: '広告放映期間'
      }
    },
    edit: {
      publishing: '出稿レポート',
      simulation: 'シミュレーション',
      errorMsg: {
        startTime: '開始時間を選択してください',
        endTime: '終了時間を選択してください'
      }
    },
    status: {
      measuring: '計測中',
      standBy: 'スタンバイ',
      processing: '集計中',
      completed: '完了'
    }
  },
  chart: {
    title: {
      area: '媒体インプレッション推移',
      gender: '性別構成',
      ageMale: '男性（年代構成）',
      ageFemale: '女性（年代構成）',
      radarChart: '視聴方向分布',
      reportRadarChart: '広告視聴方向分布',
      movingAndStaying: '歩行者・滞留者比率',
      viewingTime: '視聴秒数分布',
      reportViewingTime: '広告視聴秒数分布',
      dailyChart: '24時間計測データ',
      retention: 'Retention title'
    },
    direction: {
      left: '左方向',
      right: '右方向',
      forward: '有効視野角',
      behind: '反対方向'
    },
    movingStaying: {
      moving: '歩行者',
      staying: '滞留者'
    }
  },
  tooltip: {
    dashboard: {
      areaGraph: 'VAC：視聴人数、OTC：接触人数、OTS：視聴可能人数',
      dailyGraph: '視聴エリア滞在者の24時間データ（OTS基準）',
      vac: '媒体を視聴したVAC基準の総人数（15秒ごとの合計）',
      reach: '媒体を視聴した個人の数（VAC・15秒ごとの合計）',
      frequency: '媒体視聴エリアでの1人当たりの平均訪問回数（7日間）',
      otc: '媒体視聴エリアで媒体の方向を向いているOTC基準の総人数（15秒ごとの合計）',
      ots: '媒体視聴エリアに滞在したOTS基準の総人数（15秒ごとの合計）',
      audienceRating:
        'アイトラッキング調査による客観的な測定結果から定義した視聴確率モデルを適用した値',
      viewingTime: '媒体視聴者1人当たりが視聴に費やした平均時間（最大15秒間）',
      interval: '媒体視聴エリアへ再訪問するまでの日数の平均値（7日間）',
      viewDistance: '媒体視聴者と媒体との平均距離',
      dwellTime: '媒体視聴エリア滞在者の平均滞在時間',
      genderChart: '媒体視聴者の男女の人数と比率',
      ageMaleChart: '媒体視聴者の男性の年代ごとの人数と比率',
      ageFemaleChart: '媒体視聴者の女性の年代ごとの人数と比率',
      radarChart: '媒体視聴エリア滞在者の進行方向ごとの人数と比率',
      stayAndMovingChart: '媒体視聴エリア滞在者の歩行者と滞留者の人数と比率',
      retentionChart: '媒体視聴者の1秒ごとの視聴人数と比率（15秒間）'
    },
    report: {
      generalGraph: 'VAC：視聴人数、OTC：接触人数、OTS：視聴可能人数',
      dailyGraph: 'VAC：視聴人数、OTC：接触人数、OTS：視聴可能人数',
      vac: '広告を視聴したVAC基準の総人数',
      reach: '広告を視聴した個人の数（VAC）',
      frequency:
        '広告出稿期間中の、媒体視聴エリアへの1人当たりの平均訪問回数（7日間）',
      otc: '広告が表示されている間に、媒体視聴エリアで媒体の方向を向いているOTC基準の総人数',
      ots: '広告が表示されている間に、媒体視聴エリアに滞在したOTS基準の総人数',
      viewCost: '広告が表示されている間の、1imp当たりの平均視聴単価',
      viewRate: '広告1回放映当たりの平均視聴人数',
      contactPrice: '広告が表示されている間の、1imp当たりの平均接触単価',
      contactRate: '広告1回放映当たりの平均接触人数',
      audienceRating:
        'アイトラッキング調査による客観的な測定結果から定義した視聴確率モデルを適用した値',
      viewingTime: '広告視聴者1人当たりが視聴に費やした平均時間（最大15秒間）',
      interval:
        '広告出稿期間中に、媒体視聴エリアへ再訪問するまでの平均日数（7日間）',
      viewDistance: '広告出稿期間中の、媒体視聴者と媒体との平均距離',
      dwellTime: '広告出稿期間中の、媒体視聴エリア滞在者の平均滞在時間',
      genderChart: '広告視聴者の男女の人数と比率',
      ageMaleChart: '広告視聴者の男性の年代ごとの人数と比率',
      ageFemaleChart: '広告視聴者の女性の年代ごとの人数と比率',
      radarChart:
        '広告が表示されている間の、視聴エリア滞在者の進行方向ごとの人数と比率',
      stayAndMovingChart:
        '広告が表示されている間の、視聴エリア滞在者の歩行者と滞留者の人数と比率',
      retentionChart: '広告視聴者の1秒ごとの視聴人数と比率（15秒間）'
    }
  },
  setting: {
    title: 'OOH Analyticsアップデートのお知らせ',
    description:
      '2023年5月22日に世界基準のオーディエンスメジャメント指標に準拠しました。'
  },
  footer: {
    title: 'Ⓒ OOH Analytics'
  }
};

export const commonText = {
  en: {
    day: 'days'
  },
  ja: {
    day: '日間'
  },
  gender: {
    male: '男性',
    female: '女性'
  },
  viewer: '視聴者'
};
