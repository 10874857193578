import React, { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  ToolboxComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { UniversalTransition } from 'echarts/features';
import { LineChart } from 'echarts/charts';
import { AvsTrendGraph } from '../store/model/report.model';
import { NumberUtils } from '../../../utils/number-utils';
import moment from 'moment';
import { colorLineGraph, DATE } from '../../../constants';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

interface Props {
  data?: AvsTrendGraph;
  timeLine?: boolean;
  isDailyGraph?: boolean;
  customTimeGraph?: boolean;
  firstIndex?: number;
  lastIndex?: number;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const EchartsStackedLine = (props: Props) => {
  const { data, timeLine, customTimeGraph, firstIndex, lastIndex } = props;
  const tmp: Array<any> = [];
  if (data?.legends?.length) {
    const names = Object.keys(data.series).map((name: string) =>
      name.toUpperCase()
    );
    names.sort(
      (a, b) =>
        parseInt(a.substring(0, a.length - 1)) -
        parseInt(b.substring(0, b.length))
    );

    //convert mobx data to normal array
    const convertDataType = JSON.parse(JSON.stringify(data));

    for (let i = 0; i < convertDataType.legends.length; i++) {
      const dataSeries: any = Object.values(convertDataType.series)[i] || [];
      const temp = {
        name: names[i],
        type: 'line',
        // stack: 'Total',
        areaStyle: {
          opacity: 1
        },
        z: data?.legends.length - i,
        lineStyle: {
          width: 1
        },
        emphasis: {
          focus: 'series'
        },
        data:
          (firstIndex &&
            lastIndex &&
            customTimeGraph &&
            dataSeries?.slice(
              firstIndex,
              (dataSeries.length - lastIndex) * -1
            )) ||
          Object.values(data.series)[i]
      };
      tmp.push(temp);
    }
  }

  const getOption = () => {
    return {
      color: colorLineGraph,
      title: [
        {
          text: '',
          left: 'center'
        }
      ],
      tooltip: {
        trigger: 'axis',
        formatter: (
          params: Array<any>
          // ticket: unknown,
          // callback: () => void
        ) => {
          let headerStr = '';
          if (params[0].axisValue.includes(':')) {
            const header = parseInt(params[0].axisValue.substr(0, 2));
            const oldHeader = header + ':00';
            const newHeader = header + 1 + ':00';
            headerStr = `${oldHeader}~${newHeader}<br/>`;
          } else {
            headerStr = moment(params[0].axisValue).format(DATE) + '<br />';
          }
          // let sum = 0;
          // let totalStr = '';
          let tipString = '';
          let indexColor;
          // debugger;
          for (let i = 0, length = params.length; i < length; i++) {
            indexColor = params[i].color;
            tipString += `<div style="width: 150px;margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style="margin: 0;"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${indexColor}"></span>${params[
              i
            ].seriesName.toUpperCase()}</p><p style="margin: 0;">${NumberUtils.toThousands(
              params[i].value
            )}</p> </div>`;
            // sum += parseInt(params[i].value);
          }
          // totalStr += `<div style="width: 150px; margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style=" margin: 0;">Total</p><p style="margin: 0;">${NumberUtils.toThousands(
          //   sum
          // )}</p></div>`;
          tipString = headerStr + tipString;
          return tipString;
        }
      },
      toolbox: {
        feature: {}
      },
      // grid: {
      //   left: "3%",
      //   right: "4%",
      //   bottom: "3%",
      //   containLabel: true,
      // },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data:
            (firstIndex &&
              lastIndex &&
              customTimeGraph &&
              data &&
              JSON.parse(JSON.stringify(data?.xAxis))?.slice(
                firstIndex,
                (data.xAxis.length - lastIndex) * -1
              )) ||
            data?.xAxis ||
            [],
          axisLabel: timeLine
            ? {}
            : {
                formatter: (value: string) => moment(value).format(DATE)
              }
        }
      ],
      legend: {
        icon: 'circle',
        show: true,
        data: data?.legends.map((val: any) => val.toUpperCase()) || [],
        padding: 20,
        textStyle: {
          rich: {
            name: {
              fontSize: 12
            },
            value: {
              fontSize: 12,
              fontWeight: 900
            }
          }
        }
      },
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: tmp
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: '1088px', height: ' 350px' }}
        ref={echartsEl}
        notMerge={true}
      />
    </div>
  );
};

export default observer(EchartsStackedLine);
