import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { PATH_REPORT } from '../../../../router/router-path';
import { useRoleBack } from '../../../../hooks/use-auth';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons/lib';
import { ReportContext } from '../../store/report-store';
import { useFormInit } from '../../../../core/form';
import { useAliveNotify } from '../../../../core/keep-alive/use-keep-alive';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import {
  AdTimeModel,
  ReportExportCondition
} from '../../store/model/report.model';
import TimeMMss from '../../../../components/TimeMMss';
import UserTable from '../../../../components/UserTable';
import { MediaSelModel } from '../../../user/setting/store/model/setting.model';
import { text, unit } from '../../../../constants/text';
import { CONDITION_STATUS, REPORT_USAGE } from '../../../../shared/enum';
import {
  differentTime,
  durationTime,
  formatHour,
  TYPE_TIME,
  updateHour,
  endTimeOfDay,
  TIME_DURATION,
  compareOtherHour,
  formatHourOnly,
  maxEndTimeMedia,
  initConditionReport,
  EXPORT_CONDITION,
  maxPriceLength,
  calculateOutTime,
  maxHourOfDay
} from '../../../../utils/report-utils';
import { adFeeLimit, durationGroup } from '../../../../constants';
import { NumberUtils } from '../../../../utils/number-utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

/* eslint-disable @typescript-eslint/no-explicit-any */
const ReportEditForm = () => {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const outputLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 }
  };

  const [form] = Form.useForm();

  const navigate = useNavigate();
  const roleBack = useRoleBack();
  const params = useParams();

  const aliveNotify = useAliveNotify();

  const [noneAgency, setNoneAgency] = useState(false);
  const [days, setDays] = useState(0);
  // const [adFee, setAdFee] = useState(0);

  const reportStore = useContext(ReportContext);
  const {
    detailStore: { data, loadData, isBusy: loading },
    mediaSelListStore: { list: medias, loadData: loadMedias },
    agencySelListStore: { list: agencies, loadData: loadAgencies },
    editStore: { submit, isBusy },
    agencyUsersStore: {
      loadData: loadUsers,
      list: allUsers,
      clear: clearUsers
    },
    timeSpecificationCheckStatus
  } = reportStore;

  useEffect(() => {
    if (params?.id) {
      Promise.all([loadData({ id: params?.id }), loadMedias()]).then((res) => {
        const [res1, res2] = res;
        if (res1.success && res2.success) {
          const mediaIds: number[] = (res1.data?.medias || []).map(
            (item: any) => item.id
          );
          const adStartDate = moment(res1.data?.adStartDate, 'YYYY/MM/DD');
          const adEndDate = moment(res1.data?.adEndDate, 'YYYY/MM/DD');
          form.setFieldsValue({
            adPeriod: [adStartDate, adEndDate],
            mediaIds: mediaIds,
            adFee: res1.data?.adFee ? res1.data?.adFee : 0
          });
          const durationDays = adEndDate.diff(adStartDate, 'day') + 1;
          setDays(durationDays);
          setNoneAgency(res1.data?.agencyId === null);
          if (res1.data?.agencyId !== null) {
            loadUsers({ id: res1.data?.agencyId + '' }).then();
          }
          if (window.location.pathname.startsWith('/report/copy/')) {
            form.setFieldsValue({ id: null });
          }
          mediaSelectChange(mediaIds, res2.data, true);
        }
      });
    } else {
      loadMedias().then();
    }
    loadAgencies().then();
  }, [loadData, loadMedias, loadAgencies, loadUsers, window.location]);

  useFormInit(form, data);

  const onFinish = async (values: any) => {
    if (typeof values['mediaIds'] === 'number') {
      values['mediaIds'] = [values['mediaIds']];
    }
    const mediaReport = medias.find((media) => values.mediaIds[0] === media.id);
    const isAllMediaCondition =
      values.exportConditions.findIndex(
        (exp: any) => exp.timeSpecification === 2
      ) !== -1;

    if (mediaReport && isAllMediaCondition) {
      const allMediaData = calculateAllMediaTime(values, mediaReport);
      const { success } = await submit(allMediaData);
      if (success) {
        aliveNotify();
        navigate(PATH_REPORT);
      }
    } else {
      const { success } = await submit(values);
      if (success) {
        aliveNotify();
        navigate(PATH_REPORT);
      }
    }
  };

  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(maxEndTimeMedia);

  const calculateAllMediaTime = (data: any, mediaReport: any) => {
    const largeTime = mediaReport.opEndTime.split(':');
    const isLargeHour = +largeTime[0] >= maxHourOfDay;
    const durationDay =
      differentTime(data.adStartDate, data.adEndDate, TYPE_TIME.DAYS) + 1;
    let durationOutTime = 0;
    if (isLargeHour) {
      // const hourLarge = +largeTime[0] - 24;
      const minuteLarge = +largeTime[1];
      const durationInDay = differentTime(
        mediaReport.opStartTime,
        endTimeOfDay,
        TYPE_TIME.SECONDS
      );
      if (mediaReport.opStartTime.split(':')[0] >= maxHourOfDay) {
        durationOutTime = calculateOutTime(
          mediaReport.opStartTime,
          mediaReport.opEndTime
        );
      } else {
        durationOutTime =
          (+largeTime[0] === maxHourOfDay ? minuteLarge : minuteLarge + 60) *
            60 +
          durationInDay;
      }
    } else {
      durationOutTime = differentTime(
        mediaReport.opStartTime,
        mediaReport.opEndTime,
        TYPE_TIME.SECONDS
      );
    }
    return {
      ...data,
      exportConditions: data.exportConditions.map((exp: any) => {
        if (exp.timeSpecification === 2) {
          return {
            ...exp,
            adCountPerHour: (60 * 60) / TIME_DURATION,
            materialScale: TIME_DURATION,
            adCountPerDay: durationOutTime / TIME_DURATION,
            adCountOfPeriod: (durationOutTime * durationDay) / TIME_DURATION
          };
        }
        return exp;
      })
    };
  };

  const handleBack = () => {
    roleBack(() => {
      navigate(PATH_REPORT);
    });
  };

  // @ts-ignore
  const arrayForOptionHour = [...new Array(27).keys()];
  const changeStartTime = (conditionIdx: number) => {
    const fieldsData = form.getFieldsValue();
    const materialScale =
      fieldsData.exportConditions[conditionIdx].materialScale;
    updateEndTimeCondition(materialScale, conditionIdx);
    recalculateAdCount(conditionIdx);
  };

  const updateEndTimeCondition = (value: any, index: number) => {
    const fieldsData = form.getFieldsValue();
    const _items = fieldsData.exportConditions[index].hourlyAdTimes.map(
      (_item: AdTimeModel) => {
        const autoFillEndTime = moment(_item.startTime, 'mm:ss')
          .add(value, 'seconds')
          .format('mm:ss');
        return {
          ..._item,
          startTime: _item.startTime,
          endTime: _item.startTime && autoFillEndTime
        };
      }
    );
    form.setFieldsValue({
      exportConditions: fieldsData.exportConditions.map(
        (ec: ReportExportCondition, _index: number) => {
          return _index === index
            ? {
                ...ec,
                hourlyAdTimes: _items
              }
            : ec;
        }
      )
    });
    recalculateAdCount(index);
  };

  const calculateOutOfTime = (data: any, mediaReport: any) => {
    return data.reduce((first: any, next: any) => {
      const timeStartOut = compareOtherHour(
        next.startTime,
        `${mediaReport?.opStartTime}:00`
      );
      const timeEndOut = compareOtherHour(
        `${mediaReport?.opEndTime}:00`,
        next.endTime
      );
      return (
        first +
        (timeStartOut && timeEndOut ? 2 : timeStartOut || timeEndOut ? 1 : 0)
      );
    }, 0);
  };

  const [mediaIdSelected, setMediaIdSelected] = useState(null);
  useEffect(() => {
    setMediaIdSelected(form.getFieldValue('mediaIds'));
  }, [JSON.stringify(form.getFieldValue('mediaIds'))]);

  const recalculateAdCount = (conditionIdx: number) => {
    const adStartDate = form.getFieldValue('adStartDate');
    const adEndDate = form.getFieldValue('adEndDate');
    const exportConditions = form.getFieldValue('exportConditions');
    const mediaReport = medias.find(
      (media) =>
        mediaIdSelected === media.id ||
        form.getFieldValue('mediaIds')[0] === media.id
    );
    const timeSpecification = exportConditions[conditionIdx].timeSpecification;
    const countPerHour =
      timeSpecification === CONDITION_STATUS.allDay
        ? exportConditions[conditionIdx].hourlyAdTimes.length
        : timeSpecification === CONDITION_STATUS.custom &&
          exportConditions[conditionIdx]?.adStartTime &&
          exportConditions[conditionIdx]?.adEndTime
        ? exportConditions[conditionIdx].hourlyAdTimes.reduce(
            (firstExp: number, nextExp: EXPORT_CONDITION) => {
              return (
                firstExp + (!!nextExp?.startTime && !!nextExp?.endTime ? 1 : 0)
              );
            },
            0
          )
        : 0;

    const adStartHourMinute =
      timeSpecification === CONDITION_STATUS.allDay
        ? startTime.toString()
        : exportConditions[conditionIdx].adStartTime;
    const adEndHourMinute =
      timeSpecification === CONDITION_STATUS.allDay
        ? endTime.toString()
        : exportConditions[conditionIdx].adEndTime;

    let durationHours: number;
    if (timeSpecification === 1) {
      if (adStartHourMinute && adEndHourMinute) {
        const startHour = adStartHourMinute.split(':')[0];
        const endHour = !compareOtherHour(adStartHourMinute, adEndHourMinute)
          ? updateHour(adStartHourMinute)
          : adEndHourMinute;
        durationHours = Number(endHour.split(':')[0]) - Number(startHour);
      } else {
        durationHours = 0;
      }
    } else {
      durationHours = endTime - startTime;
    }
    const tempCondition = exportConditions[conditionIdx].hourlyAdTimes.map(
      (val: any) => {
        return {
          startTime: `${adStartHourMinute?.split(':')[0]}:${val?.startTime}`,
          endTime: `${formatHourOnly(+adEndHourMinute?.split(':')[0] - 1)}:${
            val?.endTime
          }`
        };
      }
    );
    const countOutTime = calculateOutOfTime(tempCondition, mediaReport);

    const realTimePerHour =
      countPerHour === 1 && countOutTime === 1 && durationHours === 1
        ? 0
        : countPerHour > 1 && durationHours === 1
        ? countPerHour - countOutTime
        : countPerHour;

    const adCountPerDay =
      realTimePerHour === 0 ? 0 : durationHours * countPerHour - countOutTime;

    form.setFieldsValue({
      exportConditions: exportConditions.map(
        (ec: ReportExportCondition, _index: number) => {
          if (adStartDate !== null && adEndDate !== null) {
            const durationDays =
              moment(adEndDate).diff(moment(adStartDate), 'day') + 1;
            const adCountOfPeriod = durationDays * adCountPerDay;
            return _index === conditionIdx
              ? {
                  ...ec,
                  adCountPerDay: adCountPerDay,
                  adCountOfPeriod: adCountOfPeriod,
                  adCountPerHour: realTimePerHour,
                  adEndTime:
                    ec.adEndTime &&
                    !compareOtherHour(ec.adStartTime, ec.adEndTime)
                      ? updateHour(ec.adStartTime)
                      : ec.adEndTime
                }
              : ec;
          } else {
            return _index === conditionIdx
              ? {
                  ...ec,
                  adCountPerDay: adCountPerDay,
                  adCountPerHour: realTimePerHour,
                  adEndTime:
                    ec.adEndTime &&
                    !compareOtherHour(ec.adStartTime, ec.adEndTime)
                      ? updateHour(ec.adStartTime)
                      : ec.adEndTime
                }
              : ec;
          }
        }
      )
    });
  };

  const recalculateAdCountOfPeriod = (dateStrings: string[]) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    const exportConditions = form.getFieldValue('exportConditions');
    if (startDate && endDate && exportConditions.length > 0) {
      const adStartDate = moment(startDate, 'YYYY/MM/DD (ddd)');
      const adEndDate = moment(endDate, 'YYYY/MM/DD (ddd)');
      const durationDays = adEndDate.diff(adStartDate, 'day') + 1;
      setDays(durationDays);
      form.setFieldsValue({
        adStartDate: adStartDate.format('YYYY/MM/DD'),
        adEndDate: adEndDate.format('YYYY/MM/DD'),
        adPeriod: [adStartDate, adEndDate],
        exportConditions: exportConditions.map((ec: ReportExportCondition) => {
          const countPerHour = ec.hourlyAdTimes.length;
          const adStartHourMinute = ec.adStartTime;
          const adEndHourMinute = ec.adEndTime;

          let durationHours: number;
          if (ec.timeSpecification === 1) {
            if (adStartHourMinute !== null && adEndHourMinute !== null) {
              const startHour = adStartHourMinute.split(':')[0];
              const endHour = adEndHourMinute.split(':')[0];
              durationHours = Number(endHour) - Number(startHour);
            } else {
              durationHours = 0;
            }
          } else {
            durationHours = endTime - startTime;
          }

          if (ec.hourlyAdTimes.length > 0) {
            const adCountPerDay = durationHours * countPerHour;
            const adCountOfPeriod = durationDays * adCountPerDay;
            return {
              ...ec,
              adCountPerDay: adCountPerDay,
              adCountOfPeriod: adCountOfPeriod,
              adCountPerHour: ec.hourlyAdTimes.length
            };
          }
          return ec;
        })
      });
    }
  };

  const mediaSelectChange = (
    list: number[],
    __medias?: MediaSelModel[],
    isInit = false
  ) => {
    if (list.length === 0) {
      setStartTime(Number(0));
      setEndTime(Number(maxEndTimeMedia));
    } else {
      let opStartTime = 0;
      let opEndTime = 0;
      list.forEach((_value) => {
        const _medias = (__medias || medias).find(
          (value) => value.id === _value
        );
        const tempStartTime = _medias?.opStartTime.split(':');
        const tempEndTime = _medias?.opEndTime.split(':');
        if (opStartTime === 0 && opEndTime === 0) {
          opStartTime = (tempStartTime && Number(tempStartTime[0])) || 0;
          opEndTime =
            Number(
              tempEndTime &&
                (tempEndTime[1] !== '00' ? +tempEndTime[0] + 1 : tempEndTime[0])
            ) || 0;
        } else {
          opStartTime =
            tempStartTime && Number(tempStartTime[0]) > opStartTime
              ? Number(tempStartTime[0])
              : opStartTime;
          opEndTime =
            tempEndTime && Number(tempEndTime[0]) < opEndTime
              ? Number(tempEndTime[0])
              : opEndTime;
        }
      });
      setStartTime(Number(opStartTime));
      setEndTime(Number(opEndTime));
      if (!isInit) {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          exportConditions: [{ ...initConditionReport, timeSpecification: 0 }]
        });
        reportStore.resetTimeSpecificationCheckStatus();
      }
    }
  };

  const handleAgencySelect = (selectedId: string) => {
    if (selectedId) {
      loadUsers({ id: selectedId }).then();
    } else {
      clearUsers();
    }
  };

  const reportUsageList = [
    {
      name: text.report.edit.publishing,
      value: REPORT_USAGE.publishing
    },
    {
      name: text.report.edit.simulation,
      value: REPORT_USAGE.simulation
    }
  ];

  const hourSelector = (field: any, conditionIdx: number, name: string) => {
    const excStartTime =
      form.getFieldValue('exportConditions')[conditionIdx]?.adStartTime;

    return (
      <Form.Item
        name={[field.name, name]}
        fieldKey={[field.fieldKey, name]}
        rules={[
          {
            required: true,
            message:
              name === 'adStartTime'
                ? text.report.edit.errorMsg.startTime
                : text.report.edit.errorMsg.endTime
          }
        ]}
      >
        <Select
          style={{ width: 90 }}
          onChange={() => recalculateAdCount(conditionIdx)}
          getPopupContainer={(trigger) => trigger.parentNode}
          disabled={!mediaIdSelected}
        >
          {arrayForOptionHour.map((value, index) => {
            const _value = formatHour(value);
            return (
              <Option
                disabled={
                  value < startTime ||
                  value > endTime ||
                  (name === 'adStartTime' && value === endTime) ||
                  (name === 'adEndTime' &&
                    excStartTime &&
                    !compareOtherHour(excStartTime, _value))
                }
                value={_value}
                key={index}
              >
                {_value}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    );
  };

  const onChangeCondition = (e: any, conditionIdx: number) => {
    const data = form.getFieldsValue();
    form.setFieldsValue({
      ...data,
      exportConditions: data.exportConditions.map((cdt: any, index: number) => {
        return index === conditionIdx
          ? {
              ...cdt,
              timeSpecification: cdt.timeSpecification,
              ...initConditionReport
            }
          : { ...cdt };
      })
    });
    reportStore.updateTimeSpecificationCheckStatus(
      conditionIdx,
      e.target.value
    );
  };

  return (
    <Spin tip="読み込み..." spinning={loading}>
      <Form form={form} {...layout} onFinish={onFinish} className="form">
        <Row gutter={24}>
          <Col span={14}>
            <Form.Item name="id" label="レポートID">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item
              name="usageType"
              label="用途:"
              initialValue={REPORT_USAGE.publishing}
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Radio.Group>
                {reportUsageList.map((reportUse, index) => (
                  <Radio value={reportUse.value} key={index}>
                    {reportUse.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="owner"
              label="広告主名"
              rules={[
                {
                  required: true,
                  message: '広告主名を入力してください'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              label="広告内容（タイトル等）"
              rules={[
                {
                  required: true,
                  message: '広告内容（タイトル等）を入力してください'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="mediaIds"
              label="対象媒体"
              rules={[
                { required: true, message: '対象媒体を選択してください' }
              ]}
            >
              <Select
                placeholder="対象媒体選択"
                allowClear={true}
                onChange={(list: any) => mediaSelectChange([list])}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {medias.map((media, index) => (
                  <Option key={index} value={media.id}>
                    {media.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              initialValue={noneAgency}
              valuePropName="checked"
              wrapperCol={{ offset: 8, span: 16 }}
            >
              <Checkbox
                checked={noneAgency}
                onChange={(e) => setNoneAgency(e.target.checked)}
              >
                広告主・代理店へ共有しない
              </Checkbox>
            </Form.Item>
            {!noneAgency && (
              <Form.Item
                name="agencyId"
                label="広告主・代理店選択:"
                rules={[
                  { required: true, message: '対象代理店を選択してください' }
                ]}
              >
                <Select
                  onChange={(e: string) => handleAgencySelect(e)}
                  disabled={noneAgency}
                  placeholder={text.report.addReport.placeHolder.agency}
                  allowClear={true}
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {agencies.map((agency, index) => (
                    <Option key={index} value={agency.id}>
                      {agency.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label={text.report.detail.label.dateTime}
              className="dateTime-picker"
            >
              <Form.Item
                name="adPeriod"
                rules={[
                  {
                    required: true,
                    message: '広告放映期間を入力してください'
                  }
                ]}
                noStyle
              >
                <RangePicker
                  onChange={(_, dateStrings) =>
                    recalculateAdCountOfPeriod(dateStrings)
                  }
                  format="YYYY/MM/DD (ddd)"
                  placeholder={['', '']}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                />
              </Form.Item>
              <span className="ant-form-text">（{days}日間）</span>
            </Form.Item>
            <Form.Item hidden={true} name="adStartDate">
              <Input type="hidden"></Input>
            </Form.Item>
            <Form.Item hidden={true} name="adEndDate">
              <Input type="hidden"></Input>
            </Form.Item>

            <Form.Item label={text.report.adFeeTitle} className="addFeeField">
              <Form.Item name="adFee" noStyle>
                <InputNumber
                  className="addFee"
                  min={0}
                  // defaultValue={0}
                  formatter={(value) => `${NumberUtils.toThousands(value)}`}
                  onKeyPress={(e: any) => {
                    if (
                      (e.target.value
                        .toString()
                        .split('')
                        .filter((val: string) => val !== ',').length >=
                        adFeeLimit ||
                        //input numeric and delete, backspace only
                        e.which < 48 ||
                        e.which > 57) &&
                      e.which !== 8
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    const newValue = value
                      .split(',')
                      .join('')
                      .split('-')
                      .join('')
                      .split('')
                      .slice(0, maxPriceLength)
                      .join('');
                    form.setFieldsValue({
                      adFee: newValue
                    });
                  }}
                />
              </Form.Item>
              <span className="prefix edit">{unit.yen}</span>
            </Form.Item>
          </Col>
          <Col span={10}>
            {!noneAgency && (
              <Form.Item
                name="userIds"
                label="広告主・代理店ユーザー"
                labelCol={{ offset: 4, span: 24 }}
                wrapperCol={{ offset: 4, span: 24 }}
                rules={[
                  { required: true, message: '対象ユーザを選択してください' }
                ]}
              >
                <UserTable allUsers={allUsers} />
              </Form.Item>
            )}
          </Col>
        </Row>

        <Form.List
          initialValue={[{ hourlyAdTimes: [{ startTime: '', endTime: '' }] }]}
          name="exportConditions"
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, conditionIdx) => {
                return (
                  <div key={conditionIdx}>
                    <Row gutter={24}>
                      <Col span={22}>
                        <div key={field.key}>
                          <Divider orientation="left">{`レポート出力条件${
                            conditionIdx + 1
                          }`}</Divider>
                          <Form.Item
                            {...outputLayout}
                            label={text.report.adAirTime.title}
                          >
                            <Form.Item
                              name={[field.name, 'timeSpecification']}
                              fieldKey={[field.fieldKey, 'timeSpecification']}
                              initialValue={0}
                            >
                              <Radio.Group
                                // defaultValue={0}
                                onChange={(e) =>
                                  onChangeCondition(e, conditionIdx)
                                }
                              >
                                <Radio value={0}>
                                  {text.report.adAirTime.allDay}
                                </Radio>
                                <Radio value={1}>
                                  {text.report.adAirTime.timeDesign}
                                </Radio>
                                <Radio
                                  value={2}
                                  disabled={
                                    timeSpecificationCheckStatus.length >=
                                    CONDITION_STATUS.allMedia
                                  }
                                >
                                  {text.report.adAirTime.mediaTime}
                                </Radio>
                              </Radio.Group>
                            </Form.Item>
                            {!!timeSpecificationCheckStatus[conditionIdx] &&
                              timeSpecificationCheckStatus[conditionIdx] ===
                                1 && (
                                <Space align="baseline">
                                  {hourSelector(
                                    field,
                                    conditionIdx,
                                    'adStartTime'
                                  )}
                                  〜
                                  {hourSelector(
                                    field,
                                    conditionIdx,
                                    'adEndTime'
                                  )}
                                </Space>
                              )}
                          </Form.Item>

                          {timeSpecificationCheckStatus[conditionIdx] !== 2 && (
                            <>
                              <Form.Item
                                {...outputLayout}
                                name={[field.name, 'materialName']}
                                fieldKey={[field.fieldKey, 'materialName']}
                                label="素材名"
                              >
                                <Input
                                  maxLength={50}
                                  style={{ width: '50%' }}
                                />
                              </Form.Item>

                              <Form.Item
                                {...outputLayout}
                                name={[field.name, 'materialScale']}
                                fieldKey={[field.fieldKey, 'materialScale']}
                                label={text.report.duration.title}
                                rules={[
                                  {
                                    required: true,
                                    message: '素材尺を選択してください'
                                  }
                                ]}
                              >
                                <Select
                                  style={{ width: '20%' }}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                  onChange={(value) =>
                                    updateEndTimeCondition(value, conditionIdx)
                                  }
                                  disabled={!mediaIdSelected}
                                >
                                  {durationTime.map(
                                    (time: number, index: number) => (
                                      <Option
                                        key={index}
                                        value={durationGroup(time).value}
                                      >
                                        {durationGroup(time).text}
                                      </Option>
                                    )
                                  )}
                                </Select>
                              </Form.Item>
                              <Row gutter={24}>
                                <Col span={18}>
                                  <Form.Item
                                    label="毎時放映時間"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          '毎時放映時間を選択してください'
                                      }
                                    ]}
                                  >
                                    <Form.List
                                      initialValue={[]}
                                      name={[field.name, 'hourlyAdTimes']}
                                    >
                                      {(fields, { add, remove }) => (
                                        <>
                                          {fields.map((field, index) => {
                                            return (
                                              <Space
                                                key={field.key}
                                                align="baseline"
                                              >
                                                <Form.Item
                                                  label="開始(分秒)"
                                                  name={[
                                                    field.name,
                                                    'startTime'
                                                  ]}
                                                  fieldKey={[
                                                    field.fieldKey,
                                                    'startTime'
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        '開始(分秒)を入力してください'
                                                    },
                                                    {
                                                      type: 'string',
                                                      min: 5,
                                                      message:
                                                        '開始(分秒)を入力してください'
                                                    }
                                                  ]}
                                                >
                                                  <TimeMMss
                                                    onChange={() =>
                                                      changeStartTime(
                                                        conditionIdx
                                                      )
                                                    }
                                                    isDisable={!mediaIdSelected}
                                                  ></TimeMMss>
                                                </Form.Item>
                                                <Form.Item
                                                  label="終了(分秒)"
                                                  name={[field.name, 'endTime']}
                                                  fieldKey={[
                                                    field.fieldKey,
                                                    'endTime'
                                                  ]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message:
                                                        '終了(分秒)を入力してください'
                                                    }
                                                  ]}
                                                >
                                                  <Input disabled={true} />
                                                </Form.Item>
                                                {fields.length > 1 && (
                                                  <MinusCircleOutlined
                                                    onClick={() => {
                                                      remove(index);
                                                      recalculateAdCount(
                                                        conditionIdx
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </Space>
                                            );
                                          })}
                                          <Form.Item>
                                            <Button
                                              style={{ width: '90%' }}
                                              type="dashed"
                                              onClick={() => {
                                                add();
                                                // recalculateAdCount(
                                                //   conditionIdx
                                                // );
                                              }}
                                              block
                                              icon={<PlusOutlined />}
                                            >
                                              {text.report.addition}
                                            </Button>
                                          </Form.Item>
                                        </>
                                      )}
                                    </Form.List>
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    labelCol={{ span: 12 }}
                                    name={[field.name, 'adCountPerHour']}
                                    fieldKey={[
                                      field.fieldKey,
                                      'adCountPerHour'
                                    ]}
                                    label={text.report.broadcasts.byHour}
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ span: 12 }}
                                    name={[field.name, 'adCountPerDay']}
                                    fieldKey={[field.fieldKey, 'adCountPerDay']}
                                    label={text.report.broadcasts.byDay}
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                  <Form.Item
                                    labelCol={{ span: 12 }}
                                    name={[field.name, 'adCountOfPeriod']}
                                    fieldKey={[
                                      field.fieldKey,
                                      'adCountOfPeriod'
                                    ]}
                                    label={text.report.broadcasts.allTime}
                                  >
                                    <Input disabled />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col span={1} offset={1} style={{ marginTop: '18px' }}>
                        {fields.length > 1 && (
                          <MinusCircleOutlined
                            translate="yes"
                            onClick={() => {
                              remove(conditionIdx);
                              reportStore.removeTimeSpecificationCheckStatus(
                                conditionIdx
                              );
                            }}
                          />
                        )}
                      </Col>
                    </Row>
                  </div>
                );
              })}

              <Form.Item
                hidden={fields.length >= 3}
                wrapperCol={{ span: 20, offset: 2 }}
              >
                <Button
                  type="dashed"
                  onClick={() => {
                    add({
                      hourlyAdTimes: [{ startTime: '', endTime: '' }],
                      timeSpecification: 0
                    });
                    reportStore.addTimeSpecificationCheckStatus(0);
                  }}
                  block
                  icon={<PlusOutlined translate="yes" />}
                  disabled={
                    timeSpecificationCheckStatus[0] ===
                    CONDITION_STATUS.allMedia
                  }
                >
                  レポート出力条件を追加する
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        <Divider />
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              className="btnCenter"
              wrapperCol={{ span: 16, offset: 4 }}
            >
              <Button onClick={() => handleBack()}>キャンセル</Button>
              <Button
                key="submit"
                type="primary"
                onClick={() => form.submit()}
                loading={isBusy}
              >
                {params.id
                  ? window.location.pathname.startsWith('/report/copy/')
                    ? '登録'
                    : '更新'
                  : '登録'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

export default observer(ReportEditForm);
