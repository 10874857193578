import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Col, Row, Card } from 'antd';
import { ReportContext } from '../../store/report-store';
import ERadarChart from '../../../../components/RadarChart';
import EPieChart from '../../../../components/PieChart';
import EchartsStackedLine from '../../components/EchartsStackedLine';
import EAreaChart from '../../../../components/AreaChart';
import Viewer from '../../../../components/Viewer';
import { ViewerService } from '../../../../shared/services/viewer';
import { getTextReportStatus } from '../../../../utils/common';
import {
  getAgeProportion,
  getGenderProportion,
  getMovingAndStayingProportion
} from '../../../../utils/charts';
import { text } from '../../../../constants/text';
import TooltipIcon from '../../../../components/TooltipIcon';
import { CONDITION_REPORT } from '../../../../utils/report-utils';
import { CONDITION_STATUS } from '../../../../shared/enum';

const ReportDate = () => {
  const {
    detailStore: { data: reportDef },
    viewDateStore: { data: viewDateData },
    datePicked
  } = useContext(ReportContext);

  const getTime = (typeTime = true) => {
    return reportDef?.exportConditions?.reduce(function (
      prev: CONDITION_REPORT,
      current: CONDITION_REPORT
    ) {
      const prevStartTime = +prev.adStartTime?.split(':')[0];
      const currentStartTime = +current.adStartTime?.split(':')[0];
      const prevEndTime = +prev.adEndTime?.split(':')[0];
      const currentEndTime = +current.adEndTime?.split(':')[0];
      return typeTime
        ? prevStartTime < currentStartTime
          ? prev
          : current
        : prevEndTime > currentEndTime
        ? prev
        : current;
    });
  };

  const findFirstIndex = viewDateData?.cameraDataChartInDate?.xAxis.findIndex(
    (ax: string) => ax === getTime().adStartTime
  );

  const findLastIndex = viewDateData?.cameraDataChartInDate?.xAxis.findIndex(
    (ax: string) => ax === getTime(false).adEndTime
  );

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.report.viewingPeopleTitle}
                title={text.tooltip.report.generalGraph}
              />
            }
          >
            <EchartsStackedLine
              data={viewDateData?.cameraDataChartInDate}
              timeLine={true}
              //all condition is custom date, exportConditions === 1
              customTimeGraph={reportDef?.exportConditions?.every(
                (cdt: CONDITION_REPORT) =>
                  cdt.timeSpecification === CONDITION_STATUS.custom
              )}
              firstIndex={findFirstIndex}
              lastIndex={findLastIndex}
            />
          </Card>
        </Col>
        <Viewer
          data={new ViewerService(viewDateData?.viewerAnalyticsBoard).data}
          reportStatus={getTextReportStatus(datePicked, reportDef?.status)}
          isReportPage={true}
        />
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.gender}
                title={text.tooltip.report.genderChart}
              />
            }
          >
            <EPieChart
              data={getGenderProportion(viewDateData?.genderProportionPieGraph)}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.ageMale}
                title={text.tooltip.report.ageMaleChart}
              />
            }
          >
            <EPieChart
              data={getAgeProportion(viewDateData?.ageProportionMalePieGraph)}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.ageFemale}
                title={text.tooltip.report.ageFemaleChart}
              />
            }
          >
            <EPieChart
              data={getAgeProportion(viewDateData?.ageProportionFemalePieGraph)}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.reportRadarChart}
                title={text.tooltip.report.radarChart}
              />
            }
          >
            <ERadarChart
              data={viewDateData?.walkingDirectionGraph}
              isHasBorder={true}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.movingAndStaying}
                title={text.tooltip.report.stayAndMovingChart}
              />
            }
          >
            <EPieChart
              data={getMovingAndStayingProportion(
                viewDateData?.movingStationaryPieGraph
              )}
              isReportDetail={true}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size={'small'}
            title={
              <TooltipIcon
                textTitle={text.chart.title.reportViewingTime}
                title={text.tooltip.report.retentionChart}
              />
            }
          >
            <EAreaChart
              data={viewDateData?.viewerRetentionGraph}
              isHasBorder={true}
              isReportDetail={true}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default observer(ReportDate);
