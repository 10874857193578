import React from 'react';
import { observer } from 'mobx-react-lite';
import { MyKeepAlive } from '../../../core/keep-alive/MyKeepAlive';
import ManageMain from './ManageMain';
import { DashboardStore, DashboardContext } from '../store/dashboard-store';

export default observer(() => {
  return (
    <DashboardContext.Provider value={new DashboardStore()}>
      <MyKeepAlive onlyOne={true}>
        <ManageMain />
      </MyKeepAlive>
    </DashboardContext.Provider>
  );
});
