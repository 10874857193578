import { observer } from 'mobx-react-lite';

import { text } from '../../constants/text';
import BreadCrumb from '../../components/Breadcrumb';
import CsvConfigForm from './components/CsvConfigForm';
import { CsvContext, CsvStore } from './store';
import { MyKeepAlive } from '../../core/keep-alive/MyKeepAlive';

export default observer(() => {
  return (
    <CsvContext.Provider value={new CsvStore()}>
      <MyKeepAlive onlyOne={true}>
        <BreadCrumb
          mainTitle={text.csv.breadcrumb.mainMenu}
          subTitle={text.breadCrumb.auto.subMenu}
        />
        <div className="subMainArea">
          <div className="mainCard dashboardCard csv-form">
            <div className="cardBody">
              <CsvConfigForm />
            </div>
          </div>
        </div>
      </MyKeepAlive>
    </CsvContext.Provider>
  );
});
