import { Card } from 'antd';

import { ViewerDetailModel } from '../store/viewer-model';
import { NumberUtils } from '../utils/number-utils';
import { average } from '../constants/text';
import TooltipIcon from './TooltipIcon';

const ViewerDetail = (props: ViewerDetailModel) => {
  const {
    title,
    tooltip,
    note,
    value,
    secondValue,
    unit,
    secondUnit,
    isRating = false,
    isAvg = false,
    ratioUnit,
    isDecimal = false,
    reportStatus = ''
  } = props;

  return (
    <Card bordered={isRating} size="small">
      <div className="manager-viewer">
        <div className="title">
          <span className="mr-2">{title}</span>
          {tooltip && <TooltipIcon title={tooltip} />}
        </div>
        <div className="note">{note}</div>
        <div
          className={`viewer-value justify-between ${
            reportStatus ? 'measuring-wrap' : ''
          }`}
        >
          {reportStatus ? (
            <span className="value measuring txt-bold txt-center">
              {reportStatus}
            </span>
          ) : (
            <>
              {ratioUnit && !isAvg ? (
                <span className="empty-desc"></span>
              ) : (
                <span className="description">{isAvg ? average : ''}</span>
              )}
              <span className="value txt-bold txt-center">
                {isDecimal ? value : NumberUtils.toThousands(+value)}
              </span>
              <span className="unit">
                {ratioUnit ? `${unit} / ${ratioUnit}` : unit}
              </span>
              {secondValue || secondValue === 0 ? (
                <>
                  <span className="value txt-bold">{secondValue}</span>
                  <span className="unit">{secondUnit}</span>
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ViewerDetail;
