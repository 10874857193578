import { ProColumns } from '@ant-design/pro-table/lib/typing';
import { AccountListModel } from '../store/model/account.model';
import { Space } from 'antd';
import { MyLink } from '../../../../core/navigate';
import { PATH_ACCOUNT_UPDATE } from '../../../../router/router-path';
import React from 'react';

const columns: ProColumns<AccountListModel>[] = [
  {
    title: 'アカウントID',
    dataIndex: 'id',
    key: 'id',
    width: 120,
    align: 'center',
    sorter: true
  },
  {
    title: 'アカウント名',
    dataIndex: 'username',
    key: 'username',
    ellipsis: true,
    align: 'left'
  },
  {
    title: '登録媒体数',
    dataIndex: 'mediaCount',
    key: 'mediaCount',
    width: 140,
    search: false,
    align: 'center'
  },
  {
    title: '登録代理店数',
    dataIndex: 'agencyCounty',
    key: 'agencyCounty',
    width: 140,
    search: false,
    align: 'center'
  },
  {
    title: '登録日',
    dataIndex: 'createTime',
    key: 'createTime',
    width: 110,
    search: false,
    align: 'center'
  },
  {
    title: '最終更新日',
    dataIndex: 'modifyTime',
    key: 'modifyTime',
    width: 110,
    search: false,
    align: 'center'
  },
  {
    title: '変更',
    dataIndex: 'others',
    key: 'others',
    width: 120,
    align: 'center',
    search: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    render: (_, row: any) => {
      return (
        <Space size="small">
          <MyLink to={`${PATH_ACCOUNT_UPDATE}/${row.id}`}>変更</MyLink>
        </Space>
      );
    }
  }
];

export default columns;
