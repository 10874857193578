import { observer } from 'mobx-react-lite';
import React from 'react';
import { AgencyContext, AgencyStore } from '../store/agency-store';
import AgencyEdit from './AgencyEdit';

export default observer(() => {
  return (
    <AgencyContext.Provider value={new AgencyStore()}>
      <AgencyEdit />
    </AgencyContext.Provider>
  );
});
