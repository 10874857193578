import React from 'react';

import { makeAutoObservable } from 'mobx';
import {
  ConfigService,
  fromListStore,
  fromObjStore,
  fromPageListStore,
  fromSubmitStore
} from 'mobx-template';

import { CsvConfig, CsvExport, CsvExportData } from '../../../model/csv';
import {
  fetchDel,
  fetchGet,
  fetchPost,
  fetchPut,
  instance
} from '../../../utils/request';
import { MediaSelModel } from '../../user/setting/store/model/setting.model';
import { getFilename } from '../../../utils/string';
import { downloadCSV } from '../../../utils/common';
import { text } from '../../../constants/text';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class CsvStore {
  static exportConfigPrefix = '/export-config';
  static exportManualPrefix = '/export-csv';

  constructor() {
    makeAutoObservable(this);
  }

  createConfig = fromSubmitStore<CsvConfig>((body: CsvConfig) => {
    const fetch = body.id ? fetchPut : fetchPost;
    return fetch(`${CsvStore.exportConfigPrefix}`, body);
  });

  csvConfigList = fromPageListStore<CsvConfig>((params) =>
    fetchPost(`${CsvStore.exportConfigPrefix}/list`, params)
  );

  mediaSelListStore = fromListStore<MediaSelModel>(() =>
    fetchGet('/common/media/codes')
  );

  detailConfig = fromObjStore<CsvConfig, { id: string }>(({ id }) =>
    fetchGet(`${CsvStore.exportConfigPrefix}/${id}`)
  );

  deleteConfig = fromSubmitStore<{ id: string }>(({ id }) =>
    fetchDel(`${CsvStore.exportConfigPrefix}/${id}`)
  );

  exportList = fromPageListStore<CsvExportData>((params) =>
    fetchPost(`${CsvStore.exportManualPrefix}/list`, params)
  );

  exportFile = fromSubmitStore<CsvExport>((body): any => {
    return instance
      .post(`${CsvStore.exportManualPrefix}`, body, {
        responseType: 'blob'
      })
      .then((res) => {
        const csvDownload = document.createElement('a');
        const blob = new Blob([res.data], {
          type: 'application/octet-stream'
        });

        const csvFileName: string = getFilename(
          res.headers['content-disposition']
        );
        csvDownload.href = window.URL.createObjectURL(blob);
        csvDownload.download = csvFileName;
        document.body.appendChild(csvDownload);
        csvDownload.click();
        document.body.removeChild(csvDownload);
      });
  });

  backupCSV = fromSubmitStore<{ id: string }>(({ id }) => {
    return instance
      .get(`${CsvStore.exportManualPrefix}/download/${id}`, {
        responseType: 'blob'
      })
      .then((res) => {
        downloadCSV(res);
      })
      .catch((error) => {
        ConfigService.config.showErrorMessage({
          message: text.csv.validation.fileNotExist
        });
      });
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const CsvContext = React.createContext<CsvStore>(null!);
