export const PATH_LOGIN = '/login';
export const PATH_MANAGE = '/manage';
export const PATH_ACCOUNT = `${PATH_MANAGE}/account/list`;
export const PATH_ACCOUNT_ADD = `${PATH_MANAGE}/account/add`;
export const PATH_ACCOUNT_UPDATE = `${PATH_MANAGE}/account/update`;
export const PATH_MEDIA = `${PATH_MANAGE}/media/list`;
export const PATH_MEDIA_ADD = `${PATH_MANAGE}/media/add`;
export const PATH_MEDIA_UPDATE = `${PATH_MANAGE}/media/update`;
export const PATH_AGENCY = `${PATH_MANAGE}/agency/list`;
export const PATH_AGENCY_ADD = `${PATH_MANAGE}/agency/add`;
export const PATH_AGENCY_UPDATE = `${PATH_MANAGE}/agency/update`;
export const PATH_DASHBOARD = '/dashboard';
export const PATH_DASHBOARD_MANAGE = `${PATH_DASHBOARD}/manage`;
export const PATH_DASHBOARD_ACCOUNT = `${PATH_DASHBOARD}/account`;
export const PATH_DASHBOARD_MEDIA = `${PATH_DASHBOARD}/media`;
export const PATH_CSV = '/csv';
export const PATH_CSV_EXPORT_LIST = `${PATH_CSV}/config-list`;
export const PATH_CSV_ADD = `${PATH_CSV}/add`;
export const PATH_CSV_UPDATE = `${PATH_CSV}/update`;
export const PATH_CSV_AUTO_CONFIG = `${PATH_CSV}/auto-config`;
export const PATH_CSV_MANAGEMENT = `${PATH_CSV}/files-management`;
export const PATH_CSV_MANUAL_EXPORT = `${PATH_CSV}/manual-export`;
export const PATH_CSV_MANUAL = `${PATH_CSV}/export`;
export const PATH_REPORT = '/report';
export const PATH_REPORT_DETAILS = '/report/details';
export const PATH_REPORT_ADD = '/report/add';
export const PATH_REPORT_COPY = '/report/copy';
export const PATH_REPORT_UPDATE = '/report/update';
export const PATH_SETTING = '/setting';
export const PATH_PROFILE = '/profile';
