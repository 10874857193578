import { observer } from 'mobx-react-lite';
import React from 'react';
import { MediaContext, MediaStore } from '../store/media-store';
import MediaEdit from './MediaEdit';

export default observer(() => {
  return (
    <MediaContext.Provider value={new MediaStore()}>
      <MediaEdit />
    </MediaContext.Provider>
  );
});
