import React from 'react';
import { observer } from 'mobx-react-lite';
import MediaList from './MediaList';
import { MyKeepAlive } from '../../../../core/keep-alive/MyKeepAlive';
import { MediaContext, MediaStore } from '../store/media-store';

export default observer(() => {
  return (
    <MediaContext.Provider value={new MediaStore()}>
      <MyKeepAlive onlyOne={true}>
        <MediaList />
      </MyKeepAlive>
    </MediaContext.Provider>
  );
});
