import { Card, Col, Row } from 'antd';
import { text, unit, viewerTitle } from '../constants/text';
import { PAGE_PATH } from '../shared/enum';
import { roundedNumber } from '../utils/common';
import ViewerDetail from './ViewerDetail';
import TooltipIcon from './TooltipIcon';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  data: any;
  isReportPage?: boolean;
  isShowCost?: boolean;
  reportStatus?: string;
  adFee?: number;
  totalView?: number;
}

const Viewer = (props: Props) => {
  const {
    data,
    isReportPage,
    isShowCost,
    reportStatus,
    adFee = 0,
    totalView
  } = props;
  return (
    <>
      <Col flex={3} className="viewer-section custom-col-60">
        <Card>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <ViewerDetail
                title={
                  isReportPage
                    ? viewerTitle.reportVac.title
                    : viewerTitle.vac.title
                }
                tooltip={
                  text.tooltip[
                    isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                  ].vac
                }
                note={viewerTitle.vac.note}
                value={data?.vac}
                unit={unit.impressions}
              />
              <div className="vertical-line"></div>
            </Col>
            <Col span={8}>
              <ViewerDetail
                title={viewerTitle.reach.title}
                tooltip={
                  text.tooltip[
                    isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                  ].reach
                }
                note={viewerTitle.reach.note}
                value={data?.reach}
                unit={unit.people}
              />
              <div className="vertical-line"></div>
            </Col>
            <Col span={8}>
              <ViewerDetail
                title={viewerTitle.frequency.title}
                tooltip={
                  text.tooltip[
                    isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                  ].frequency
                }
                note={viewerTitle.frequency.note}
                value={data?.frequency}
                unit={unit.times}
                isDecimal={true}
                reportStatus={reportStatus}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col flex={2} className="viewer-section custom-col-40">
        <Row gutter={[16, 16]} className="custom-row">
          <Col span={12}>
            <Card>
              <ViewerDetail
                title={
                  isReportPage
                    ? viewerTitle.reportOtc.title
                    : viewerTitle.otc.title
                }
                tooltip={
                  text.tooltip[
                    isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                  ].otc
                }
                note={viewerTitle.otc.note}
                value={data?.otc}
                unit={unit.impressions}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <ViewerDetail
                title={
                  isReportPage
                    ? viewerTitle.reportOts.title
                    : viewerTitle.ots.title
                }
                tooltip={
                  text.tooltip[
                    isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                  ].ots
                }
                note={viewerTitle.ots.note}
                value={data?.ots}
                unit={unit.impressions}
              />
            </Card>
          </Col>
        </Row>
      </Col>
      {isReportPage && isShowCost && (
        <Col span={24} className="viewer-section viewer-cost">
          <Row gutter={[16, 16]} className="custom-row">
            <Col span={12}>
              <Card>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <ViewerDetail
                      title={viewerTitle.adCost.title}
                      tooltip={text.tooltip.report.viewCost}
                      note={viewerTitle.adCost.note}
                      value={(
                        (adFee &&
                          data?.vac > 0 &&
                          roundedNumber(adFee / data?.vac, 1)) ||
                        '0.0'
                      ).toString()}
                      isDecimal={true}
                      isAvg={true}
                      unit={`${unit.yen}`}
                      ratioUnit={unit.impressions}
                    />
                    <div className="vertical-line"></div>
                  </Col>
                  <Col span={12}>
                    <ViewerDetail
                      title={viewerTitle.watchingAd.title}
                      tooltip={text.tooltip.report.viewRate}
                      note={viewerTitle.watchingAd.note}
                      isAvg={true}
                      value={(
                        (totalView &&
                          totalView > 0 &&
                          roundedNumber(data?.vac / totalView, 0)) ||
                        0
                      ).toString()}
                      unit={unit.impressions}
                      ratioUnit={unit.times}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <ViewerDetail
                      title={viewerTitle.contactPrice.title}
                      tooltip={text.tooltip.report.contactPrice}
                      note={viewerTitle.contactPrice.note}
                      value={(
                        (adFee &&
                          data?.otc > 0 &&
                          roundedNumber(adFee / data?.otc, 1)) ||
                        '0.0'
                      ).toString()}
                      isDecimal={true}
                      isAvg={true}
                      unit={unit.yen}
                      ratioUnit={unit.impressions}
                    />
                    <div className="vertical-line"></div>
                  </Col>
                  <Col span={12}>
                    <ViewerDetail
                      title={viewerTitle.contactAd.title}
                      tooltip={text.tooltip.report.contactRate}
                      note={viewerTitle.contactAd.note}
                      isAvg={true}
                      value={(
                        (totalView &&
                          totalView > 0 &&
                          roundedNumber(data?.otc / totalView, 0)) ||
                        0
                      ).toString()}
                      unit={unit.impressions}
                      ratioUnit={unit.times}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      )}
      <Col span={24} className="viewer-section">
        <Row gutter={[16, 16]} className="custom-row">
          <Col flex={1}>
            <Card bordered={true} size="small">
              <div className="manager">
                <div className="title">
                  <span className="mr-2">
                    {isReportPage
                      ? viewerTitle.reportAudienceRating.title
                      : viewerTitle.audienceRating.title}
                  </span>
                  <TooltipIcon
                    title={
                      isReportPage
                        ? text.tooltip.report.audienceRating
                        : text.tooltip.dashboard.audienceRating
                    }
                  />
                </div>
                <div className="d-flex justify-between sound-section mt-1">
                  <div className="sound-item pd-2">
                    <div className="sound-title">
                      {viewerTitle.sound.haveSound}
                    </div>
                    <div className="txt-demi sound-percent">{`${data.viewingProbabilityWithSound}`}</div>
                  </div>
                  <div className="sound-item pd-2">
                    <div className="sound-title">
                      {viewerTitle.sound.noSound}
                    </div>
                    <div className="txt-demi sound-percent">
                      {`${data.viewingProbabilityWithoutSound}`}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            {/* )} */}
          </Col>
          <Col flex={1}>
            <ViewerDetail
              title={
                isReportPage
                  ? viewerTitle.reportViewingTime.title
                  : viewerTitle.viewingTime.title
              }
              tooltip={
                text.tooltip[
                  isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                ].viewingTime
              }
              isAvg={true}
              value={data?.averageViewingTime}
              unit={unit.seconds}
              isDecimal={true}
              isRating={true}
            />
          </Col>
          <Col flex={1}>
            <ViewerDetail
              title={viewerTitle.returnInterval.title}
              tooltip={
                text.tooltip[
                  isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                ].interval
              }
              isAvg={true}
              value={data?.revisitIntervalAve}
              unit={unit.days}
              isDecimal={true}
              isRating={true}
              reportStatus={reportStatus}
            />
          </Col>
          <Col flex={1}>
            <ViewerDetail
              title={
                isReportPage
                  ? viewerTitle.reportViewedDistance.title
                  : viewerTitle.viewedDistance.title
              }
              tooltip={
                text.tooltip[
                  isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                ].viewDistance
              }
              isAvg={true}
              value={data?.viewingDistanceAve}
              unit={unit.meter}
              isDecimal={true}
              isRating={true}
              reportStatus={reportStatus}
            />
          </Col>
          <Col flex={1}>
            <ViewerDetail
              title={viewerTitle.dwellTime.title}
              tooltip={
                text.tooltip[
                  isReportPage ? PAGE_PATH.report : PAGE_PATH.dashboard
                ].dwellTime
              }
              isAvg={true}
              value={data?.dwellTimeMinAve}
              unit={unit.minutes}
              secondValue={data?.dwellTimeSecAve}
              secondUnit={unit.seconds}
              isRating={true}
              reportStatus={reportStatus}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default Viewer;
