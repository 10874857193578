import { useContext } from 'react';
import { CsvContext } from '../store';
import { DownloadOutlined } from '@ant-design/icons';
import { text } from '../../../constants/text';
import {
  ExportFileStatus,
  ExportFileStatusText
} from '../../../constants/enum';

/* eslint-disable @typescript-eslint/no-explicit-any */
const columnsData = () => {
  const {
    backupCSV: { submit }
  } = useContext(CsvContext);

  return [
    {
      title: text.csv.list.id,
      dataIndex: 'id',
      key: 'id',
      width: 80,
      align: 'center',
      sorter: true
    },
    {
      title: text.csv.list.fileName,
      dataIndex: 'name',
      key: 'name',
      width: 600,
      align: 'left'
    },
    {
      title: text.csv.list.status.title,
      dataIndex: 'fileStatus',
      key: 'fileStatus',
      width: 180,
      align: 'center',
      render: (value: ExportFileStatus) => ExportFileStatusText[value]
    },
    {
      title: text.csv.list.createAt,
      dataIndex: 'createTime',
      key: 'createTime',
      width: 240,
      search: false,
      align: 'center'
    },
    {
      title: text.csv.list.action.backup,
      dataIndex: 'backup',
      key: 'backup',
      width: 240,
      search: false,
      align: 'center',
      render: (_: any, row: any) => {
        return <DownloadOutlined onClick={() => submit({ id: row.id })} />;
      }
    }
  ];
};

export default columnsData;
