import React, { useContext, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { DashboardContext } from '../store/dashboard-store';
import { getDataZoom } from '../../../utils/charts';
import { NumberUtils } from '../../../utils/number-utils';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

interface Props {
  showTotal: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const EChartStackLine = (props: Props) => {
  const { showTotal } = props;
  const {
    summaryStore: { data },
    dataZoom24
  } = useContext(DashboardContext);

  const onEvents = {
    dataZoom: (event: any) => {
      if (event.batch) {
        getDataZoom(dataZoom24, event.batch[0].start, event.batch[0].end);
      } else {
        getDataZoom(dataZoom24, event.start, event.end);
      }
    }
  };

  const [dataTotal, setDataTotal] = useState<Array<any>>([]);

  useEffect(() => {
    const tmp: Array<any> = [];
    for (
      let i = 0;
      i < data?.realtimeTrendByMedia24Hours?.legends.length;
      i++
    ) {
      const name = data?.realtimeTrendByMedia24Hours?.legends[i];
      const datas = data?.realtimeTrendByMedia24Hours?.series[name];
      const temp = {
        name: name,
        type: 'line',
        symbol: 'none',
        lineStyle: {
          width: 1
        },
        data: datas
      };
      tmp.push(temp);
    }
    if (showTotal) {
      const totalData = [];
      for (
        let i = 0;
        i < data?.realtimeTrendByMedia24Hours?.xAxis.length;
        i++
      ) {
        let total: number | null = 0;
        for (
          let j = 0;
          j < data?.realtimeTrendByMedia24Hours?.legends.length;
          j++
        ) {
          const name = data?.realtimeTrendByMedia24Hours?.legends[j];
          const datas = data?.realtimeTrendByMedia24Hours?.series[name];
          total += parseFloat((datas && datas[i]) || 0);
        }
        totalData.push(total === 0 ? null : total);
      }
      data?.realtimeTrendByMedia24Hours?.legends.push('Total');
      const tempTotal = {
        name: 'Total',
        type: 'line',
        symbol: 'none',
        lineStyle: {
          width: 1
        },
        data: totalData
      };
      tmp.unshift(tempTotal);
    }

    setDataTotal(tmp);
  }, [data, showTotal]);

  const getOption = () => {
    return {
      color: [
        '#00c4cd',
        '#75bd7a',
        '#fdaf43',
        '#e66665',
        '#b452c5',
        '#42c1f8',
        '#dddee0',
        '#9ea1a0',
        '#dc69aa',
        '#07a2a4',
        '#9a7fd1',
        '#588dd5',
        '#f5994e',
        '#c9ab00',
        '#c14089',
        '#7eb00a'
      ],
      tooltip: {
        trigger: 'axis',
        formatter: (
          params: Array<any>
          // ticket: unknown,
          // callback: () => void
        ) => {
          const headerStr = params[0].axisValue + '<br />';
          let tipString = '';
          let indexColor;
          for (let i = 0, length = params.length; i < length; i++) {
            indexColor = params[i].color;
            tipString += `<div style="min-width: 200px;margin-top: 5px; margin-bottom: 5px;display: flex;justify-content: space-between;"><p style="margin: 0;margin-right:10px;"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background:${indexColor}"></span>${
              params[i].seriesName
            }</p><p style="margin: 0;">${NumberUtils.toThousands(
              params[i].value
            )}</p> </div>`;
          }
          tipString = headerStr + tipString;
          return tipString;
        }
      },
      legend: {
        icon: 'circle',
        data: data?.realtimeTrendByMedia24Hours?.legends,
        padding: 20
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '70px',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: data?.realtimeTrendByMedia24Hours?.xAxis
      },
      yAxis: {
        type: 'value'
      },
      dataZoom: dataZoom24,
      series: dataTotal
    };
  };

  const echartsEl = useRef(null);

  return (
    <div className="echartsBox">
      <ReactEcharts
        option={getOption()}
        style={{ width: '1120px', height: ' 460px' }}
        ref={echartsEl}
        showLoading={!data?.realtimeTrendByMedia24Hours}
        onEvents={onEvents}
      />
    </div>
  );
};

export default observer(EChartStackLine);
